import React, { FC, memo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { connect, useDispatch } from "react-redux";
import { StateType } from "../../redux/store";
import {
  clearComparison,
  InitialStateCarsComparisonType,
} from "../../redux/carsComparisonData-reducer";
import { setCarsComparisonData } from "../../redux/carsComparisonData-reducer";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Button,
  Badge,
  Popper,
  Collapse,
  ClickAwayListener,
} from "@material-ui/core";

import IconAddComparison from "../Icons/IconAddComparison";
import { AppRoutesEnum } from "../../routes";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      alignSelf: "stretch",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& .MuiBadge-colorPrimary": {
        backgroundColor: "#FFBE3F",
        color: "#000",
      },
    },
    comparison: {
      marginLeft: 11,

      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "100%",
      textTransform: "capitalize",
      color: "#fff",

      transition: "all .2s ease-in-out",
    },
    button: {
      padding: "10px 18px",
      minHeight: "100%",
      "&:hover p": {
        color: "#FFBE3F",
      },
    },
    navlink: {
      textDecoration: "none",
    },
    menu: {
      backgroundColor: "#FFBE3F",
      margin: 0,
    },
    menuItem: {
      margin: 0,
      width: 192,
      fontFamily: "Open-sans, sans-serif",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000",
      padding: "7px 16px",
      cursor: "pointer",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#A28C8E",
      },
    },
    link: {
      textDecoration: "none",
    },
  })
);
interface IHeaderComparison {
  carsComparisonData: InitialStateCarsComparisonType;
  setCarsComparisonData(carId: number[]): void;
  className?: string;
}
const HeaderComparison: FC<IHeaderComparison> = ({
  carsComparisonData,
  className,
  ...props
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();

  let buttonComparison = useRef<HTMLButtonElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClearComparison = () => {
    dispatch(clearComparison());
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popover" : undefined;
  return (
    <div className={classes.root + " " + className}>
      <ClickAwayListener onClickAway={handlePopoverClose}>
        <Button
          ref={buttonComparison}
          className={classes.button}
          onClick={handleToggle}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <Badge
            badgeContent={carsComparisonData.carsIdForComparison.length}
            color="primary"
          >
            <IconAddComparison selected={false} hover={hover} />
          </Badge>
          <p className={classes.comparison}>Cравнение</p>
        </Button>
      </ClickAwayListener>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
        keepMounted
      >
        {({ TransitionProps }) => (
          <Collapse {...TransitionProps} timeout={350}>
            <div className={classes.menu}>
              <NavLink className={classes.link} to={AppRoutesEnum.COMPARISON}>
                <p className={classes.menuItem}>Перейти в сравнение</p>
              </NavLink>
              <p className={classes.menuItem} onClick={handleClearComparison}>
                Очистить сравнение
              </p>
            </div>
          </Collapse>
        )}
      </Popper>
    </div>
  );
};
export default connect(
  (state: StateType) => ({
    carsComparisonData: state.carsComparisonData,
  }),
  {
    setCarsComparisonData,
  }
)(memo(HeaderComparison));
