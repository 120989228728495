import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { Button, Grow } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { users } from "../../../api/users/users";
import { GAModalView } from "../../../index";
import { setFormOpen } from "../../../redux/auth/auth-actions";
import { StateType } from "../../../redux/store";
import { enumToasts } from "../../../utils/enumToasts";
import { validate } from "../../../utils/validate";
import InputBase from "../../Inputs/InpuBase";
import ButtonLoading from "../../Loading/buttonLoading";
import { AxiosError } from "axios";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      maxHeight: 582,
      width: 302,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    inp: {
      margin: "14px 0 0 5px",
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    title: {
      margin: "0 0 20px",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
      textAlign: "center",
    },
  })
);

const ChangePasswordForm: FC<InjectedFormProps<ChangePasswordFormValuesType>> =
  ({ handleSubmit, pristine, submitting, invalid }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { progressVisible } = useSelector((state: StateType) => state.auth);

    useEffect(() => {
      GAModalView("changePasswordForm");
    }, []);

    const handleSubmitChangePassword = async (
      values: ChangePasswordFormValuesType
    ) => {
      try {
        await users.changePassword(values.password, values.passwordNew);
        toast.success(enumToasts.successChangePassword);
        dispatch(setFormOpen(false));
      } catch (error) {
        if (
          (error as AxiosError).response &&
          (error as AxiosError).response?.data.msg.includes(
            "wrong current_password"
          )
        ) {
          throw new SubmissionError({
            password: "Неверный текущий пароль",
          });
        } else {
          toast.error(enumToasts.somethingWrong);
        }
      }
    };

    return (
      <Grow
        in={true}
        style={{ transformOrigin: "0 0 0" }}
        {...{ timeout: 700 }}
      >
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleSubmitChangePassword)}
        >
          <h3 className={classes.title}>Изменение пароля</h3>
          <div className={classes.inp}>
            <Field
              name="password"
              component={InputBase}
              label="Текущий пароль"
              setType="password"
            />
          </div>
          <div className={classes.inp}>
            <Field
              name="passwordNew"
              component={InputBase}
              label="Новый пароль"
              setType="password"
            />
          </div>
          <div className={classes.inp}>
            <Field
              name="repeatPassword"
              component={InputBase}
              label="Повторите новый пароль"
              setType="password"
            />
          </div>
          <Button
            type="submit"
            name="button"
            disabled={progressVisible || invalid || pristine || submitting}
            className={classes.button}
          >
            {progressVisible ? <ButtonLoading /> : "ОТПРАВИТЬ"}
          </Button>
        </form>
      </Grow>
    );
  };
export type ChangePasswordFormValuesType = {
  password: string;
  passwordNew: string;
  repeatPassword: string;
};

export default reduxForm<ChangePasswordFormValuesType>({
  form: "changePasswordForm",
  validate,
})(ChangePasswordForm);
