import { useSelector } from "react-redux";
import { StateType } from "../redux/store";
import { EnumMainFilter } from "../pages/Main/MainFilter/enumMainFilter";
import { getFullYear } from "../utils/getFullYear";

const useMinMaxPriceAndYear = () => {
  const { carMinMaxPriceAndYear } = useSelector(
    (state: StateType) => state.carsDataForFilter
  );
  let {
    priceMax = EnumMainFilter.carPriceMax,
    priceMin = EnumMainFilter.carPriceMin,
    manufactureYearMax = getFullYear(),
    manufactureYearMin = EnumMainFilter.carYearMin,
  } = carMinMaxPriceAndYear.minMax;

  const maxPrice = priceMax ? priceMax : EnumMainFilter.carPriceMax;
  const minPrice = priceMin ? priceMin : EnumMainFilter.carPriceMin;
  const maxYear = manufactureYearMax ? manufactureYearMax : getFullYear();
  const minYear = manufactureYearMin
    ? manufactureYearMin
    : EnumMainFilter.carYearMin;

  return { minPrice, maxPrice, minYear, maxYear };
};

export default useMinMaxPriceAndYear;
