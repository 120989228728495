import React, { FC, memo } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: "120px 49px 0",
      maxWidth: 495,
      [theme.breakpoints.down("sm")]: {
        padding: "120px 24px 0",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "120px 10px 0",
      },
    },
    title: {
      fontStyle: "normal",
      fontFamily: "Roboto, sans-serif",
      margin: 0,
      fontWeight: "bold",
      fontSize: 60,
      lineHeight: "60px",
      letterSpacing: "0.1px",
      color: "#FFFFFF",
      textShadow: "1px 1px 3px #666666",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        fontSize: 36,
        lineHeight: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    line: {
      marginTop: 30,
      height: 1,
      width: "100%",
      maxWidth: 323,
      borderBottom: "1px solid #FFFFFF",
      textShadow: "1px 1px 3px #666666",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    subTitle: {
      fontFamily: "Roboto, sans-serif",
      margin: "1.5rem 0 0 0",
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "0.1px",
      color: "#FFFFFF",
      textShadow: "1px 1px 5px #666666",
      textAlign: "left",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
  })
);

const MainPageTitle: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1 className={classes.title}>ПОМОЖЕМ ВЫБРАТЬ АВТОМОБИЛЬ</h1>
      <div className={classes.line}> </div>
      <p className={classes.subTitle}>
        Поиск лучшей машины для вас, шаг за шагом
      </p>
    </div>
  );
};

export default memo(MainPageTitle);
