import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";

import { ICard } from "../../../api/carsGeneration/interfaceCarsGeneration";
import { staticAPI } from "../../../api/staticAPI";
import carImageBack from "../../../assets/images/carImageBack.png";
import { changeCarsIdForComparison } from "../../../redux/carsComparisonData-reducer";
import { StateType } from "../../../redux/store";
import { AppRoutesEnum } from "../../../routes";
import { declOfNum } from "../../../utils/countForm";
import IconAddComparison from "../../Icons/IconAddComparison";
import IconFlagRating from "../../Icons/IconFlagRating";
import DataProgress from "../../Progress/DataProgress";
import CustomTooltipCard from "../../Tooltip/CustomTooltipCard";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "100%",
    maxWidth: 436,
    minWidth: 336,
    height: "453px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 12%, #3F392E 13.41%)",
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
  },
  info: {
    padding: "0 7% 15px",
    position: "relative",
    // marginTop: -50,
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  infoTitle: {
    color: "#fff",
    textAlign: "center",
    margin: 0,
    // minHeight: "50px",
  },
  infoLine: {
    display: "flex",
    justifyContent: "space-between",
    padding: "1px 0",
    margin: 0,
  },
  infoLineName: {
    display: "inline-block",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: "normal",
    margin: 0,
    minWidth: 120,
  },
  infoLinePrice: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 7,
  },
  infoLineCount: {
    fontSize: "24px",
    color: "#fff",
    margin: 0,
    height: 24,
  },
  button: {
    width: "100%",
    height: 50,
    backgroundColor: "#FFBE3F",
    border: "none",
    borderRadius: "0px 0px 4px 4px",
    letterSpacing: "1.25px",
    cursor: "pointer",
    textDecoration: "none",
    color: "#000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "#A28C8E",
    },
  },
  imgBox: {
    width: "100%",
    borderRadius: "4px 4px 0px 0px",
    position: "relative",
    overflow: "hidden",
  },
  img: {
    marginTop: -35,
    width: "100%",
    height: 220,
    borderRadius: "4px 4px 0px 0px",
    backgroundColor: "#9c9c9c",
  },
  imgGradient: {
    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 36.24%, #3F392E 95.41%, #3F392E 155.86%)`,
    backgroundSize: "cover",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 160,
  },
  icon: {
    position: "absolute",
    top: 14,
    right: "7%",
    cursor: "pointer",
  },
  infoBack: {
    padding: "0 0 48px",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  infoBackLine: {
    "&:first-child": {
      width: 290,
      display: "flex",
      justifyContent: "space-between",
    },
    "&:last-child": {
      marginTop: 10,
    },
  },
  infoBackTitle: {
    color: "#fff",
    maxWidth: 170,
    fontSize: "20px",
    margin: 0,
  },
  infoBackSubTitle: {
    color: "#fff",
    margin: "8px 0 0",
    fontSize: "16px",
  },
  cardBody: {
    position: "relative",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 12%, #3F392E 13.41%)",
  },
  iconComparison: {
    position: "absolute",
    top: 78,
    right: "8%",
    width: 18,
    height: 20,
    cursor: "pointer",
  },
  rating: {
    position: "absolute",
    top: -5,
    left: 20,
    cursor: "pointer",
  },
  additionalRating: {
    position: "absolute",
    top: -5,
    left: 20,
    cursor: "pointer",
  },

  ratingText: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#fff",
    fontFamily: "OpenSans, serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "36px",
    margin: 0,
    zIndex: 2,
  },
  infoSubtitle: {
    margin: "5px 0",
    fontFamily: "Roboto, serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "14px",
    color: "#fff",
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
  },
  tooltip: {
    fontSize: 12,
  },
}));

export interface CarCardProps {
  data: ICard;
}

const CarCard: FC<CarCardProps> = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { carsIdForComparison } = useSelector(
    (state: StateType) => state.carsComparisonData
  );
  const [hoverInfoIcon, setHoverInfoIcon] = useState(false);
  const [hoverComparisonIcon, setHoverComparisonIcon] = useState(false);

  const {
    imgPath,
    radarData,
    carIds,
    brand,
    model,
    generation,
    carsCount,
    totalCarsCount,
    carDrives,
    averagePrice,
    gearboxes,
    countries,
    generationId,
    medianCarId,
  } = data;

  let findRating = radarData.find((item) => {
    return item.subject === "Рейтинг";
  });
  let rating = findRating ? findRating.A : 0;

  const changeIdsComparison = (): void => {
    dispatch(changeCarsIdForComparison(carIds[0], carsIdForComparison));
    // логика отображения сообщения о добавлении авто в сравнение
    let showMessage = carsIdForComparison.find((item) => item === carIds[0]);
    showMessage
      ? toast.success("Автомобиль добавлен в сравнение", { autoClose: 2000 })
      : toast.warning("Автомобиль удален из сравнения", { autoClose: 2000 });
    ///---------------
  };

  const checkIdsComparison = (): boolean => {
    let item = carsIdForComparison.find(
      (item) => item === (carIds ? carIds[0] : false)
    );
    return !!item;
  };

  const handleButtonClick = () => {
    // if (!isAuth) {
    //   dispatch(setFormOpen(true));
    // } else {
    history.push(
      `${AppRoutesEnum.CAR}?generationId=${generationId}&medianCarId=${medianCarId}&rating=${rating}&carIds=${carIds}`
    );
    // }
  };

  const wordEquipment = declOfNum(carsCount, [
    "комплектация",
    "комплектации",
    "комплектаций",
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.imgBox}>
        <CardMedia
          className={classes.img}
          image={imgPath ? staticAPI.getImage(imgPath) : carImageBack}
        />
        {rating ? (
          <div className={classes.additionalRating}>
            <CustomTooltipCard
              title={
                "Оценка рассчитывается по индивидуальной конфигурации Вашего запроса."
              }
            >
              <p className={classes.ratingText}>{rating}</p>
              <IconFlagRating />
            </CustomTooltipCard>
          </div>
        ) : (
          // additional car list rating
          <div className={classes.additionalRating}>
            <CustomTooltipCard
              title={
                "Этот автомобиль не в полной мере соответстует вашим запросам, но максимально близок к ним."
              }
            >
              <p className={classes.ratingText}>*</p>
              <IconFlagRating color={"additional"} />
            </CustomTooltipCard>
          </div>
        )}

        <div className={classes.imgGradient} />
        <svg
          onMouseEnter={() => {
            setHoverInfoIcon(true);
          }}
          onMouseLeave={() => {
            setHoverInfoIcon(false);
          }}
          className={classes.icon}
          width="30"
          height="30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 0C6.72 0 0 6.72 0 15c0 8.28 6.72 15 15 15 8.28 0 15-6.72 15-15 0-8.28-6.72-15-15-15zm1.5 22.5h-3v-9h3v9zm0-12h-3v-3h3v3z"
            fill="#fff"
          />
        </svg>
        <div
          onClick={changeIdsComparison}
          className={classes.iconComparison}
          onMouseEnter={() => {
            setHoverComparisonIcon(true);
          }}
          onMouseLeave={() => {
            setHoverComparisonIcon(false);
          }}
        >
          <IconAddComparison
            selected={checkIdsComparison()}
            hover={hoverComparisonIcon}
            tooltip={{ in: "добавить в сравнение", out: "убрать из сравнения" }}
          />
        </div>
      </div>
      <div className={classes.cardBody}>
        <Fade in={!hoverInfoIcon} timeout={700}>
          <div className={classes.info}>
            <h3 className={classes.infoTitle}>
              {brand} {model} {generation}
            </h3>
            <h4 className={classes.infoSubtitle}>
              ( {carsCount} {wordEquipment} из {totalCarsCount} )
            </h4>
            {radarData.map(
              (data) =>
                data.subject !== "Рейтинг" && (
                  <div key={data.subject} className={classes.infoLine}>
                    <p className={classes.infoLineName}>{data.subject}</p>
                    <div style={{ width: 218 }}>
                      <DataProgress value={Math.round(data.A)} />
                    </div>
                  </div>
                )
            )}
            <div className={classes.infoLinePrice}>
              <p className={classes.infoLineName}>Средняя цена</p>
              <p className={classes.infoLineCount}>
                {Math.trunc(averagePrice).toLocaleString("ru-RU")} &#8381;
              </p>
            </div>
          </div>
        </Fade>
        <Fade in={hoverInfoIcon} timeout={700}>
          <div className={classes.infoBack}>
            <div className={classes.infoBackLine}>
              <div>
                <p className={classes.infoBackTitle}>ПРИВОД</p>
                <p className={classes.infoBackSubTitle}>
                  {carDrives
                    ? carDrives.map((item) => `${item} `)
                    : "нет данных"}
                </p>
              </div>
              <div>
                <p className={classes.infoBackTitle}>КОРОБКА</p>
                <p className={classes.infoBackSubTitle}>
                  {gearboxes
                    ? gearboxes.map((item) => `${item} `)
                    : "нет данных"}
                </p>
              </div>
            </div>
            <div className={classes.infoBackLine}>
              <p className={classes.infoBackTitle}>СТРАНА ПРОИЗВОДИТЕЛЬ</p>
              <p className={classes.infoBackSubTitle}>
                {countries ? countries.map((item) => `${item} `) : "нет данных"}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <Button className={classes.button} onClick={handleButtonClick}>
        УЗНАТЬ ПОДРОБНЕЕ
      </Button>
    </div>
  );
};
export default CarCard;
