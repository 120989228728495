import React, { FC } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Button from "@material-ui/core/Button";

import ButtonLoading from "../../Loading/buttonLoading";
import FormikInput from "../../UI/Formik/FormikInput";
import { StateType } from "../../../redux/store";
import { postFeedback } from "../../../redux/feedback-reducer";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      margin: "0 auto",
      width: 302,
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
    },
    title: {
      margin: "0 auto 24px",
      maxWidth: "340px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
    input: {
      margin: "10px 0",
    },
    button: {
      margin: "20px 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    error: {
      margin: "0 auto",
      padding: "5px 10px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#fff",
      background: "#8D4D4D",
    },
    errorHide: {
      visibility: "hidden",
    },
  })
);

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(2, "Минимум 2 символа")
    .max(20, "Имя пользователя больше 20 символов")
    .required("Обязательное поле"),
  email: Yup.string().email("Некорректный email").required("Обязательное поле"),
  message: Yup.string()
    // .min(8, "Должно быть больше 8 символов")
    .required("Обязательное поле"),
});

export type FeedbackFormValuesType = {
  username: string;
  email: string;
  message: string;
};

const FooterFeedbackForm: FC = () => {
  const dispatch = useDispatch();
  const { isLoading, isError } = useSelector(
    (state: StateType) => state.feedback
  );
  const { isAuth, userData } = useSelector((state: StateType) => state.auth);

  const handleSubmit = (values: FeedbackFormValuesType) => {
    dispatch(postFeedback(values));
  };

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      username: isAuth ? userData.username : "",
      email: isAuth ? userData.email : "",
      message: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <h3 className={classes.title}>Остались вопросы? Напишите нам</h3>
        <FormikInput
          classname={classes.input}
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          label="Имя"
          error={formik.touched.username ? formik.errors.username : undefined}
        />
        <FormikInput
          classname={classes.input}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          label="Email"
          error={formik.touched.email ? formik.errors.email : undefined}
        />
        <FormikInput
          classname={classes.input}
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
          label="Введите ваш текст"
          error={formik.touched.message ? formik.errors.message : undefined}
          multiline
          rows={6}
        />
        <p
          className={
            isError ? classes.error : classes.error + " " + classes.errorHide
          }
        >
          Ошибка при отправке данных! Повторите попытку.
        </p>
        <Button type="submit" className={classes.button}>
          {isLoading ? <ButtonLoading /> : "ОТПРАВИТЬ"}
        </Button>
      </form>
    </Grow>
  );
};

export default FooterFeedbackForm;
