import { ThunkAction } from "redux-thunk";
import {
  IAddFilterParams,
  ICarById,
  ICarDTP,
  ICarPricePredictions,
  ICarPricesById,
  ICarsOptions,
  IFeatureOption,
  IMaintenanceCost,
  IRadarData,
} from "../../api/carsGeneration/interfaceCarsGeneration";
import { StateType } from "../store";
import { initialState } from "./car-reducer";
import { ICarsFromAvito } from "./../../api/carsGeneration/interfaceCarsGeneration";

export enum CarActionsEnum {
  COST_FILTER_ANNUAL_MILEAGE = "COST_FILTER_ANNUAL_MILEAGE",
  COST_FILTER_DRIVER_AGE = "COST_FILTER_DRIVER_AGE",
  COST_FILTER_DRIVING_EXPERIENCE = "COST_FILTER_DRIVING_EXPERIENCE",
  RESET_CAR_DATA = "RESET_CAR_DATA",
  SELECT_CAR_SPEC = "SELECT_CAR_SPEC",
  SET_CAR_DATA = "SET_CAR_DATA",
  SET_CAR_SPECS = "SET_CAR_SPECS",
  SET_COST_DATA = "SET_COST_DATA",
  SET_DTP = "SET_DTP",
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_LOADING_SPEC = "SET_LOADING_SPEC",
  SET_PRICES = "SET_PRICES",
  SET_PRICE_PREDICTIONS = "SET_PRICE_PREDICTIONS",
  SET_RADAR_DATA = "SET_RADAR_DATA",
  SET_RECOMMENDATIONS = "SET_RECOMMENDATIONS",
  SET_SPEC_FILTER = "SET_SPEC_FILTER", // доступные значения фильтров
  SET_SPEC_FILTER_OPEN = "SET_SPEC_FILTER_OPEN",
  SET_SPEC_OPTIONS = "SET_SPEC_OPTIONS",
  SET_SURVEY_VIEW = "SET_SURVEY_VIEW",
  SPEC_FILTER_ENGINER_POWER = "SPEC_FILTER_ENGINER_POWER", // установленное значение фильтра
  SPEC_FILTER_FUEL_CONSUNMPTION = "SPEC_FILTER_FUEL_CONSUNMPTION",
  SPEC_FILTER_TRUNK_VOLUME_MAX = "SPEC_FILTER_TRUNK_VOLUME_MAX",
  SPEC_FILTER_WHEEL_SIZE = "SET_SPEC_WHEEL_SIZE",
  CARS_FROM_AVITO = "CARS_FROM_AVITO",
}

export type InitialStateType = typeof initialState;
export type CostFilterType = typeof initialState.costFilter;

export enum CarSurveyViewEnum {
  ASPECTS = "aspects",
  REVIEWS = "reviews",
}

export type AllTypes =
  | SetCarsFromAvito
  | ResetCarData
  | SelectCarSpec
  // | SetAspects
  | SetCarData
  | SetCarSpec
  | SetCostData
  | SetCostFilterAnnualMileage
  | SetCostFilterDriverAge
  | SetCostFilterDrivingExperience
  | SetDtp
  | SetError
  | SetLoading
  | SetLoadingSpec
  | SetPricePredictions
  | SetPrices
  | SetRadarData
  | SetRecommendations
  | SetSurveyView
  | SetSpecEnginerPower
  | SetSpecFilter
  | SetSpecFilterOpen
  | SetSpecFuelConsumption
  | SetSpecOptions
  | SetSpecTrunkVolumeMax
  | SetSpecWheelSize;

export type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;
export type ThunkTypeAsync = ThunkAction<
  Promise<void>,
  StateType,
  unknown,
  AllTypes
>;

export type ResetCarData = {
  type: CarActionsEnum.RESET_CAR_DATA;
  data: null;
};

export type SelectCarSpec = {
  type: CarActionsEnum.SELECT_CAR_SPEC;
  data: ICarsOptions;
};

export type SetCarData = {
  type: CarActionsEnum.SET_CAR_DATA;
  data: ICarById;
};

export type SetCarSpec = {
  type: CarActionsEnum.SET_CAR_SPECS;
  data: ICarsOptions[];
};

export type SetCostData = {
  type: CarActionsEnum.SET_COST_DATA;
  data: {
    maintenanceCost: IMaintenanceCost;
    pieValueTotal: number;
  };
};

export type SetCostFilterAnnualMileage = {
  type: CarActionsEnum.COST_FILTER_ANNUAL_MILEAGE;
  data: number;
};

export type SetCostFilterDriverAge = {
  type: CarActionsEnum.COST_FILTER_DRIVER_AGE;
  data: boolean;
};

export type SetCostFilterDrivingExperience = {
  type: CarActionsEnum.COST_FILTER_DRIVING_EXPERIENCE;
  data: boolean;
};

export type SetDtp = {
  type: CarActionsEnum.SET_DTP;
  data: ICarDTP[];
};

export type SetError = {
  type: CarActionsEnum.SET_ERROR;
  data: string | null;
};

export type SetLoading = {
  type: CarActionsEnum.SET_LOADING;
  data: boolean;
};

export type SetLoadingSpec = {
  type: CarActionsEnum.SET_LOADING_SPEC;
  data: boolean;
};

export type SetPricePredictions = {
  type: CarActionsEnum.SET_PRICE_PREDICTIONS;
  data: ICarPricePredictions[];
};

export type SetPrices = {
  type: CarActionsEnum.SET_PRICES;
  data: ICarPricesById[];
};

export type SetRadarData = {
  type: CarActionsEnum.SET_RADAR_DATA;
  data: IRadarData[];
};

export type SetRecommendations = {
  type: CarActionsEnum.SET_RECOMMENDATIONS;
  data: string[];
};

export type SetSurveyView = {
  type: CarActionsEnum.SET_SURVEY_VIEW;
  data: CarSurveyViewEnum;
};

export type SetSpecFilterOpen = {
  type: CarActionsEnum.SET_SPEC_FILTER_OPEN;
  data: boolean;
};

export type SetSpecEnginerPower = {
  type: CarActionsEnum.SPEC_FILTER_ENGINER_POWER;
  data: string[];
};

export type SetSpecFilter = {
  type: CarActionsEnum.SET_SPEC_FILTER;
  data: IAddFilterParams;
};

export type SetSpecFuelConsumption = {
  type: CarActionsEnum.SPEC_FILTER_FUEL_CONSUNMPTION;
  data: string[];
};

export type SetSpecOptions = {
  type: CarActionsEnum.SET_SPEC_OPTIONS;
  data: IFeatureOption;
};

export type SetSpecTrunkVolumeMax = {
  type: CarActionsEnum.SPEC_FILTER_TRUNK_VOLUME_MAX;
  data: string[];
};

export type SetSpecWheelSize = {
  type: CarActionsEnum.SPEC_FILTER_WHEEL_SIZE;
  data: string[];
};

export type SetCarsFromAvito = {
  type: CarActionsEnum.CARS_FROM_AVITO;
  data: ICarsFromAvito[] | null;
};
