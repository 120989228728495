import React, { FC, ReactNode } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      "&:hover $tooltip": {
        visibility: "visible",
        opacity: 1,
      },
    },
    tooltip: {
      padding: "4px 8px",
      maxWidth: 120,
      width: "max-content",

      fontFamily: "'Roboto'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "120%",

      color: "#FFFFFF",
      borderRadius: "4px",
      backgroundColor: "rgba(97, 97, 97, 0.9)",

      visibility: "hidden",
      opacity: 0,

      position: "absolute",
      top: "calc(100% + 10px)",
      right: 0,
      zIndex: 1000,
      transform: "none",

      transition: "all 0.5s ease-in-out",
      [theme.breakpoints.up(600)]: {
        // maxWidth: 300,
        right: "50%",
        transform: "translate(50%, 0)",
      },
    },
  })
);

interface I {
  title?: string;
  children: ReactNode;
}

const CustomTooltip: FC<I> = ({ children, title }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.tooltip}>{title}</p>
      {children}
    </div>
  );
};

export default CustomTooltip;
