import React, { FC } from "react";

import { SvgIcon, Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const CustomTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);
interface IIconAddComparison {
  selected: boolean;
  hover: boolean;
  tooltip?: { in: string; out: string };
}

const IconAddComparison: FC<IIconAddComparison> = (props) => {
  const { selected, hover } = props;
  const iconColor = () => {
    if (selected) {
      return "#FAC764";
    } else if (hover) {
      return "#FFDA8C";
    } else {
      return "#fff";
    }
  };
  return (
    <CustomTooltip
      title={
        props.tooltip
          ? props.tooltip && props.selected
            ? props.tooltip.out
            : props.tooltip.in
          : ""
      }
      aria-label={props.tooltip ? props.tooltip.in : ""}
    >
      <SvgIcon>
        <path
          style={{ transition: "all .2s ease-in-out" }}
          d="M0 5.833h14.167V20H0V5.833z"
          fill={iconColor()}
        />
        <path
          style={{ transition: "all .2s ease-in-out" }}
          d="M6.667 0H20v15h-4.167V4.167H6.667V0z"
          fill={iconColor()}
        />
      </SvgIcon>
    </CustomTooltip>
  );
};

export default IconAddComparison;
