import { ThunkAction } from "redux-thunk";
import { ICarsGeneration } from "../../api/carsGeneration/interfaceCarsGeneration";
import {
  ICarsColor,
  ICarsSpec,
  IOwnershipStatus,
  IUsersCar,
  IUsersCarsPhoto,
} from "../../api/usersCars/interfaceUsersCars";
import { StateType } from "../store";
import { initialState } from "./usersCars-reducer";

export enum UsersCarsActionsEnum {
  ADD_USERS_CAR = "ADD_USERS_CAR",
  DELETE_USERS_CAR = "DELETE_USERS_CAR",
  EDIT_USERS_CAR = "EDIT_USERS_CAR",
  REMOVE_USERS_CARS_PHOTO = "REMOVE_USERS_CARS_PHOTO",
  SELECT_USERS_CAR = "SELECT_USERS_CAR",
  SET_CARS_COLORS = "SET_CARS_COLORS",
  SET_CARS_GENERATION = "SET_CARS_GENERATION",
  SET_CARS_OWNERSHIP_STATUSES = "SET_CARS_OWNERSHIP_STATUSES",
  SET_CARS_SPECIFICATION = "SET_CARS_SPECIFICATION",
  SET_ERROR = "SET_ERROR",
  SET_LOADING = "SET_LOADING",
  SET_USERS_CARS_PHOTOS = "SET_USERS_CARS_PHOTOS",
  SET_USERS_CARS_PHOTOS_ADD = "SET_USERS_CARS_PHOTOS_ADD",
  USERS_CARS = "USERS_CARS",
  USERS_CAR_FORM_INITIAL = "USERS_CAR_FORM_INITIAL",
  USERS_CAR_FORM_OPEN = "USERS_CAR_FORM_OPEN",
  USERS_CAR_FORM_TYPE = "USERS_CAR_FORM_TYPE",
}

export enum UsersCarFormType {
  ADD = "add",
  EDIT = "edit",
}

export type UsersCarFormValuesType = {
  brand: number | "";
  color: number | "";
  description: string;
  generation: number | "";
  model: number | "";
  modification: number | "";
  ownershipStatus: number | "";
  shortDescription: string;
  year: number | "";
};

export type InitialStateType = typeof initialState;

export type AllTypes =
  | AddUsersCar
  | DeleteUsersCar
  | EditUsersCar
  | SelectUsersCar
  | SetCarGenerations
  | SetCarColors
  | SetCarOwnershipStatuses
  | SetCarSpecs
  | SetError
  | SetLoading
  | SetUsersCarFormOpen
  | SetUsersCarFormType
  | SetUsersCarInitial
  | SetUsersCars
  | SetUsersCarsPhotos
  | SetUsersCarsPhotosAdd
  | SetUsersCarsPhotosRemove;

export type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;
export type ThunkTypeAsync = ThunkAction<
  Promise<void>,
  StateType,
  unknown,
  AllTypes
>;

export type AddUsersCar = {
  type: UsersCarsActionsEnum.ADD_USERS_CAR;
  data: IUsersCar;
};

export type DeleteUsersCar = {
  type: UsersCarsActionsEnum.DELETE_USERS_CAR;
  data: number;
};

export type EditUsersCar = {
  type: UsersCarsActionsEnum.EDIT_USERS_CAR;
  data: IUsersCar;
};

export type SelectUsersCar = {
  type: UsersCarsActionsEnum.SELECT_USERS_CAR;
  data: IUsersCar;
};

export type SetCarGenerations = {
  type: UsersCarsActionsEnum.SET_CARS_GENERATION;
  data: ICarsGeneration;
};

export type SetCarColors = {
  type: UsersCarsActionsEnum.SET_CARS_COLORS;
  data: ICarsColor[];
};

export type SetCarOwnershipStatuses = {
  type: UsersCarsActionsEnum.SET_CARS_OWNERSHIP_STATUSES;
  data: IOwnershipStatus[];
};

export type SetCarSpecs = {
  type: UsersCarsActionsEnum.SET_CARS_SPECIFICATION;
  data: ICarsSpec[];
};

export type SetError = {
  type: UsersCarsActionsEnum.SET_ERROR;
  data: string | null;
};
export type SetLoading = {
  type: UsersCarsActionsEnum.SET_LOADING;
  data: boolean;
};

export type SetUsersCarFormOpen = {
  type: UsersCarsActionsEnum.USERS_CAR_FORM_OPEN;
  data: boolean;
};

export type SetUsersCarFormType = {
  type: UsersCarsActionsEnum.USERS_CAR_FORM_TYPE;
  data: UsersCarFormType;
};

export type SetUsersCarInitial = {
  type: UsersCarsActionsEnum.USERS_CAR_FORM_INITIAL;
  data: UsersCarFormValuesType;
};

export type SetUsersCars = {
  type: UsersCarsActionsEnum.USERS_CARS;
  data: IUsersCar[];
};

export type SetUsersCarsPhotos = {
  type: UsersCarsActionsEnum.SET_USERS_CARS_PHOTOS;
  data: { add: File[]; saved: IUsersCarsPhoto[]; remove: IUsersCarsPhoto[] };
};

export type SetUsersCarsPhotosAdd = {
  type: UsersCarsActionsEnum.SET_USERS_CARS_PHOTOS_ADD;
  data: File[];
};

export type SetUsersCarsPhotosRemove = {
  type: UsersCarsActionsEnum.REMOVE_USERS_CARS_PHOTO;
  data: IUsersCarsPhoto;
};
