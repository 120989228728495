import React, { FC, memo } from "react";

import { SvgIcon } from "@material-ui/core";

interface IIconMobileMenu {}

const IconMobileMenu: FC<IIconMobileMenu> = (props) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 24 14">
      <path
        stroke="#828282"
        strokeWidth="2"
        d="M.545 1h22.909M.545 7h22.909M.545 13h22.909"
        fontSize={26}
      />
    </SvgIcon>
  );
};

export default memo(IconMobileMenu);
