import React, { FC, memo } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { setFormOpen, setStatusForm } from "../../redux/auth/auth-actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: "0",
      padding: "25px 18px 23px",

      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "100%",
      textTransform: "capitalize",

      color: "#FFFFFF",
      borderRadius: 0,
      transition: "all .2s ease-in-out",
      "&:hover": {
        color: "#FFBE3F",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const HeaderContacts: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenForm = () => {
    dispatch(setFormOpen(true));
    dispatch(setStatusForm("bug-report"));
  };

  return (
    <Button onClick={handleOpenForm} className={classes.button}>
      Помощь
    </Button>
  );
};

export default memo(HeaderContacts);
