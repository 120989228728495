import { Button, Grid, GridSize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useCarsDataForRequest from "../../../hooks/useCarsDataForRequest";
import { setCarsGenerationData } from "../../../redux/carsGenerationData-reducer";
import { enumToasts } from "../../../utils/enumToasts";
import { EnumMainFilter } from "./enumMainFilter";
import MainFilterButtonDrop from "./MainFilterButtonDrop";

const useStyles = makeStyles({
  button: {
    height: 56,
    fontSize: 16,
    maxWidth: 120,
  },
});

interface I {
  md?: boolean | GridSize;
  lg?: boolean | GridSize;
  onClick(): void;
}

const MainFilterButtons: FC<I> = ({ md, lg, onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataForRequest = useCarsDataForRequest();

  const getCarsGeneration = async () => {
    try {
      await dispatch(
        setCarsGenerationData(
          dataForRequest.carCross,
          dataForRequest.carAppearance,
          dataForRequest.carDynamism,
          dataForRequest.carComfort,
          dataForRequest.carEconomy,
          dataForRequest.carSafety,
          dataForRequest.carPrice.minValue,
          dataForRequest.carPrice.maxValue,
          dataForRequest.carsBrands,
          dataForRequest.carModel,
          dataForRequest.carClass,
          dataForRequest.carGearbox,
          dataForRequest.carBody,
          dataForRequest.carDriver,
          dataForRequest.carTrunk.minValue,
          dataForRequest.carTrunk.maxValue,
          dataForRequest.carEnginePower.minValue,
          dataForRequest.carEnginePower.maxValue,
          dataForRequest.carDisplacement.minValue,
          dataForRequest.carDisplacement.maxValue,
          dataForRequest.carEngineType,
          dataForRequest.carAcceleration.minValue,
          dataForRequest.carAcceleration.maxValue,
          dataForRequest.carYear.minValue,
          dataForRequest.carYear.maxValue,
          dataForRequest.carConsumption.minValue,
          dataForRequest.carConsumption.maxValue,
          dataForRequest.carReason,
          dataForRequest.carSort,
          EnumMainFilter.carLimit
        )
      );
      onClick();
    } catch (error) {
      if (
        error.response &&
        error.response.data.msg.includes("Bad Authorization header")
      ) {
        toast.error(enumToasts.noAutorization);
      }
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      sm={12}
      md={md}
      lg={lg}
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item>
        <MainFilterButtonDrop className={classes.button} />
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={getCarsGeneration}
        >
          Подобрать
        </Button>
      </Grid>
    </Grid>
  );
};

export default MainFilterButtons;
