import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/images/logo.svg";

export const useStyles = makeStyles(() => ({
  paper: {
    width: "100%",
    boxSizing: "border-box",

    background: "#FAFAFA",
    boxShadow: "0px -4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px 8px  0 0",

    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: 1000,

    touchAction: "none",
    "&::before": {
      content: "''",

      width: 60,
      height: 4,

      background: "#E6E6E6",
      borderRadius: 2,

      position: "absolute",
      top: 6,
      left: "50%",
      transform: "translate(-50%, 0)",
    },
  },
  blackout: {
    width: "100%",
    height: "100%",

    background: "rgba(0, 0, 0, 0.5)",

    position: "fixed",
    top: 0,
    left: 0,
  },
  head: {
    padding: 23,
    paddingRight: 0,

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  info: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  logo: {
    marginLeft: -2,
    width: 60,
    height: 14,

    backgroundImage: `url(${Logo})`,
    backgroundSize: "cover",
    backgroundPosition: "center left",
    backgroundRepeat: "no-repeat",
  },
  name: {
    margin: 0,

    fontFamily: "'Open Sans'",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
    letterSpacing: "0.1px",

    color: "#000000",
  },
  button: {
    margin: 0,
    padding: 4,
    minWidth: 133,
    height: "auto",

    fontFamily: "'Open Sans', sans-serif",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    textTransform: "uppercase",
    textDecoration: "none",
    textAlign: "center",

    color: "#000000",
    background: "#FAC764",
    borderRadius: 5,

    display: "block",

    "&:hover": {
      background: "#A28C8E",
    },
  },
  images: {
    margin: "0 auto",
    padding: "16px 20px",

    borderTop: "solid 1px #C4C4C4",

    listStyle: "none",
    display: "grid",
    gridAutoFlow: "column",
    gap: 16,
    justifyContent: "flex-start",

    overflowX: "scroll",
  },
  item: {
    width: 150,
    height: 284,
  },
}));
