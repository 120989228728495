import React, { FC, memo } from "react";
import { NavLink } from "react-router-dom";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import HeaderPagesButtons from "./HeaderPagesButtons";
import HeaderSignIn from "./headerSignIn/HeaderSignIn";
import HeaderComparison from "./HeaderComparison";
import logo from "../../assets/images/logo.svg";
import HeaderContacts from "./HeaderContacts";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/store";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // width: "100hv",
      background: "rgba(0, 0, 0, 0.4)",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.12)",
      zIndex: 2,
      marginBottom: -72,
    },
    content: {
      margin: "0 auto",
      maxWidth: 1440,
      padding: "0 49px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: {
        padding: "0 24px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 10px",
      },
    },
    link: {
      textDecoration: "none",
    },
    logo: {
      width: 138,
      height: 36,
      backgroundImage: `url(${logo})`,
      marginLeft: 42,
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    item: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const Header: FC = () => {
  const classes = useStyles();
  const { isAuth } = useSelector((state: StateType) => state.auth);

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <NavLink className={classes.link} to="/main">
          <div className={classes.logo} />
        </NavLink>
        <HeaderPagesButtons className={classes.item} />
        <HeaderComparison className={classes.item} />
        {isAuth && <HeaderContacts />}
        <HeaderSignIn />
      </div>
    </section>
  );
};

export default memo(Header);
