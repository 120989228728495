import { ThunkAction } from "redux-thunk";
import { ICarAspect } from "../../api/carAspect/interfaceCarAspect";
import { StateType } from "../store";
import { initialState } from "./carAspect-reducer";

export enum CarAspectActionsEnum {
  CAR_ASPECTS = "CAR_ASPECTS",
  CAR_ASPECTS_LOADING = "CAR_ASPECTS_LOADING",
  ADD_REWIER_OPEN = "ADD_REWIER_OPEN",
  ADD_REWIER_ASPECT_ID = "ADD_REWIER_ASPECT_ID",
}

export type InitialStateType = typeof initialState;

export type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;
export type ThunkTypeAsync = ThunkAction<
  Promise<void>,
  StateType,
  unknown,
  AllTypes
>;

export type AllTypes =
  | SetCarAspects
  | SetCarAspectsLoading
  | SetAddCarAspectReviewOpen
  | SetAddCarAspectReviewAspect;

export type SetCarAspects = {
  type: CarAspectActionsEnum.CAR_ASPECTS;
  data: ICarAspect[];
};

export type SetCarAspectsLoading = {
  type: CarAspectActionsEnum.CAR_ASPECTS_LOADING;
  data: boolean;
};

export type SetAddCarAspectReviewOpen = {
  type: CarAspectActionsEnum.ADD_REWIER_OPEN;
  data: boolean;
};

export type SetAddCarAspectReviewAspect = {
  type: CarAspectActionsEnum.ADD_REWIER_ASPECT_ID;
  data: number | undefined;
};
