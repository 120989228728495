import NumberFormat from "react-number-format";
import React from "react";

interface NumberFormatInputProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: number } }) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  name: string;
}

function NumberFormatYear(props: NumberFormatInputProps) {
  const { inputRef, onChange, onBlur, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: +values.value,
          },
        });
      }}
      onBlur={onBlur}
      thousandSeparator={""}
      suffix={" г"}
      allowNegative={false}
    />
  );
}

export default NumberFormatYear;
