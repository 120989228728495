import React, { FC } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: "10px",
      fontWeight: 300,
      color: "rgba(255, 255, 255, 0.7)",
      margin: 0,
    },

    checkbox: {
      color: "rgba(255, 255, 255, 0.7)",
    },
    checkBoxIcon: {
      color: "#FAC867",
    },
  })
);

const CheckBoxRF: FC<{ value: any; input: any }> = ({ value, input }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ label: classes.label }}
      control={
        <Checkbox
          className={classes.checkbox}
          checked={input.value ? true : false}
          onChange={input.onChange}
          checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon} />}
        />
      }
      label="Я прочитал и согласен "
    />
  );
};

export default CheckBoxRF;
