import { useSelector } from "react-redux";
import { StateType } from "../redux/store";

const useCarsDataForFilter = () => {
  const carsDataForFilter = useSelector(
    (state: StateType) => state.carsDataForFilter
  );

  return carsDataForFilter;
};

export default useCarsDataForFilter;
