import { instance } from "../baseURL";

export const otherRequests = {
  async bugReport(
    message: string,
    page: string,
    subject?: string,
    aspect_car_parts_id?: number,
    file?: File[]
  ) {
    let bodyFormData = new FormData();

    bodyFormData.append("message", message);
    bodyFormData.append("page", page);
    subject && bodyFormData.append("subject", subject);
    aspect_car_parts_id &&
      bodyFormData.append("aspectCarPartsId", String(aspect_car_parts_id));
    if (file) {
      file.forEach((item) => {
        bodyFormData.append("file[]", item);
      });
    }

    try {
      const res = await instance.post<string>("api/bug-reports", bodyFormData);
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
};
