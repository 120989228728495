import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() =>
  createStyles({
    field: {
      width: "100%",
    },
    label: {
      color: "rgba(255, 255, 255, 0.5)",
      transform: "translate(16px, 16px)",
      "&$focused": {
        color: "#FAC867",
      },
    },
    labelShrink: {
      color: "white",
    },
    border: {
      padding: "0",
      color: "#FFFFFF",
      "&:not(hover):not($focused):not($error) $notchedOutline": {
        borderColor: "#FFFFFF", //default
      },
      "&$focused $notchedOutline": {
        borderColor: "#FAC867", //focused
      },
    },
    notchedOutline: {
      borderColor: "#FFF",
    },
    focused: {
      fontSize: 16,
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    input: {
      color: "#FFFFFF",
      padding: "15px",
    },
    error: {
      backgroundColor: "#8D4D4D",
    },
    helperError: {
      alignSelf: "end",
      color: "#FFF !important",
      background: "#8D4D4D",
      margin: "2px 0 0 auto",
      padding: "0 4px",
    },
  })
);

type I = {
  classname?: string;
  name: string;
  value: string;
  onChange: (props: any) => void;
  label: string;
  error: string | undefined;
  multiline?: boolean;
  rows?: number;
};

const FormikInput: FC<I> = ({
  classname,
  error,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classname}>
      <TextField
        className={classes.field}
        variant="outlined"
        error={!!error}
        helperText={error}
        {...props}
        InputLabelProps={{
          classes: {
            root: classes.label,
            shrink: classes.labelShrink,
            focused: classes.focused,
          },
        }}
        InputProps={{
          classes: {
            root: classes.border,
            input: classes.input,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            error: classes.error,
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperError,
          },
        }}
      />
    </div>
  );
};

export default FormikInput;
