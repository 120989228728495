// поля для форм
import React, { FC, memo } from "react";
import cn from "classnames";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ErrorIcon from "@material-ui/icons/Error";
import { InputAdornment } from "@material-ui/core";
import PasswordTooltip from "../Tooltip/PasswordTooltip";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      maxWidth: "302px",
    },
    textField: {
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
      "& .MuiOutlinedInput-multiline": {
        padding: "3px 0 0",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        padding: 0,
      },
      "& .MuiInputAdornment-positionStart": {
        margin: 0,
      },
    },
    cssLabel: {
      transform: "translate(16px, 17px) scale(1)",
      color: "#FFFFFF",
      "&$cssFocused ": {
        color: "#FFFFFF",
        backgroundColor: "#666",
      },
      "&.Mui-error": {
        color: "#fff",
      },
    },
    cssOutlinedInput: {
      color: "#fff",
      "&:not(hover):not($disabled):not($cssFocused):not($error) $notchedOutline":
        {
          borderColor: "#FFFFFF", //default
        },
      "&:hover:not($disabled):not($cssFocused):not($error) $notchedOutline": {
        borderColor: "#FAC867", //hovered
      },
      "&$cssFocused $notchedOutline": {
        borderColor: "#FAC867", //focused
      },
    },
    notchedOutline: {},
    cssFocused: {
      fontSize: 16,
      lineHeight: "19px",
      color: "#FFFFFF",
    },
    disabled: {},
    input: {
      padding: "15px 14px",
      ".Mui-error > &": {
        background: "#8D4D4D",
      },
      "&:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
        WebkitTextFillColor: "white",
      },
    },
    label: {},
    error: {},
    helperError: {
      alignSelf: "end",
      color: "#FFF !important",
      background: "#8D4D4D",
      margin: "2px 0 0 auto",
      padding: "0 4px",
    },
    icon: {
      position: "absolute",
      right: 10,
      top: "50%",
      transform: "translate(0, -50%)",
      padding: 0,
    },
    item: {
      fontSize: 12,
      paddingRight: 5,
    },
  })
);

interface IInputBase {
  setType?: string;
  label: string;
  input: string;
  meta: {
    touched: boolean;
    invalid: string;
    error: string;
  };
  className: string;
}

const InputBase: FC<IInputBase> = ({
  setType,
  label,
  input,
  meta: { touched, invalid, error },
  className,
  ...custom
}) => {
  const classes = useStyles();
  return (
    <div className={cn(classes.root, className)}>
      <TextField
        className={classes.textField}
        variant="outlined"
        type={setType}
        label={label}
        error={!!(touched && error)}
        helperText={touched && error}
        {...input}
        {...custom}
        InputLabelProps={{
          classes: {
            root: classes.cssLabel,
            focused: classes.cssFocused,
          },
        }}
        InputProps={{
          classes: {
            input: classes.input,
            root: classes.cssOutlinedInput,
            focused: classes.cssFocused,
            notchedOutline: classes.notchedOutline,
          },
          inputMode: "numeric",
          endAdornment: (
            <InputAdornment position="start" style={{ cursor: "pointer" }}>
              {!!(touched && error && setType === "password") && (
                <PasswordTooltip
                  title={
                    <React.Fragment>
                      <ul>
                        <li className={classes.item}>
                          Только латинский алфавит
                        </li>
                        <li className={classes.item}>Не менее 7 символов</li>
                        <li className={classes.item}>Минимум одну цифру</li>
                      </ul>
                    </React.Fragment>
                  }
                >
                  <ErrorIcon className={classes.icon} />
                </PasswordTooltip>
              )}
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          classes: {
            root: classes.helperError,
          },
        }}
      />
    </div>
  );
};

export default memo(InputBase);
