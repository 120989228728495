import React, { FC, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const themeNew = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#FAC867",
        },
        "&$disabled": {
          color: "grey",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 208,
    },
    formControl: {
      margin: theme.spacing(0),
      width: "100%",
    },
    select: {
      color: "#FFFFFF",
    },
    label: {
      color: "#FFFFFF",
    },
    icon: {
      color: "#FFFFFF",
    },
    menuPaper: {
      maxHeight: 450,
    },
  })
);
const useOutlinedInputStyles = makeStyles(() => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#FFFFFF",
    },
    "&:hover  $notchedOutline ": {
      borderColor: "#FAC867",
    },
    "&$focused $notchedOutline": {
      borderColor: "#FAC867",
    },
    "&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "grey",
    },
  },
  focused: {},
  notchedOutline: {},
}));

interface IFilterSelect {
  set(data: number | string): void;
  label: string;
  value: {
    //для модели авто
    carBrandId?: number;
    id: number | string;
    name: string;
  }[];
  disabled?: boolean;
  selected: string;
}

const FilterSelect: FC<IFilterSelect> = ({ ...props }) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();

  useEffect(() => {
    if (props.disabled) {
      props.set("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disabled]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.set(event.target.value as number | string);
  };
  return (
    <div className={classes.root}>
      <FormControl
        variant="outlined"
        disabled={props.disabled}
        className={classes.formControl}
      >
        <ThemeProvider theme={themeNew}>
          <InputLabel className={classes.label}>{props.label}</InputLabel>
          <Select
            className={classes.select}
            value={props.selected}
            onChange={handleChange}
            input={
              <OutlinedInput
                classes={outlinedInputClasses}
                label={props.label}
              />
            }
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            <MenuItem value={""}>
              <em>Не выбрано</em>
            </MenuItem>
            {props.value &&
              props.value.map((model, index) => {
                return (
                  <MenuItem key={index} value={model.id}>
                    {model.name}
                  </MenuItem>
                );
              })}
          </Select>
        </ThemeProvider>
      </FormControl>
    </div>
  );
};

export default FilterSelect;
