import { instance } from "../baseURL";
import {
  ICarBrand,
  ICarModels,
  ICarClasses,
  ICarGearboxes,
  ICarDrivers,
  ICarBodies,
  ICarMinMaxPriceAndYear,
  ICarReasons,
  ICarEngineDisplacements,
  ICarEngineTypes,
} from "./interfaceCars";
import { EnumEndPoints } from "../enumEndPoints";

export const carsInfo = {
  // список марок автомобилей для фильтра
  getCarsBrands() {
    return instance
      .get<ICarBrand>(EnumEndPoints.carBrands)
      .then((res) => res.data)
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  },
  // список мделей определнной марки авто
  getCarModels(brandId: number) {
    return instance
      .get<ICarModels>(EnumEndPoints.carModels, {
        params: {
          "car_brand_ids[]": brandId,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  // список классов автомобилей
  getCarClasses() {
    return instance
      .get<ICarClasses>(EnumEndPoints.carClasses)
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  // список коробок передач
  getCarGearboxes() {
    return instance
      .get<ICarGearboxes>(EnumEndPoints.gearboxes)
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  // список возможных приводов автомоблей
  getCarDrivers() {
    return instance
      .get<ICarDrivers>(EnumEndPoints.carDrives)
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  // список возможных кузовов автомоблей
  getCarBodies() {
    return instance
      .get<ICarBodies>(EnumEndPoints.carBodies)
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  // получаю 4 значения max min цену и max min год
  getCarMinMaxPriceAndYear(brandId: number[], carModelId: number | string) {
    return instance
      .get<ICarMinMaxPriceAndYear>(EnumEndPoints.carsMinMax, {
        params: {
          car_brand_ids: brandId,
          car_model_id: carModelId,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  getCarReasons() {
    return instance
      .get<ICarReasons[]>(EnumEndPoints.reasonTags)
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  async getCarEngineDisplacements(brandId?: number[], carModelId?: number) {
    try {
      const res = await instance.get<ICarEngineDisplacements[]>(
        EnumEndPoints.engineDisplacements,
        {
          params: {
            car_brand_ids: brandId,
            car_model_id: carModelId,
          },
        }
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async getCarEngineTypes(brandId?: number[], carModelId?: number) {
    try {
      const res = await instance.get<ICarEngineTypes>(
        EnumEndPoints.engineTypes,
        {
          params: {
            car_brand_ids: brandId,
            car_model_id: carModelId,
          },
        }
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
};
