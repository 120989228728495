import React, { FC, useState, memo } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button, Link } from "@material-ui/core";

import logo from "../../assets/images/logo.svg";
import nccrLogo from "../../assets/images/NCCRLogo.png";
import itmoLogo from "../../assets/images/ITMOLogo.png";
import googlePlay from "../../assets/images/googlePlay.svg";
import Instagram from "../../assets/images/Instagram.png";
import PrivacyPolicyButton from "../PrivacyPolicy/PrivacyPolicyButton";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import FooterAboutProjectModal from "./FooterAboutProjectModal";
import FooterFeedbackModal from "./FooterFeedback/FooterFeedback";
import { resetFeedback } from "../../redux/feedback-reducer";
import { AppRoutesEnum, LinksEnum } from "../../routes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: "linear-gradient(180deg, #1B1714 0%, #000000 100%)",
    },
    content: {
      margin: "0 auto",
      padding: "0 40px",
      maxWidth: 1440,

      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",

      [theme.breakpoints.between(850, 1241)]: {
        gridTemplateColumns: "1fr 3fr 1fr",
      },

      [theme.breakpoints.between("sm", 850)]: {
        gridTemplateColumns: "1fr 5fr 1fr",
      },

      [theme.breakpoints.down("sm")]: {
        padding: "20px 24px",
        gridTemplateColumns: "1fr ",
      },
    },
    link: {
      textDecoration: "none",
    },
    logoContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.between(600, 850)]: {
        flexDirection: "column",
        justifyContent: "center",
        // alignItems: "center",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },

    logo: {
      width: 83,
      height: 20,
      backgroundImage: `url(${logo})`,
      backgroundSize: "83px 20px",
    },
    logoITMO: {
      margin: "0 0 0 16px",
      width: 45,
      height: 20,
      backgroundImage: `url(${itmoLogo})`,
      backgroundSize: "45px 20px",
      [theme.breakpoints.between(600, 850)]: {
        margin: "10px 0 0 0",
      },
    },
    logoNCCR: {
      margin: "0 0 0 16px",
      width: 50,
      height: 20,
      backgroundImage: `url(${nccrLogo})`,
      backgroundSize: "50px 20px",
      [theme.breakpoints.between(600, 850)]: {
        margin: "10px 0 0 0",
      },
    },
    nav: {
      padding: "36px 0 12px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 18,
        flexDirection: "column",
      },
    },
    navLinks: {
      margin: "0 auto 24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    navLink: {
      margin: "0 16px 0 0",
      padding: 0,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#FFFFFF",
      textDecoration: "none",
      textTransform: "inherit",
      "&:last-child": {
        margin: 0,
      },
      "&:hover": {
        textDecoration: "underline",
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 auto 6px",
      },
    },
    copyright: {
      margin: 0,
      fontWeight: 300,
      fontSize: "10px",
      lineHeight: "12px",
      letterSpacing: "0.15px",
      color: "#fff",
    },
    appLink: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      [theme.breakpoints.between(600, 850)]: {
        flexDirection: "column",
        justifyContent: "center",
      },
    },
    googlePlay: {
      width: "110px",
      height: "32px",
      border: "1px solid #FFFFFF",
      borderRadius: "6px",
      backgroundImage: `url(${googlePlay})`,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    insta: {
      marginLeft: 8,

      width: "34px",
      height: "34px",
      // border: "1px solid #FFFFFF",
      borderRadius: "6px",
      backgroundImage: `url(${Instagram})`,
      backgroundSize: "cover",
      [theme.breakpoints.between(600, 850)]: {
        marginLeft: 0,
        marginTop: 8,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const Footer: FC = () => {
  const classes = useStyles();
  const [isAboutProjectOpen, setIsAboutProjectOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpenAboutProject = () => {
    setIsAboutProjectOpen(true);
  };

  const handleOpenFeedback = () => {
    setIsFeedbackOpen(true);
    dispatch(resetFeedback());
  };

  const handleCloseModal = () => {
    setIsAboutProjectOpen(false);
    setIsFeedbackOpen(false);
  };

  return (
    <footer className={classes.root}>
      <div className={classes.content}>
        <div className={classes.logoContainer}>
          <NavLink className={classes.link} to={AppRoutesEnum.MAIN}>
            <div className={classes.logo} />
          </NavLink>
          <div className={classes.logoITMO} />
          <div className={classes.logoNCCR} />
        </div>
        <div className={classes.nav}>
          <div className={classes.navLinks}>
            <Button
              onClick={handleOpenAboutProject}
              className={classes.navLink}
            >
              О проекте
            </Button>
            <Button onClick={handleOpenFeedback} className={classes.navLink}>
              Обратная связь
            </Button>
            <PrivacyPolicyButton
              text="Политика конфиденциальности"
              className={classes.navLink}
            />
          </div>
          <p className={classes.copyright}>
            &copy; {`${new Date().getFullYear()}`} НЦКР/при поддержке ИТМО
          </p>
        </div>
        <div className={classes.appLink}>
          <Link
            href={LinksEnum.GOOGLE_PLAY}
            target="_blank"
            className={classes.link}
          >
            <div className={classes.googlePlay} />
          </Link>
          <Link
            href={LinksEnum.INSTAGRAM}
            target="_blank"
            className={classes.link}
          >
            <div className={classes.insta} />
          </Link>
        </div>
      </div>
      <FooterAboutProjectModal
        isOpen={isAboutProjectOpen}
        onClose={handleCloseModal}
      />
      <FooterFeedbackModal isOpen={isFeedbackOpen} onClose={handleCloseModal} />
      <PrivacyPolicy />
    </footer>
  );
};

export default memo(Footer);
