import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, Checkbox, FormControlLabel, Grow } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { GAModalView } from "../../../../index";
import {
  errorVisibleMessage,
  setStatusForm,
} from "../../../../redux/auth/auth-actions";
import { StateType } from "../../../../redux/store";
import { validate } from "../../../../utils/validate";
import InputBase from "../../../Inputs/InpuBase";
import ButtonLoading from "../../../Loading/buttonLoading";
import HeaderSignInUiBottom from "../headerSignInUi/HeaderSignInUiBottom";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      maxHeight: 582,
      width: 302,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    inp: {
      margin: "14px 0 0 0",
    },
    password: {
      margin: "8px 0 0 0",
      fontSize: "14px",
      lineHeight: "16px",
      color: "rgba(255, 255, 255, 0.7);",
      cursor: "pointer",
      "&:hover": {
        color: "#FAC867",
      },
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    label: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "rgba(255, 255, 255, 0.7)",
      marginTop: -4,
    },
    sizeLabel: {
      height: "18px",
    },
    checkbox: {
      color: "rgba(255, 255, 255, 0.7)",
      paddingTop: 0,
    },
    checkBoxIcon: {
      color: "#FAC867",
    },
    buttonVK: {
      margin: "30px 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "rgba(218, 218, 218, 0.2);",
      border: "1px solid #FFFFFF",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    error: {
      fontSize: "12px",
      lineHeight: "19px",
      color: "#fff",
      background: "#8D4D4D",
      margin: "2px 0 0 auto",
      padding: "0 4px",
    },
    errorNone: {
      margin: "0",
      fontSize: "12px",
      lineHeight: "19px",
      visibility: "hidden",
    },
    enter: {
      margin: 0,
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "36px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
  })
);

const HeaderSignInLogInForm: FC<InjectedFormProps<LogInFormValuesType>> = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
}) => {
  const classes = useStyles();
  const [checkBox, setCheckBox] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { progressVisible, errorVisible } = useSelector(
    (state: StateType) => state.auth
  );

  useEffect(() => {
    GAModalView("logInForm");
  }, []);

  useEffect(() => {
    dispatch(errorVisibleMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckBox = useCallback((): void => {
    setCheckBox(!checkBox);
  }, [checkBox]);

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <h3 className={classes.enter}>Вход</h3>
        <div className={classes.inp}>
          <Field name="login" component={InputBase} label="Пользователь" />
        </div>
        <div className={classes.inp}>
          <Field
            name="password"
            setType="password"
            component={InputBase}
            label="Пароль"
          />
        </div>
        <p
          className={classes.password}
          onClick={() => dispatch(setStatusForm("email-restore-password"))}
        >
          Забыли пароль?
        </p>

        <Button
          type="submit"
          disabled={progressVisible || invalid || pristine || submitting}
          className={classes.button}
        >
          {progressVisible ? <ButtonLoading /> : "ВОЙТИ"}
        </Button>
        <p className={errorVisible ? classes.errorNone : classes.error}>
          Ошибка логина или пароля
        </p>
        <FormControlLabel
          classes={{ label: classes.label }}
          className={classes.sizeLabel}
          control={
            <Checkbox
              className={classes.checkbox}
              checked={checkBox}
              checkedIcon={<CheckBoxIcon className={classes.checkBoxIcon} />}
              onChange={handleChangeCheckBox}
            />
          }
          label="Запомнить пароль"
        />
        {/*<HeaderSignInUiOre />*/}
        {/*<Button className={classes.buttonVK}>войти через ВК</Button>*/}
        <HeaderSignInUiBottom
          questionText="Ещё нет аккаунта?"
          changeForm={() => dispatch(setStatusForm("registration"))}
          linkText={"ЗАРЕГИСТРИРОВАТЬСЯ"}
        />
      </form>
    </Grow>
  );
};

export type LogInFormValuesType = {
  login: string;
  password: string;
};

export default reduxForm<LogInFormValuesType>({
  form: "logIn",
  validate,
})(memo(HeaderSignInLogInForm));
