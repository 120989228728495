export enum EnumEndPoints {
  // images = "api/images/",
  auth = "api/auth",
  cars = "api/cars",
  filterGenerationCars = "api/v2/search",
  generationCars = "api/generation-cars/",
  prices = "api/prices",
  // carAspects = "api/car-aspects",
  pricePredictions = "api/price-predictions",
  dtp = "api/dtp",
  generationCarsMinMax = "api/generation-cars/min-max",
  buyingRecommendations = "api/buying-recommendations",
  carBrands = "api/car-brands",
  carModels = "api/car-models",
  carClasses = "api/car-classes",
  gearboxes = "api/gearboxes",
  carDrives = "api/car-drives",
  carBodies = "api/car-bodies",
  carsMinMax = "api/cars/min-max",
  reasonTags = "api/reason-tags",
  // logoImages = "images/",
  engineDisplacements = "api/engine-displacements",
  engineTypes = "api/engine-types",
  userRegistration = "api/user/registration",
  maintenanceCost = "api/maintenance_cost",
  userInformation = "/api/users/me",
  putUser = "api/users/",
  drivingExperience = "api/driving-experience-types",
  activateUser = "api/users/me/activate",
  sendActivateEmail = "api/users/me/send_activation_email",
  sendRestorePassword = "api/users/me/send_restore_password",
  restorePassword = "api/users/me/restore_password",
  changePassword = "api/users/me/change_password",
  updateProfilePhoto = "api/users/me/photo",
  // usersCars = "api/users-cars/me",
  // postUsersCars = "api/users-cars",
  // deleteUsersCars = "api/users-cars/",
  // ownershipStatuses = "api/ownership-statuses",
  feedback = "api/feedbacks",
  carsFromAvito = "api/generation-cars",
}
