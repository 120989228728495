import { StateType } from "./store";
import { ThunkAction } from "redux-thunk";

const PRIVACY_POLICY_OPEN = "PRIVACY_POLICY_OPEN";

let initialState = {
  isPrivacyPolicyOpen: false, // открытие и закрытие политики конфиденциальности
};

export type InitialStateType = typeof initialState;
type AllTypes = PrivacyPolicyOpen;

const privacyPolicyReducer = (
  state = initialState,
  action: AllTypes
): InitialStateType => {
  switch (action.type) {
    case PRIVACY_POLICY_OPEN:
      return { ...state, isPrivacyPolicyOpen: action.data };
    default:
      return state;
  }
};

type PrivacyPolicyOpen = {
  type: typeof PRIVACY_POLICY_OPEN;
  data: boolean;
};

const actionsPrivacyPolicy = {
  PrivacyPolicyOpen: (data: boolean): PrivacyPolicyOpen => ({
    type: PRIVACY_POLICY_OPEN,
    data,
  }),
};

type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;

export const setIsPrivacyPolicyOpen = (data: boolean): ThunkType => {
  return (dispatch) => {
    dispatch(actionsPrivacyPolicy.PrivacyPolicyOpen(data));
  };
};

export default privacyPolicyReducer;
