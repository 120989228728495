export const validate = (values: {
  [field: string]: number | string | any[];
}) => {
  const errors: { [field: string]: string } = {};
  const requiredFields = [
    "email",
    "password",
    "repeatPassword",
    "passwordNew",
    "message",
    "generation",
    "modification",
    "engine_power",
    "engine_displacement",
    "personal_data",
  ];

  requiredFields.forEach((field: any) => {
    if (!values[field]) {
      errors[field] = "Обязательное поле";
    }
  });

  if (
    values.email &&
    typeof values.email === "string" &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
  ) {
    errors.email = "Некорректный email";
  }

  if (
    values.username &&
    typeof values.username === "string" &&
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)
  ) {
    errors.username = "Некорректное имя пользователя";
  }

  if (
    values.username &&
    typeof values.username === "string" &&
    values.username.length > 20
  ) {
    errors.username = "Имя пользователя больше 20 символов";
  }

  //Минимум восемь символов, как минимум одна заглавная буква, одна строчная буква, одна цифра и один специальный символ:
  if (
    values.passwordNew &&
    typeof values.passwordNew === "string" &&
    !/^(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?^&#]{7,}$/.test(values.passwordNew)
  ) {
    errors.passwordNew = "Слишком слабый пароль";
  }

  if (values.passwordNew !== values.repeatPassword) {
    errors.repeatPassword = "Пароли должны совпадать";
  }

  return errors;
};
