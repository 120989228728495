// используется в умном фильтре
import React, { FC, memo, useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import Slider from "@material-ui/core/Slider";
import questionIcon from "../../assets/images/question.png";
import { Typography } from "@material-ui/core";
import cn from "classnames";
import CustomTooltip from "../Tooltip/CustomToooltip";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: 120,
      width: "100%",
    },
    slider: {
      marginTop: 52,
      color: "#FFBE3F",
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down(425)]: {
        display: "none",
      },
    },
    textContainerHidden: {
      display: "none",
    },
    text: {
      margin: 0,
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "14px",
      color: "#FFFFFF",
    },
    iconQuestion: {
      width: 16,
      height: 16,
      background: `center no-repeat url("${questionIcon}")`,
      marginLeft: 5,
      alignSelf: "center",
      cursor: "pointer",
      [theme.breakpoints.down(360)]: {
        display: "none",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);

interface ISlider {
  title: string;
  value: number;
  set(data: number): void;
  tooltip?: any;
  className?: string;
}

const SliderForFilter: FC<ISlider> = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(props.value);

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value);
    }
  }, [props.value]);

  const handleChange = (event: any, value: number | number[]) => {
    setValue(value as number);
  };
  const handleChangeCommitted = (event: any, value: number | number[]) => {
    props.set(value as number);
    setValue(value as number);
  };

  return (
    <div className={cn(classes.root, props.className)}>
      <div className={classes.titleContainer}>
        <Typography variant="subtitle1" color="primary">
          {props.title}
        </Typography>
        {props.tooltip && (
          <CustomTooltip title={props.tooltip}>
            <div className={classes.iconQuestion} />
          </CustomTooltip>
        )}
      </div>
      <Slider
        className={classes.slider}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        value={value}
        step={1}
        marks
        min={0}
        max={10}
      />
      <div className={classes.textContainer}>
        <p className={classes.text}>не важно</p>
        <p className={classes.text}>важно</p>
        <p className={classes.text}>очень важно</p>
      </div>
    </div>
  );
};
export default memo(SliderForFilter);
