import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, Grow } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { GAModalView } from "../../../../index";
import { setStatusForm } from "../../../../redux/auth/auth-actions";
import { StateType } from "../../../../redux/store";
import { validate } from "../../../../utils/validate";
import InputBase from "../../../Inputs/InpuBase";
import ButtonLoading from "../../../Loading/buttonLoading";
import PrivacyPolicyButton from "../../../PrivacyPolicy/PrivacyPolicyButton";
import CheckBoxRF from "../../../UI/checkBox/CheckBoxRF";
import HeaderSignInUiBottom from "../headerSignInUi/HeaderSignInUiBottom";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      maxHeight: 582,
      width: 302,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    inp: {
      margin: "14px 0 0 5px",
    },
    lastInput: {
      marginBottom: 10,
      color: "black",
      borderColor: "#000",
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    label: {
      fontSize: "10px",
      fontWeight: 300,
      color: "rgba(255, 255, 255, 0.7)",
      margin: 0,
    },
    labelLink: {
      position: "absolute",
      bottom: 0,
      left: "33px",
      padding: 0,
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: "inherit",
      },
    },
    checkboxContainer: {
      position: "relative",
    },
    checkbox: {
      color: "rgba(255, 255, 255, 0.7)",
    },
    checkBoxIcon: {
      color: "#FAC867",
    },
    buttonVK: {
      margin: "10px 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "rgba(218, 218, 218, 0.2);",
      border: "1px solid #FFFFFF",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    error: {
      fontSize: "12px",
      lineHeight: "19px",
      color: "#fff",
      background: "#8D4D4D",
      margin: "2px 0 0 auto",
      padding: "0 4px",
    },
    errorNone: {
      margin: "3px 0 0 0",
      fontSize: "14px",
      lineHeight: "16px",
      visibility: "hidden",
    },
    enter: {
      margin: 0,
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "36px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
    helperText: {
      color: "#f94538",
      position: "absolute",
    },
  })
);

const HeaderSignInLogUpForm: FC<InjectedFormProps<LogUpFormValuesType>> = ({
  handleSubmit,
  pristine,
  submitting,
  invalid,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { progressVisible } = useSelector((state: StateType) => state.auth);

  useEffect(() => {
    GAModalView("logUpForm");
  }, []);

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <h3 className={classes.enter}>Регистрация</h3>
        <div className={classes.inp}>
          <Field name="email" component={InputBase} label="Email" />
        </div>
        <div className={classes.inp}>
          <Field name="username" component={InputBase} label="Пользователь" />
        </div>
        <div className={classes.inp}>
          <Field
            name="passwordNew"
            component={InputBase}
            label="Пароль"
            setType="password"
          />
        </div>
        <div className={classes.inp}>
          <Field
            name="repeatPassword"
            component={InputBase}
            label="Повторить пароль"
            setType="password"
          />
        </div>
        <Button
          type="submit"
          name="button"
          disabled={progressVisible || invalid || pristine || submitting}
          className={classes.button}
        >
          {progressVisible ? <ButtonLoading /> : "ЗАРЕГИСТРИРОВАТЬСЯ"}
        </Button>
        <div className={classes.checkboxContainer}>
          <Field name="personal_data" component={CheckBoxRF} />
          <PrivacyPolicyButton
            text="c условиями обработи персональных данных"
            className={classes.label + " " + classes.labelLink}
          />
        </div>
        {/*<HeaderSignInUiOre />*/}
        {/*<Button className={classes.buttonVK}>войти через ВК</Button>*/}
        <HeaderSignInUiBottom
          questionText="Есть аккаунт?"
          changeForm={() => dispatch(setStatusForm("login"))}
          linkText={"Войти"}
        />
      </form>
    </Grow>
  );
};
export type LogUpFormValuesType = {
  email: string;
  username: string;
  passwordNew: string;
  repeatPassword: string;
  personal_data: string;
};
const HeaderSignInLogUpFormRedux = reduxForm<LogUpFormValuesType>({
  form: "logUp",
  validate,
})(HeaderSignInLogUpForm);

export default HeaderSignInLogUpFormRedux;
