// в этом reducer храним всю информацию полученную из умного фильтра (чтобы дальше отправить запрос на подбор авто)
import { StateType } from "./store";
import { ThunkAction } from "redux-thunk";
import { getFullYear } from "../utils/getFullYear";
import { currentValueType } from "../components/Sliders/DoubleSliderWithInputs";
import { EnumMainFilter } from "../pages/Main/MainFilter/enumMainFilter";

const CAR_BRANDS = "CAR_BRANDS";
const CAR_MODEL = "CAR_MODEL";
const CAR_CLASS = "CAR_CLASS";
const CAR_GEARBOX = "CAR_GEARBOX";
const CAR_DRIVER = "CAR_DRIVER";
const CAR_BODY = "CAR_BODY";
const CAR_PRICE = "CAR_PRICE";
const CAR_YEAR = "CAR_YEAR";
const CAR_CROSS = "CAR_CROSS";
const CAR_COMFORT = "CAR_COMFORT";
const CAR_ECONOMY = "CAR_ECONOMY";
const CAR_DYNAMISM = "CAR_DYNAMISM";
const CAR_SAFETY = "CAR_SAFETY";
const CAR_APPEARANCE = "CAR_APPEARANCE";
const CAR_ACCELERATION = "CAR_ACCELERATION";
const CAR_TRUNK = "CAR_TRUNK";
const CAR_ENGINE_POWER = "CAR_ENGINE_POWER";
const CAR_DISPLACEMENT = "CAR_DISPLACEMENT";
const CAR_CONSUMPTION = "CAR_CONSUMPTION";
const CAR_REASON = "CAR_REASON";
const CAR_SORT = "CAR_SORT";
const CAR_ENGINE_TYPE = "CAR_ENGINE_TYPE";
const ALL_DATA = "ALL_DATA";
const GET_DATA = "GET_DATA";
const RESET_EXSEPT_SURVEY = "RESET_EXSEPT_SURVEY";

let initialState = {
  carsBrands: [] as number[],
  carModel: "" as number | string,
  carClass: "" as number | string,
  carGearbox: "" as number | string,
  carDriver: "" as number | string,
  carBody: "" as number | string,
  carPrice: {
    minValue: EnumMainFilter.carPriceMin,
    maxValue: EnumMainFilter.carPriceMax,
  } as currentValueType,
  carYear: {
    minValue: EnumMainFilter.carYearMin,
    maxValue: getFullYear(),
  } as currentValueType,
  carCross: 0,
  carComfort: 0,
  carEconomy: 0,
  carDynamism: 0,
  carSafety: 0,
  carAppearance: 0,
  carAcceleration: {
    minValue: EnumMainFilter.carAccelerationMin,
    maxValue: EnumMainFilter.carAccelerationMax,
  } as currentValueType,
  carTrunk: {
    minValue: EnumMainFilter.carTrunkMin,
    maxValue: EnumMainFilter.carTrunkMax,
  } as currentValueType,
  carEnginePower: {
    minValue: EnumMainFilter.carEnginePowerMin,
    maxValue: EnumMainFilter.carEnginePowerMax,
  } as currentValueType,
  carDisplacement: {
    minValue: EnumMainFilter.carDisplacementMin,
    maxValue: EnumMainFilter.carDisplacementMax,
  } as currentValueType,
  carConsumption: {
    minValue: EnumMainFilter.carConsumptionMin,
    maxValue: EnumMainFilter.carConsumptionMax,
  } as currentValueType,
  carReason: [] as number[], //id причины для покупки
  carSort: "" as string,
  carEngineType: "" as number | string,
};

export type InitialStateCarsForRequestType = typeof initialState;
type AllTypes =
  | setCarsBrandsForRequest
  | setCarModelForRequest
  | setCarClassForRequest
  | setCarGearboxForRequest
  | setCarDriverForRequest
  | setCarBodyForRequest
  | setCarPriceForRequest
  | setCarYearForRequest
  | setCarCrossForRequest
  | setCarComfortForRequest
  | setCarEconomyForRequest
  | setCarDynamismForRequest
  | setCarSafetyForRequest
  | setCarAppearanceForRequest
  | setCarAccelerationForRequest
  | setCarTrunkForRequest
  | setCarEnginePowerForRequest
  | setCarDisplacementForRequest
  | setCarConsumptionForRequest
  | setCarReason
  | setCarSort
  | setCarEngineType
  | setAllData
  | getAllData
  | resetExceptSurvey;

const carsForRequestReducer = (
  state = initialState,
  action: AllTypes
): InitialStateCarsForRequestType => {
  switch (action.type) {
    case CAR_BRANDS:
      return { ...state, carsBrands: action.data };
    case CAR_MODEL:
      return { ...state, carModel: action.data };
    case CAR_CLASS:
      return { ...state, carClass: action.data };
    case CAR_GEARBOX:
      return { ...state, carGearbox: action.data };
    case CAR_DRIVER:
      return { ...state, carDriver: action.data };
    case CAR_BODY:
      return { ...state, carBody: action.data };
    case CAR_PRICE:
      return { ...state, carPrice: action.data };
    case CAR_YEAR:
      return { ...state, carYear: action.data };
    case CAR_CROSS:
      return { ...state, carCross: action.data };
    case CAR_COMFORT:
      return { ...state, carComfort: action.data };
    case CAR_ECONOMY:
      return { ...state, carEconomy: action.data };
    case CAR_DYNAMISM:
      return { ...state, carDynamism: action.data };
    case CAR_SAFETY:
      return { ...state, carSafety: action.data };
    case CAR_APPEARANCE:
      return { ...state, carAppearance: action.data };
    case CAR_ACCELERATION:
      return { ...state, carAcceleration: action.data };
    case CAR_TRUNK:
      return { ...state, carTrunk: action.data };
    case CAR_ENGINE_POWER:
      return { ...state, carEnginePower: action.data };
    case CAR_DISPLACEMENT:
      return { ...state, carDisplacement: action.data };
    case CAR_CONSUMPTION:
      return { ...state, carConsumption: action.data };
    case CAR_REASON:
      return { ...state, carReason: action.data };
    case CAR_SORT:
      return { ...state, carSort: action.data };
    case CAR_ENGINE_TYPE:
      return { ...state, carEngineType: action.data };
    case ALL_DATA:
      return action.data;
    case RESET_EXSEPT_SURVEY:
      // получаем текущие значения фильтров из опроса
      const {
        carPrice,
        carCross,
        carComfort,
        carEconomy,
        carDynamism,
        carReason,
      } = state;
      return {
        ...initialState,
        carPrice,
        carCross,
        carComfort,
        carEconomy,
        carDynamism,
        carReason,
      };
    default:
      return state;
  }
};

type setCarsBrandsForRequest = {
  type: typeof CAR_BRANDS;
  data: number[];
};
type setCarModelForRequest = {
  type: typeof CAR_MODEL;
  data: number | string;
};
type setCarClassForRequest = {
  type: typeof CAR_CLASS;
  data: number | string;
};
type setCarGearboxForRequest = {
  type: typeof CAR_GEARBOX;
  data: number | string;
};
type setCarDriverForRequest = {
  type: typeof CAR_DRIVER;
  data: number | string;
};
type setCarBodyForRequest = {
  type: typeof CAR_BODY;
  data: number | string;
};
type setCarPriceForRequest = {
  type: typeof CAR_PRICE;
  data: currentValueType;
};
type setCarYearForRequest = {
  type: typeof CAR_YEAR;
  data: currentValueType;
};
type setCarCrossForRequest = {
  type: typeof CAR_CROSS;
  data: number;
};
type setCarComfortForRequest = {
  type: typeof CAR_COMFORT;
  data: number;
};
type setCarEconomyForRequest = {
  type: typeof CAR_ECONOMY;
  data: number;
};
type setCarDynamismForRequest = {
  type: typeof CAR_DYNAMISM;
  data: number;
};
type setCarSafetyForRequest = {
  type: typeof CAR_SAFETY;
  data: number;
};
type setCarAppearanceForRequest = {
  type: typeof CAR_APPEARANCE;
  data: number;
};
type setCarAccelerationForRequest = {
  type: typeof CAR_ACCELERATION;
  data: currentValueType;
};
type setCarTrunkForRequest = {
  type: typeof CAR_TRUNK;
  data: currentValueType;
};
type setCarEnginePowerForRequest = {
  type: typeof CAR_ENGINE_POWER;
  data: currentValueType;
};
type setCarDisplacementForRequest = {
  type: typeof CAR_DISPLACEMENT;
  data: currentValueType;
};
type setCarConsumptionForRequest = {
  type: typeof CAR_CONSUMPTION;
  data: currentValueType;
};
type setCarReason = {
  type: typeof CAR_REASON;
  data: number[];
};
type setCarSort = {
  type: typeof CAR_SORT;
  data: string;
};
type setCarEngineType = {
  type: typeof CAR_ENGINE_TYPE;
  data: number | string;
};
type setAllData = {
  type: typeof ALL_DATA;
  data: InitialStateCarsForRequestType;
};
type getAllData = {
  type: typeof GET_DATA;
  data: InitialStateCarsForRequestType;
};
type resetExceptSurvey = {
  type: typeof RESET_EXSEPT_SURVEY;
  data: null;
};

const actionsCarsForRequest = {
  setCarsBrandsForRequest: (data: number[]): setCarsBrandsForRequest => ({
    type: CAR_BRANDS,
    data: data,
  }),
  setCarModelForRequest: (data: number | string): setCarModelForRequest => ({
    type: CAR_MODEL,
    data: data,
  }),
  setCarClassForRequest: (data: number | string): setCarClassForRequest => ({
    type: CAR_CLASS,
    data: data,
  }),
  setCarGearboxForRequest: (
    data: number | string
  ): setCarGearboxForRequest => ({
    type: CAR_GEARBOX,
    data: data,
  }),
  setCarDriverRequest: (data: number | string): setCarDriverForRequest => ({
    type: CAR_DRIVER,
    data: data,
  }),
  setCarBodyRequest: (data: number | string): setCarBodyForRequest => ({
    type: CAR_BODY,
    data: data,
  }),
  setCarPriceRequest: (data: currentValueType): setCarPriceForRequest => ({
    type: CAR_PRICE,
    data: data,
  }),
  setCarYearRequest: (data: currentValueType): setCarYearForRequest => ({
    type: CAR_YEAR,
    data: data,
  }),
  setCarCrossRequest: (data: number): setCarCrossForRequest => ({
    type: CAR_CROSS,
    data: data,
  }),
  setCarComfortRequest: (data: number): setCarComfortForRequest => ({
    type: CAR_COMFORT,
    data: data,
  }),
  setCarEconomyRequest: (data: number): setCarEconomyForRequest => ({
    type: CAR_ECONOMY,
    data: data,
  }),
  setCarDynamismRequest: (data: number): setCarDynamismForRequest => ({
    type: CAR_DYNAMISM,
    data: data,
  }),
  setCarSafetyRequest: (data: number): setCarSafetyForRequest => ({
    type: CAR_SAFETY,
    data: data,
  }),
  setCarAppearanceRequest: (data: number): setCarAppearanceForRequest => ({
    type: CAR_APPEARANCE,
    data: data,
  }),
  setCarAccelerationRequest: (
    data: currentValueType
  ): setCarAccelerationForRequest => ({
    type: CAR_ACCELERATION,
    data: data,
  }),
  setCarTrunkRequest: (data: currentValueType): setCarTrunkForRequest => ({
    type: CAR_TRUNK,
    data: data,
  }),
  setCarEnginePowerRequest: (
    data: currentValueType
  ): setCarEnginePowerForRequest => ({
    type: CAR_ENGINE_POWER,
    data: data,
  }),
  setCarDisplacementRequest: (
    data: currentValueType
  ): setCarDisplacementForRequest => ({
    type: CAR_DISPLACEMENT,
    data: data,
  }),
  setCarConsumptionRequest: (
    data: currentValueType
  ): setCarConsumptionForRequest => ({
    type: CAR_CONSUMPTION,
    data: data,
  }),
  setCarReason: (data: number[]): setCarReason => ({
    type: CAR_REASON,
    data: data,
  }),
  setCarSort: (data: string): setCarSort => ({
    type: CAR_SORT,
    data: data,
  }),
  setCarEngineType: (data: number | string): setCarEngineType => ({
    type: CAR_ENGINE_TYPE,
    data: data,
  }),
  setAllData: (data: InitialStateCarsForRequestType): setAllData => ({
    type: ALL_DATA,
    data,
  }),
  resetExceptSurvey: (): resetExceptSurvey => ({
    type: RESET_EXSEPT_SURVEY,
    data: null,
  }),
};

type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;
type ThunkTypeAsync = ThunkAction<Promise<void>, StateType, unknown, AllTypes>;

export const setSelectedBrands = (carsBrands: number[]): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarsBrandsForRequest(carsBrands));
  };
};
export const setSelectedModel = (carModel: number | string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarModelForRequest(carModel));
  };
};
export const setSelectedClass = (carClass: number | string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarClassForRequest(carClass));
  };
};
export const setSelectedGearbox = (carGearbox: number | string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarGearboxForRequest(carGearbox));
  };
};
export const setSelectedDriver = (carDriver: number | string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarDriverRequest(carDriver));
  };
};
export const setSelectedBody = (carBody: number | string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarBodyRequest(carBody));
  };
};
export const setSelectedPrice = (
  priceMinAndMax: currentValueType
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarPriceRequest(priceMinAndMax));
  };
};
export const setSelectedYear = (yearMinAndMax: currentValueType): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarYearRequest(yearMinAndMax));
  };
};
export const setSelectedCross = (carCross: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarCrossRequest(carCross));
  };
};
export const setSelectedComfort = (carComfort: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarComfortRequest(carComfort));
  };
};
export const setSelectedEconomy = (carEconomy: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarEconomyRequest(carEconomy));
  };
};
export const setSelectedDynamism = (carDynamism: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarDynamismRequest(carDynamism));
  };
};
export const setSelectedSafety = (carSafety: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarSafetyRequest(carSafety));
  };
};
export const setSelectedAppearance = (carAppearance: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarAppearanceRequest(carAppearance));
  };
};
export const setSelectedAcceleration = (
  carAcceleration: currentValueType
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarAccelerationRequest(carAcceleration));
  };
};
export const setSelectedTrunk = (carTrunk: currentValueType): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarTrunkRequest(carTrunk));
  };
};
export const setSelectedEnginePower = (
  carEnginePower: currentValueType
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarEnginePowerRequest(carEnginePower));
  };
};
export const setSelectedDisplacement = (
  carDisplacement: currentValueType
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarDisplacementRequest(carDisplacement));
  };
};
export const setSelectedConsumption = (
  carConsumption: currentValueType
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarConsumptionRequest(carConsumption));
  };
};
export const setSelectedReason = (carReason: number | string): ThunkType => {
  return (dispatch) => {
    let array = [];
    if (carReason !== "") {
      array.push(carReason as number);
      //TODO: костыль, чтобы обойти непонятное преобразование массива чисел в число.
      // Пришлось использовать, т.к. хоть и причина одна, но параметр должен быть в массиве для бэка
    }
    dispatch(actionsCarsForRequest.setCarReason(array));
  };
};

export const setSelectedSort = (sort_by: string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarSort(sort_by));
  };
};

export const setSelectedEngineType = (
  engineType: number | string
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setCarEngineType(engineType));
  };
};

export const resetFilter = (): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.setAllData(initialState));
  };
};

export const resetFilterExceptSurvey = (): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCarsForRequest.resetExceptSurvey());
  };
};

export default carsForRequestReducer;
