import { useSelector } from "react-redux";
import { StateType } from "../redux/store";

const useCarsDataForRequest = () => {
  const carsDataForRequest = useSelector(
    (state: StateType) => state.carsDataForRequest
  );

  //TODO: упростить код, разобраться с разницей string | number. Убрать неочевидную подстановку default и преобразование
  const carDriverDef = carsDataForRequest.carDriver
    ? String(carsDataForRequest.carDriver)
    : "";
  const carModelDef = carsDataForRequest.carModel
    ? String(carsDataForRequest.carModel)
    : "";
  const carClassDef = carsDataForRequest.carClass
    ? String(carsDataForRequest.carClass)
    : "";
  const carEngineTypeDef = carsDataForRequest.carEngineType
    ? String(carsDataForRequest.carEngineType)
    : "";
  const carGearboxDef = carsDataForRequest.carGearbox
    ? String(carsDataForRequest.carGearbox)
    : "";
  const carBodyDef = carsDataForRequest.carBody
    ? String(carsDataForRequest.carBody)
    : "";

  const carsDataForRequestDef = {
    ...carsDataForRequest,
    carDriver: carDriverDef,
    carModel: carModelDef,
    carClass: carClassDef,
    carEngineType: carEngineTypeDef,
    carGearbox: carGearboxDef,
    carBody: carBodyDef,
  };
  return carsDataForRequestDef;
};

export default useCarsDataForRequest;
