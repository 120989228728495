import { carsComparison } from "../api/carsComparison/carsComparison";

import { ICarsComparison } from "../api/carsComparison/interfaceCarsComparison";

import { StateType } from "./store";
import { ThunkAction } from "redux-thunk";

const GET_CAR_COMPARISON = "GET_CAR_COMPARISON";
const CARS_ID_FOR_COMPARISON = "CARS_ID_FOR_COMPARISON";
const BUTTON_COMPARISON_TOGGLE = "BUTTON_COMPARISON_TOGGLE";

let initialState = {
  carsComparison: {} as ICarsComparison,
  carsIdForComparison: [] as number[],
  buttonComparisonToggle: true,
};

export type InitialStateCarsComparisonType = typeof initialState;
type allTypes =
  | setCarsComparison
  | setCarsIdForComparison
  | setButtonComparisonToggle;

const carsComparisonDataReducer = (
  state = initialState,
  action: allTypes
): InitialStateCarsComparisonType => {
  switch (action.type) {
    case GET_CAR_COMPARISON:
      return { ...state, carsComparison: action.data };
    case CARS_ID_FOR_COMPARISON:
      return { ...state, carsIdForComparison: action.data };
    case BUTTON_COMPARISON_TOGGLE:
      return { ...state, buttonComparisonToggle: action.data };
    default:
      return state;
  }
};

type setCarsComparison = {
  type: typeof GET_CAR_COMPARISON;
  data: ICarsComparison;
};

type setCarsIdForComparison = {
  type: typeof CARS_ID_FOR_COMPARISON;
  data: number[];
};

type setButtonComparisonToggle = {
  type: typeof BUTTON_COMPARISON_TOGGLE;
  data: boolean;
};

const actionsComparison = {
  setCarsComparison: (data: ICarsComparison): setCarsComparison => ({
    type: GET_CAR_COMPARISON,
    data: data,
  }),
  setCarsIdForComparison: (data: number[]): setCarsIdForComparison => ({
    type: CARS_ID_FOR_COMPARISON,
    data: data,
  }),
  setButtonComparisonToggle: (data: boolean): setButtonComparisonToggle => ({
    type: BUTTON_COMPARISON_TOGGLE,
    data: data,
  }),
};

type ThunkTypeAsync = ThunkAction<Promise<void>, StateType, unknown, allTypes>;
type ThunkType = ThunkAction<void, StateType, unknown, allTypes>;

export const setCarsComparisonData = (carId: number[]): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let comparison = await carsComparison.getCarsComparison(carId);
      dispatch(actionsComparison.setCarsComparison(comparison));
    } catch (err) {
      console.log(err);
    }
  };
};

export const changeCarsIdForComparison = (
  carId: number,
  carsIds: number[]
): ThunkType => {
  return (dispatch) => {
    let carsIdArray = carsIds;
    const item = carsIdArray.indexOf(carId);
    item > -1 ? carsIdArray.splice(item, 1) : carsIdArray.push(carId);
    dispatch(actionsComparison.setCarsIdForComparison(carsIdArray));
  };
};

export const setSelectedButtonComparisonToggle = (data: boolean): ThunkType => {
  return (dispatch) => {
    dispatch(actionsComparison.setButtonComparisonToggle(data));
  };
};

export const setCarsComparisonNewData = (
  comparison: ICarsComparison
): ThunkType => {
  return (dispatch) => {
    dispatch(actionsComparison.setCarsComparison(comparison));
  };
};

export const clearComparison = (): ThunkType => {
  return (dispatch) => {
    dispatch(actionsComparison.setCarsIdForComparison([]));
    dispatch(actionsComparison.setCarsComparison({} as ICarsComparison));
  };
};

export default carsComparisonDataReducer;
