import { ThunkAction } from "redux-thunk";
import { IDrivingExperience, IUsers } from "../../api/users/interfaceUsers";
import { StateType } from "../store";
import { initialState } from "./auth-reducer";

export enum ActionsAuthEnum {
  LOGIN_USER = "LOGIN_USER",
  LOGIN_USER_PROGRESS = "LOGIN_USER_PROGRESS",
  LOGIN_USER_ERROR_VISIBLE = "LOGIN_USER_ERROR_VISIBLE",
  LOGIN_USER_FORM_OPEN = "LOGIN_USER_FORM_OPEN",
  LOGIN_USER_AUTH = "LOGIN_USER_AUTH",
  USER_DATA = "USER_DATA",
  DRIVING_EXPERIENCE_DATA = "DRIVING_EXPERIENCE_DATA",
  STATUS_FORM = "STATUS_FORM",
  SET_UUID = "SET_UUID",
  SET_PROFILE_PHOTO = "SET_PROFILE_PHOTO",
  SET_BANNER = "SET_BANNER",
  SET_APP_BANNER_OPEN = "SET_APP_BANNER_OPEN",
}

export type authStatusFormType =
  | "login"
  | "registration"
  | "registration-success"
  | "activation-success"
  | "change-password"
  | "email-restore-password"
  | "restore-password"
  | "bug-report";

export type InitialStateAuthType = typeof initialState;

// для async
export type ThunkTypeAsync = ThunkAction<
  Promise<void>,
  StateType,
  unknown,
  AllTypes
>;
// для sync
export type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;

export type AllTypes =
  | LoginType
  | LoginProgressVisible
  | LoginErrorVisible
  | LoginFormOpen
  | LoginIsAuth
  | UserDataType
  | DrivingExperienceData
  | StatusFormType
  | UuidType
  | SetProfilePhoto
  | SetBanner
  | SetAppBannerOpen;

export type LoginType = {
  type: ActionsAuthEnum.LOGIN_USER;
  data: string;
};

export type LoginProgressVisible = {
  type: ActionsAuthEnum.LOGIN_USER_PROGRESS;
  data: boolean;
};

export type LoginErrorVisible = {
  type: ActionsAuthEnum.LOGIN_USER_ERROR_VISIBLE;
  data: boolean;
};

export type LoginFormOpen = {
  type: ActionsAuthEnum.LOGIN_USER_FORM_OPEN;
  data: boolean;
};

export type LoginIsAuth = {
  type: ActionsAuthEnum.LOGIN_USER_AUTH;
  data: boolean;
};

export type UserDataType = {
  type: ActionsAuthEnum.USER_DATA;
  data: IUsers;
};

export type DrivingExperienceData = {
  type: ActionsAuthEnum.DRIVING_EXPERIENCE_DATA;
  data: IDrivingExperience;
};

export type StatusFormType = {
  type: ActionsAuthEnum.STATUS_FORM;
  data: authStatusFormType;
};

export type UuidType = {
  type: ActionsAuthEnum.SET_UUID;
  data: string;
};

export type SetProfilePhoto = {
  type: ActionsAuthEnum.SET_PROFILE_PHOTO;
  data: string;
};

export type SetBanner = {
  type: ActionsAuthEnum.SET_BANNER;
  data: string;
};

export type SetAppBannerOpen = {
  type: ActionsAuthEnum.SET_APP_BANNER_OPEN;
  data: boolean;
};
