export const formatPrice = (value: number): string => {
  const strValue = String(value);
  const valueLength = strValue.length;

  if (valueLength < 3) {
    return strValue + " P";
  } else if (valueLength < 7) {
    return strValue.replace(/\d{3}$/, "") + " тыс. P"; // 900000
  } else {
    return +(value / 1000000).toFixed(1) + " млн. Р"; // 1000000
  }
};
