// используется в умном фильтре ЦЕНА, и extra Filter год выпуска
import React, { FC, useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import { formatPrice } from "../../utils/formatPrice";
import { currentValueType } from "./DoubleSliderWithInputs";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingBottom: 15,
      maxWidth: "97%",
      height: 120,
    },
    sliderContainer: {},
    slider: {
      color: "#FFBE3F",
    },
    textContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    text: {
      margin: 0,
      fontWeight: "normal",
      fontSize: "10px",
      lineHeight: "14px",
      color: "#FFFFFF",
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "22px",
      color: "#FFBE3F",
      maxHeight: 22,
    },
    titleContainer: {
      display: "flex",
    },
  })
);

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

interface DoubleSliderProps {
  min: number;
  max: number;
  type?: string;
  set(data: currentValueType): void;
  value: currentValueType;
  title?: string;
  step?: number;
}

const ValueLabelComponent = (props: Props) => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const DoubleSlider: FC<DoubleSliderProps> = ({
  set,
  value,
  min,
  max,
  step = 1,
  type,
  title,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState(value);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const arrValue = newValue as number[];
    const [minValue, maxValue] = arrValue;
    setValues({ minValue: minValue, maxValue: maxValue });
  };
  const handleSliderBlur = (event: any, newValue: number | number[]) => {
    const arrValue = newValue as number[];
    const [minValue, maxValue] = arrValue;
    set({ minValue: minValue, maxValue: maxValue });
  };

  useEffect(() => {
    setValues(value);
  }, [value]);

  const isFormatPrice = type === "price";
  return (
    <div className={classes.root}>
      {title && (
        <Typography variant="subtitle1" color="primary">
          {title}
        </Typography>
      )}
      <div className={classes.sliderContainer}>
        <Slider
          className={classes.slider}
          value={[values.minValue, values.maxValue]}
          onChange={handleSliderChange}
          onChangeCommitted={handleSliderBlur}
          valueLabelDisplay="auto"
          ValueLabelComponent={ValueLabelComponent}
          aria-labelledby="range-slider"
          min={min}
          max={max}
          step={step}
        />
        <div className={classes.textContainer}>
          <p className={classes.text}>
            {isFormatPrice
              ? `от ${formatPrice(values.minValue)}`
              : `от ${values.minValue}`}
          </p>
          <p className={classes.text}>
            {isFormatPrice
              ? `до ${formatPrice(values.maxValue)}`
              : `до ${values.maxValue}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DoubleSlider);
