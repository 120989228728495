import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import background from "../../../assets/images/backgroundModal.png";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      outline: "none",
      padding: "60px 80px",
      position: "relative",
      boxShadow:
        "0px 0px 4px rgba(0, 0, 0, 0.25), 0px 5px 4px rgba(0, 0, 0, 0.28)",
      borderRadius: 5,
      backgroundImage: `url('${background}')`,
      backgroundColor: "#6b6a6a",
      backgroundSize: "cover",
      [theme.breakpoints.down(500)]: {
        width: "100%",
        padding: "30px 10px",
      },
    },
    icon: {
      position: "relative",
      backgroundColor: "#FFBE3F",
      width: 72,
      height: 72,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 26,
      cursor: "pointer",
    },
    mobileMenu: {
      backgroundColor: "#FFBE3F",
      margin: 0,
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    link: {
      textDecoration: "none",
    },
    mobileContainer: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    singInButtonContainer: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    menuItem: {
      margin: 0,
      width: 192,
      fontFamily: "Open-sans, sans-serif",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000",
      padding: "7px 16px",
      cursor: "pointer",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#A28C8E",
      },
    },
    popover: {
      position: "absolute",
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: 14,
      backgroundColor: "#FFBE3F",
      marginTop: 5,
      padding: 4,
      borderRadius: 4,
      width: 200,
      top: 0,
      left: "50%",
      transform: "translate(-50%,0)",
      color: "#000",
    },
    cross: {
      position: "absolute",
      top: 0,
      right: 0,
      fontSize: 40,
      color: "#FFBE3F",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);
