import React, { FC, useEffect, useRef, useState } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, CheckboxProps, ListItemText } from "@material-ui/core";

const themeNew = createTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: "#FAC867",
        },
      },
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 208,
    },
    formControl: {
      margin: theme.spacing(0),
      width: "100%",
    },
    select: {
      color: "#FFFFFF",
    },
    label: {
      color: "#FFFFFF",
    },
    icon: {
      color: "#FFFFFF",
    },
  })
);
const useOutlinedInputStyles = makeStyles(() =>
  createStyles({
    root: {
      "& $notchedOutline": {
        borderColor: "#FFFFFF",
      },
      "&:hover $notchedOutline": {
        borderColor: "#FAC867",
      },
      "&$focused $notchedOutline": {
        borderColor: "#FAC867",
      },
    },
    focused: {},
    notchedOutline: {},
  })
);
const CustomCheckbox = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#FFBE3F",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

interface IFilterSelect {
  label: string;
  values: { id: number; name: string }[];
  set(data: number[]): void;
  selected: number[];
}

const FilterSelect: FC<IFilterSelect> = ({ ...props }) => {
  const classes = useStyles();
  const outlinedInputClasses = useOutlinedInputStyles();
  const inputLabel = useRef<HTMLLabelElement>(null);
  const [value, setValue] = useState<number[]>(props.selected);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as number[]);
  };
  const handleClose = (event: any) => {
    props.set(value);
  };

  useEffect(() => {
    setValue(props.selected);
  }, [props.selected]);

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <ThemeProvider theme={themeNew}>
          <InputLabel
            className={classes.label}
            ref={inputLabel}
            htmlFor={props.label}
          >
            {props.label}
          </InputLabel>
          <Select
            className={classes.select}
            value={value}
            multiple
            onChange={handleChange}
            onClose={handleClose}
            MenuProps={MenuProps}
            input={
              <OutlinedInput
                label={props.label}
                id={props.label}
                classes={outlinedInputClasses}
              />
            }
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
            renderValue={() => {
              let renderArray: string[] = [];
              props.selected.forEach((data) => {
                if (props.values) {
                  let result = props.values.find((item) => data === item.id);
                  if (result) {
                    renderArray.push(result.name);
                  }
                }
              });
              return renderArray.join(", ");
            }}
          >
            {props.values &&
              props.values.map((item, index) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    <CustomCheckbox checked={value.indexOf(item.id) > -1} />
                    {/*{item.name}*/}
                    <ListItemText primary={item.name} />
                  </MenuItem>
                );
              })}
          </Select>
        </ThemeProvider>
      </FormControl>
    </div>
  );
};

export default FilterSelect;
