import { IDrivingExperience, IUsers } from "../../api/users/interfaceUsers";
import {
  ActionsAuthEnum,
  AllTypes,
  authStatusFormType,
  InitialStateAuthType,
} from "./auth-types";

export const initialState = {
  access_token: "",
  progressVisible: false, //для бегающей строки загруки
  errorVisible: false, // отображение сообщения об ошибке
  formOpened: false, // открытие и закрытие формы входа
  isAuth: false, // авторизован ли пользователь
  userData: {} as IUsers,
  drivingExperienceData: {} as IDrivingExperience,
  statusForm: "login" as authStatusFormType,
  uuid: null as string | null,
  banner: localStorage.getItem("banner"),
  isAppBannerOpen: true,
};

const authReducer = (
  state = initialState,
  action: AllTypes
): InitialStateAuthType => {
  switch (action.type) {
    case ActionsAuthEnum.LOGIN_USER:
      return { ...state, access_token: action.data };

    case ActionsAuthEnum.LOGIN_USER_PROGRESS:
      return { ...state, progressVisible: action.data };

    case ActionsAuthEnum.LOGIN_USER_ERROR_VISIBLE:
      return { ...state, errorVisible: action.data };

    case ActionsAuthEnum.LOGIN_USER_FORM_OPEN:
      return { ...state, formOpened: action.data };

    case ActionsAuthEnum.LOGIN_USER_AUTH:
      return { ...state, isAuth: action.data };

    case ActionsAuthEnum.USER_DATA:
      return { ...state, userData: action.data };

    case ActionsAuthEnum.DRIVING_EXPERIENCE_DATA:
      return { ...state, drivingExperienceData: action.data };

    case ActionsAuthEnum.STATUS_FORM:
      return { ...state, statusForm: action.data };

    case ActionsAuthEnum.SET_UUID:
      return { ...state, uuid: action.data };

    case ActionsAuthEnum.SET_PROFILE_PHOTO:
      return {
        ...state,
        userData: { ...state.userData, pathToPhoto: action.data },
      };

    case ActionsAuthEnum.SET_BANNER:
      localStorage.setItem("banner", action.data);
      return { ...state, banner: action.data };

    case ActionsAuthEnum.SET_APP_BANNER_OPEN:
      return { ...state, isAppBannerOpen: action.data };

    default:
      return state;
  }
};

export default authReducer;
