import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    "@keyframes shake": {
      "0%": {
        transform: "translateY(-1%)",
      },
      "100%": {
        transform: "translateY(3%)",
      },
    },
    "@keyframes line": {
      "0%": {
        strokeDashoffset: 22,
      },
      "25%": {
        strokeDashoffset: 22,
      },
      "50%": {
        strokeDashoffset: 0,
      },
      "51%": {
        strokeDashoffset: 0,
      },
      "80%": {
        strokeDashoffset: -22,
      },
      "100%": {
        strokeDashoffset: -22,
      },
    },
    loader: {
      marginLeft: -10,
      transform: "scale(0.7)",
    },
    car: {
      "& .car__body": {
        animation: "$shake 0.2s ease-in-out infinite alternate",
      },

      "& .car__line": {
        transformOrigin: "center",
        strokeDasharray: 22,
        animation: "$line 0.8s ease-in-out infinite",
        animationFillMode: "both",
      },
    },
  })
);

const ButtonLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <svg
        className={classes.car}
        width="102"
        height="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          transform="translate(2 1)"
          stroke="#002742"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            className="car__body"
            d="M47.293 2.375C52.927.792 54.017.805 54.017.805c2.613-.445 6.838-.337 9.42.237l8.381 1.863c2.59.576 6.164 2.606 7.98 4.531l6.348 6.732 6.245 1.877c3.098.508 5.609 3.431 5.609 6.507v4.206c0 .29-2.536 4.189-5.687 4.189H36.808c-2.655 0-4.34-2.1-3.688-4.67 0 0 3.71-19.944 14.173-23.902zM36.5 15.5h54.01"
            strokeWidth="3"
          />
          <ellipse
            className="car__wheel_left"
            strokeWidth="3.2"
            fill="#FFF"
            cx="83.493"
            cy="30.25"
            rx="6.922"
            ry="6.808"
          />
          <ellipse
            className="car__wheel_right"
            strokeWidth="3.2"
            fill="#FFF"
            cx="46.511"
            cy="30.25"
            rx="6.922"
            ry="6.808"
          />
          <path
            className="car__line car__line_top"
            d="M22.5 16.5H2.475"
            strokeWidth="3"
          />
          <path
            className="car__line car__line_middle"
            d="M20.5 23.5H.4755"
            strokeWidth="3"
          />
          <path
            className="car__line car__line_bottom"
            d="M25.5 9.5h-19"
            strokeWidth="3"
          />
        </g>
      </svg>
    </div>
  );
};

export default ButtonLoading;
