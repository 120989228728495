import {
  setSelectedAcceleration,
  setSelectedAppearance,
  setSelectedBody,
  setSelectedClass,
  setSelectedComfort,
  setSelectedConsumption,
  setSelectedCross,
  setSelectedDisplacement,
  setSelectedDriver,
  setSelectedDynamism,
  setSelectedEconomy,
  setSelectedEnginePower,
  setSelectedEngineType,
  setSelectedGearbox,
  setSelectedModel,
  setSelectedSafety,
  setSelectedTrunk,
} from "../../../redux/carsDataForRquest-reducer";
import { EnumMainFilter } from "./enumMainFilter";

export const SLIDERS = [
  {
    title: "Проходимость",
    tooltip:
      "Оценка проходимости авто в зависимости от класса авто, типа привода и клиренса",
    value: "carCross",
    set: setSelectedCross,
  },
  {
    title: "Комфорт",
    tooltip: "Оценка владельцев автомобиля",
    value: "carComfort",
    set: setSelectedComfort,
  },
  {
    title: "Экономичность",
    tooltip:
      "Оценка экономичности в зависимости от стоимости обслуживания авто за год",
    value: "carEconomy",
    set: setSelectedEconomy,
  },
  {
    title: "Динамика",
    tooltip: "Оценка ускорения до 100 км/ч",
    value: "carDynamism",
    set: setSelectedDynamism,
  },
  {
    title: "Безопасность",
    tooltip:
      "Оценка безопасности автомобиля согласно тестированию независимыми органами: EuroNCAP, ANCAP",
    value: "carSafety",
    set: setSelectedSafety,
  },
  {
    title: "Внешний вид",
    tooltip: "Оценка владельцев автомобиля",
    value: "carAppearance",
    set: setSelectedAppearance,
  },
];

export const DOUBLE_SLIDERS = [
  {
    title: "Разгон, с",
    min: EnumMainFilter.carAccelerationMin,
    max: EnumMainFilter.carAccelerationMax,
    value: "carAcceleration",
    set: setSelectedAcceleration,
  },
  {
    title: "Багажник, л",
    min: EnumMainFilter.carTrunkMin,
    max: EnumMainFilter.carTrunkMax,
    value: "carTrunk",
    set: setSelectedTrunk,
  },
  {
    title: "Расход, л/100км",
    min: EnumMainFilter.carConsumptionMin,
    max: EnumMainFilter.carConsumptionMax,
    value: "carConsumption",
    set: setSelectedConsumption,
    step: 0.1,
  },
  {
    title: "Объем двигателя, л",
    min: EnumMainFilter.carDisplacementMin,
    max: EnumMainFilter.carDisplacementMax,
    value: "carDisplacement",
    set: setSelectedDisplacement,
    step: 0.1,
  },
  {
    title: "Мощность двигателя, л.с",
    min: EnumMainFilter.carEnginePowerMin,
    max: EnumMainFilter.carEnginePowerMax,
    value: "carEnginePower",
    set: setSelectedEnginePower,
    step: 1,
  },
];

export const FILTER_SELECT = [
  {
    label: "Модель",
    values: "carModels",
    key: "carModels",
    set: setSelectedModel,
    selected: "carModel",
    disabled: "isModelsDisabled",
  },
  {
    label: "Класс",
    values: "carClasses",
    key: "carClasses",
    set: setSelectedClass,
    selected: "carClass",
    disabled: false,
  },
  {
    label: "Привод",
    values: "carDrivers",
    key: "carDrives",
    set: setSelectedDriver,
    selected: "carDriver",
    disabled: false,
  },
  {
    label: "Двигатель",
    values: "carEngineTypes",
    key: "engineTypes",
    set: setSelectedEngineType,
    selected: "carEngineType",
    disabled: false,
  },
  {
    label: "Коробка",
    values: "carGearboxes",
    key: "gearboxes",
    set: setSelectedGearbox,
    selected: "carGearbox",
    disabled: false,
  },
  {
    label: "Кузов",
    values: "carBodies",
    key: "carBodies",
    set: setSelectedBody,
    selected: "carBody",
    disabled: false,
  },
];
