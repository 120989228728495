import { instance } from "../baseURL";
import { IDrivingExperience, IProfilePhoto, IUsers } from "./interfaceUsers";
import { EnumEndPoints } from "../enumEndPoints";

export const users = {
  async getUsers() {
    try {
      const res = await instance.get<IUsers>(EnumEndPoints.userInformation);
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async postUsers(
    bio: string,
    birthDate: string,
    drivingExperienceId: number | null,
    email: string,
    id: number,
    isActivated: boolean,
    linkToAutoru: string,
    linkToDromru: string,
    linkToVk: string,
    name: string,
    pathToPhoto: string,
    phoneNumber: string,
    sex: number | boolean,
    surname: string,
    username: string,
    userRoleId: number
  ) {
    try {
      const res = await instance.put<string>(EnumEndPoints.putUser + id, {
        bio,
        birthDate,
        drivingExperienceId,
        email,
        id,
        isActivated,
        linkToAutoru,
        linkToDromru,
        linkToVk,
        name,
        pathToPhoto,
        phoneNumber,
        sex,
        surname,
        username,
        userRoleId,
      });
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async getDrivingExperience() {
    try {
      const res = await instance.get<IDrivingExperience>(
        EnumEndPoints.drivingExperience
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async activateUser(uuid: string) {
    try {
      const res = await instance.get<{ msg: string }>(
        EnumEndPoints.activateUser,
        {
          params: {
            uuid,
          },
        }
      );
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  async sendActivateEmail() {
    try {
      const res = await instance.get<string>(EnumEndPoints.sendActivateEmail);
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  async sendRestorePasswordEmail(email: string) {
    try {
      const res = await instance.get<string>(
        EnumEndPoints.sendRestorePassword,
        {
          params: {
            email,
          },
        }
      );
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  async sendRestorePasswordLogin(username: string) {
    try {
      const res = await instance.get<string>(
        EnumEndPoints.sendRestorePassword,
        {
          params: {
            username,
          },
        }
      );
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  async restorePassword(uuid: string, new_password: string) {
    try {
      const res = await instance.post<string>(EnumEndPoints.restorePassword, {
        uuid,
        new_password,
      });
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  async changePassword(current_password: string, new_password: string) {
    try {
      const res = await instance.post<string>(EnumEndPoints.changePassword, {
        current_password,
        new_password,
      });
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  async updateProfilePhoto(photo: File) {
    try {
      const data = new FormData();
      data.append("file", photo);
      const res = await instance.put<IProfilePhoto>(
        EnumEndPoints.updateProfilePhoto,
        data
      );
      return res.data.pathToPhoto;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
};
