import { instance } from "../baseURL";
import {
  ICarsGeneration,
  ICarById,
  ICarPricesById,
  ICarDTP,
  ICarPricePredictions,
  ICarMinMaxYear,
  IFeatureOption,
  IMaintenanceCost,
  ICarsFromAvito,
} from "./interfaceCarsGeneration";
import { EnumEndPoints } from "../enumEndPoints";

export const carsGeneration = {
  getCarsGeneration(
    cross: number,
    appearance: number,
    dynamism: number,
    comfort: number,
    economy: number,
    safety: number,
    price_gte: number,
    price_lte: number,
    car_brand_ids: number[],
    car_model_id: string | number,
    car_class_id: string | number,
    gearbox_id: string | number,
    car_body_id: string | number,
    car_drive_id: string | number,
    trunk_volume_min: number,
    trunk_volume_max: number,
    engine_power_min: number,
    engine_power_max: number,
    engine_displacement_min: number,
    engine_displacement_max: number,
    engine_type_id: string | number,
    car_acceleration_min: number,
    car_acceleration_max: number,
    manufacture_year_gte: number,
    manufacture_year_lte: number,
    fuel_consumption_gte: number,
    fuel_consumption_lte: number,
    reason_tag_ids: number[],
    sort_by: string,
    limit: number
  ) {
    return instance
      .get<ICarsGeneration>(EnumEndPoints.filterGenerationCars, {
        params: {
          cross,
          appearance,
          dynamism,
          comfort,
          economy,
          safety,
          price_gte,
          price_lte,
          car_brand_ids,
          car_model_id,
          car_class_id,
          gearbox_id,
          car_body_id,
          car_drive_id,
          trunk_volume_min,
          trunk_volume_max,
          engine_power_min,
          engine_power_max,
          engine_displacement_min,
          engine_displacement_max,
          engine_type_id,
          car_acceleration_min,
          car_acceleration_max,
          manufacture_year_gte,
          manufacture_year_lte,
          fuel_consumption_gte,
          fuel_consumption_lte,
          reason_tag_ids,
          sort_by,
          limit,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  },
  getCarById(carId: number) {
    return instance
      .get<ICarById>(`${EnumEndPoints.generationCars}${carId}`)
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  getCarPricesById(carId: number) {
    return instance
      .get<ICarPricesById[]>(`${EnumEndPoints.prices}`, {
        params: {
          generation_id: carId,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  getCarPricePredictions(carId: number) {
    return instance
      .get<ICarPricePredictions[]>(EnumEndPoints.pricePredictions, {
        params: {
          generation_id: carId,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  getCarDTP(carId: number) {
    return instance
      .get<ICarDTP[]>(`${EnumEndPoints.dtp}`, {
        params: {
          generation_id: carId,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
  async getCarMinMaxYear(carId: number) {
    try {
      const res = await instance.get<ICarMinMaxYear>(
        EnumEndPoints.generationCarsMinMax,
        {
          params: {
            generation_id: carId,
          },
        }
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async getFeatureOption(carId: number) {
    try {
      const res = await instance.get<IFeatureOption>(
        `${EnumEndPoints.cars}/${carId}`
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async getBuyingRecommendation(carId: number) {
    try {
      const res = await instance.get<string[]>(
        EnumEndPoints.buyingRecommendations,
        {
          params: {
            generation_id: carId,
          },
        }
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async getMaintenanceCost(
    car_id: number,
    annual_mileage: number,
    person_age_less_22: boolean,
    person_experience_less_3: boolean
  ) {
    try {
      const res = await instance.get<IMaintenanceCost>(
        EnumEndPoints.maintenanceCost,
        {
          params: {
            car_id,
            annual_mileage,
            person_age_less_22,
            person_experience_less_3,
          },
        }
      );
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
  async getCarsGenerationByModel(id: number, limit: number) {
    try {
      const res = await instance.get<ICarsGeneration>(
        EnumEndPoints.filterGenerationCars,
        {
          params: {
            car_model_id: id,
            limit: limit,
          },
        }
      );
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
  // получение данных с сайтом AVITO, DROM, AUTO.ru для отображения на странице car
  async getCarsFromAvito(id: number) {
    try {
      const res = await instance.get<ICarsFromAvito[]>(
        `${EnumEndPoints.carsFromAvito}/${id}/classifieds`
      );
      return res.data;
    } catch (err) {
      return await Promise.reject(err);
    }
  },
};

// cross=0&                                                - Умный фильтр: проходимость
// appearance=0&                                           - Умный фильтр: Внешний вид
// dynamism=0&                                             - Умный фильтр: Динамичность
// comfort=0&                                              - Умный фильтр: Комфорт
// economy=0&                                              - Умный фильтр: Экономичность (Стоимость обслуживания)
// safety=0&                                               - Умный фильтр: Безопасность
// price_gte=0&                                            - Умный фильтр: Левая граница в фильтре "Цена"
// price_lte=8000000&                                      - Умный фильтр: Правая граница в фильтре "Цена"
// car_brand_ids[]=9&car_brand_ids[]=23&car_brand_ids[]=17 - id выбранных брендов. Тут выбрано три разных бренда.
// car_model_id=&                                          - id выбранный моделей. Может быть выбрано несколько.
// car_class_id=&                                          - id класса
// gearbox_id=&                                            - id коробки передач
// car_body_id=&                                           - id кузова
// car_drive_id=&                                          - id двигателя
// trunkVolumeMin=49&                                    - минимальный объем багажника
// trunkVolumeMax=4880&                                  - максимальный объем багажника
// engine_power_min=28&                                    - минимальная мощность двигателя
// engine_power_max=1101&                                  - максмимальная мощность двигателя
// engine_displacement_min=0.5&                            - минимальный объем двигателя
// engine_displacement_max=8.5&                            - максимальный объем двигателя
// car_acceleration_min=2&                                 - левая граница в фильтре "Разгон"
// car_acceleration_max=36&                                - правая граница в фильтре "Разгон"
// manufacture_year_gte=1950&                              - левая граница в фильтре "Год выпуска"
// manufacture_year_lte=2020&                              - правая граница в фильтре "Год выпуска"
// city_fuel_consumption_gte=3&                            - Левая граница фильтра расход топлива по городу.
// city_fuel_consumption_lte=42&                           - Правая граница фильтра расход топлива по городу.
// highway_fuel_consumption_gte=2.5&                       - Левая граница фильтра расход топлива по трассе.
// highway_fuel_consumption_lte=16.6&                      - Правая граница фильтра расход топлива по трассе.
// combined_fuel_consumption_gte=2.9&                      - Левая граница фильтра расход топлива смешанная.
// combined_fuel_consumption_lte=25                        - Правая граница фильтра расход топлива смешанная.
// sort_by=&                                               - Сортировать по
// limit=&                                                 - кол-во карточек
