import { authAPI } from "../../api/auth/auth";
import { IDrivingExperience, IUsers } from "../../api/users/interfaceUsers";
import { users } from "../../api/users/users";
import {
  ActionsAuthEnum,
  authStatusFormType,
  DrivingExperienceData,
  LoginErrorVisible,
  LoginFormOpen,
  LoginIsAuth,
  LoginProgressVisible,
  LoginType,
  SetAppBannerOpen,
  SetBanner,
  SetProfilePhoto,
  StatusFormType,
  ThunkType,
  ThunkTypeAsync,
  UserDataType,
  UuidType,
} from "./auth-types";

const actionsAuth = {
  loginProgressVisible: (data: boolean): LoginProgressVisible => ({
    type: ActionsAuthEnum.LOGIN_USER_PROGRESS,
    data,
  }),
  login: (data: string): LoginType => ({
    type: ActionsAuthEnum.LOGIN_USER,
    data,
  }),
  loginErrorVisible: (data: boolean): LoginErrorVisible => ({
    type: ActionsAuthEnum.LOGIN_USER_ERROR_VISIBLE,
    data,
  }),
  loginFormOpen: (data: boolean): LoginFormOpen => ({
    type: ActionsAuthEnum.LOGIN_USER_FORM_OPEN,
    data,
  }),
  loginIsAuth: (data: boolean): LoginIsAuth => ({
    type: ActionsAuthEnum.LOGIN_USER_AUTH,
    data,
  }),
  setUserData: (data: IUsers): UserDataType => ({
    type: ActionsAuthEnum.USER_DATA,
    data: data,
  }),
  setDrivingExperienceData: (
    data: IDrivingExperience
  ): DrivingExperienceData => ({
    type: ActionsAuthEnum.DRIVING_EXPERIENCE_DATA,
    data: data,
  }),
  setStatusForm: (data: authStatusFormType): StatusFormType => ({
    type: ActionsAuthEnum.STATUS_FORM,
    data: data,
  }),
  setUuid: (data: string): UuidType => ({
    type: ActionsAuthEnum.SET_UUID,
    data: data,
  }),
  setProfilePhoto: (pathToPhoto: string): SetProfilePhoto => ({
    type: ActionsAuthEnum.SET_PROFILE_PHOTO,
    data: pathToPhoto,
  }),
  setBanner: (data: string): SetBanner => ({
    type: ActionsAuthEnum.SET_BANNER,
    data,
  }),
  setAppBannerOpen: (data: boolean): SetAppBannerOpen => ({
    type: ActionsAuthEnum.SET_APP_BANNER_OPEN,
    data,
  }),
};

export const authUserName = (
  username: string,
  password: string
): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsAuth.loginProgressVisible(true));
    dispatch(actionsAuth.loginErrorVisible(true));
    try {
      let data = await authAPI.loginUserName(username, password);
      dispatch(actionsAuth.loginProgressVisible(false));
      dispatch(actionsAuth.login(data.access_token));
      dispatch(actionsAuth.loginFormOpen(false));
      dispatch(actionsAuth.loginIsAuth(true));
    } catch (err) {
      dispatch(actionsAuth.loginProgressVisible(false));
      dispatch(actionsAuth.loginErrorVisible(false));
      console.log(err);
    }
  };
};

export const authEmail = (email: string, password: string): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsAuth.loginProgressVisible(true));
    dispatch(actionsAuth.loginErrorVisible(true));
    try {
      let data = await authAPI.loginEmail(email, password);
      dispatch(actionsAuth.loginProgressVisible(false));
      dispatch(actionsAuth.login(data.access_token));
      dispatch(actionsAuth.loginFormOpen(false));
      dispatch(actionsAuth.loginIsAuth(true));
    } catch (err) {
      dispatch(actionsAuth.loginProgressVisible(false));
      dispatch(actionsAuth.loginErrorVisible(false));
      console.log(err);
    }
  };
};

export const errorVisibleMessage = (): ThunkType => {
  return (dispatch) => {
    dispatch(actionsAuth.loginErrorVisible(true));
  };
};

export const setFormOpen = (data: boolean): ThunkType => {
  return (dispatch) => {
    dispatch(actionsAuth.loginFormOpen(data));
  };
};

export const registrationUser = (
  email: string,
  password: string,
  username?: string
): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsAuth.loginProgressVisible(true));
    dispatch(actionsAuth.loginErrorVisible(true));
    try {
      await authAPI.registration(email, password, username);
      dispatch(actionsAuth.loginProgressVisible(false));
    } catch (err) {
      dispatch(actionsAuth.loginProgressVisible(false));
      dispatch(actionsAuth.loginErrorVisible(false));
      return await Promise.reject(err);
    }
  };
};

export const setUserData = (): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsAuth.loginProgressVisible(true));

    try {
      let userData = await users.getUsers();
      dispatch(actionsAuth.loginProgressVisible(false));

      //TODO: временный костыль, из за того, что на бэке 0 и 1 конвертируются в true, false
      userData = { ...userData, sex: Number(userData.sex) };
      dispatch(actionsAuth.setUserData(userData));
      dispatch(actionsAuth.loginIsAuth(true));
    } catch (err) {
      dispatch(actionsAuth.loginIsAuth(false));
      dispatch(actionsAuth.loginProgressVisible(false));

      console.log(err);
    }
  };
};

export const putUserData = ({
  bio,
  birthDate,
  drivingExperienceId,
  email,
  id,
  isActivated,
  linkToAutoru,
  linkToDromru,
  linkToVk,
  name,
  pathToPhoto,
  phoneNumber,
  sex,
  surname,
  username,
  userRoleId,
}: IUsers): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      await users.postUsers(
        bio,
        birthDate,
        drivingExperienceId,
        email,
        id,
        isActivated,
        linkToAutoru,
        linkToDromru,
        linkToVk,
        name,
        pathToPhoto,
        phoneNumber,
        sex,
        surname,
        username,
        userRoleId
      );
      dispatch(
        actionsAuth.setUserData({
          bio,
          birthDate,
          drivingExperienceId,
          email,
          id,
          isActivated,
          linkToAutoru,
          linkToDromru,
          linkToVk,
          name,
          pathToPhoto,
          phoneNumber,
          sex,
          surname,
          username,
          userRoleId,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
};

export const clearToken = (): ThunkType => {
  return (dispatch) => {
    dispatch(actionsAuth.login(""));
    dispatch(actionsAuth.loginIsAuth(false));
  };
};

export const setDrivingExperienceData = (): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let res = await users.getDrivingExperience();
      dispatch(actionsAuth.setDrivingExperienceData(res));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setStatusForm = (status: authStatusFormType): ThunkType => {
  return (dispatch) => {
    dispatch(actionsAuth.setStatusForm(status));
  };
};

export const setUuid = (value: string): ThunkType => {
  return (dispatch) => {
    dispatch(actionsAuth.setUuid(value));
  };
};

export const setProfilePhoto = (photo: File): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsAuth.loginProgressVisible(true));
    try {
      const res = await users.updateProfilePhoto(photo);
      dispatch(actionsAuth.setProfilePhoto(res));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(actionsAuth.loginProgressVisible(false));
    }
  };
};

export const setSberbankBanner =
  (banner: string): ThunkType =>
  (dispatch) => {
    dispatch(actionsAuth.setBanner(banner));
  };

export const setAppBannerOpen =
  (isOpen: boolean): ThunkType =>
  (dispatch) =>
    dispatch(actionsAuth.setAppBannerOpen(isOpen));
