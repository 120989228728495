import { instance } from "../baseURL";
import { EnumEndPoints } from "../enumEndPoints";
import { IFeedback, IFeedbackRes } from "./interfaceFeedback";

export const feedbackAPI = {
  async postFeedback(feedback: IFeedback) {
    try {
      const res = await instance.post<IFeedbackRes>(EnumEndPoints.feedback, {
        name: feedback.username,
        email: feedback.email,
        message: feedback.message,
      });
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
};
