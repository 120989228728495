const BASE_URL: string | undefined = process.env.REACT_APP_BASE_URL;

export const staticAPI = {
  getImage: (imageId: string) => `${BASE_URL}/api/images/${imageId}`,
  getLogoImage: (imageId: string) => `${BASE_URL}/images/${imageId}`,
  // аватар в профиле
  getProfilePhotos: (photoId: string) =>
    `${BASE_URL}/images/profile_photos/${photoId}`,
  // фото авто в профиле
  getUsersCarsPhotos: (photoId: string) =>
    `${BASE_URL}/user_car_photos/${photoId}`,
};
