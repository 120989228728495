import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import { useDropzone } from "react-dropzone";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ImageIcon from "@material-ui/icons/Image";
import CloseIcon from "@material-ui/icons/Close";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { enumToasts } from "../../utils/enumToasts";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 10,
    },
    uploadContainer: {
      cursor: "pointer",
    },
    dropZone: {
      height: "100%",
      borderColor: "#fff",
      border: "1px dashed",
      borderRadius: "4px",
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: 10,
      outline: "none",
    },
    iconImage: {
      color: "#fff",
    },
    iconUpload: {
      fontSize: "40px",
      color: "#fff",
    },
    textUpload: {
      margin: 0,
      textAlign: "center",
      color: "#fff",
      fontSize: 14,
    },
    loadedRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "0 0 10px",
    },
    loadedName: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: 14,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "19px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "#fff",
      opacity: ".7",
      margin: 0,
    },
  })
);

interface DropzoneFieldProps {
  limitFiles: number;
  handleOnDrop(files: any): void;
}

const DropZoneField: FC<DropzoneFieldProps> = ({
  limitFiles = 6,
  handleOnDrop,
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles: limitFiles,
    accept: "image/jpeg, image/png, image/gif",
  });
  useEffect(() => {
    if (fileRejections.length > 0) {
      if (fileRejections[0].errors[0].code === "file-invalid-type") {
        toast.error(enumToasts.differentTypeFile);
      }
      if (fileRejections[0].errors[0].code === "too-many-files") {
        toast.error(enumToasts.maxFiles + limitFiles);
      }
    }
  }, [fileRejections, limitFiles]);

  useEffect(() => {
    setFiles((state) => {
      if (state.length + acceptedFiles.length > limitFiles) {
        toast.error(enumToasts.maxFiles + limitFiles);
        return state;
      }

      acceptedFiles.forEach((acceptedItem) => {
        const item = state.find((stateItem) => {
          return stateItem.name === acceptedItem.name;
        });
        if (!item) {
          state.push(acceptedItem);
        } else {
          toast.error(enumToasts.sameFiles);
        }
      });
      return [...state];
    });
  }, [acceptedFiles, limitFiles]);

  useEffect(() => {
    handleOnDrop(files);
    // eslint-disable-next-line
  }, [files]);

  const handleDeleteFile = (item: any) => {
    setFiles((state) => {
      return state.filter((elem) => elem.name !== item.name);
    });
  };
  return (
    <div className={classes.root}>
      {files.map((item) => (
        <div className={classes.loadedRow} key={item.name}>
          <ImageIcon className={classes.iconImage} />
          <p className={classes.loadedName}>{item.name}</p>
          <CloseIcon
            onClick={() => handleDeleteFile(item)}
            color="primary"
            cursor="pointer"
          />
        </div>
      ))}

      {files.length < limitFiles && (
        <div {...getRootProps()} className={cn(classes.dropZone)}>
          <input {...getInputProps()} />
          <CloudUploadIcon className={classes.iconUpload} />
          {files.length < 1 && (
            <p className={classes.textUpload}>
              Кликните или перетащите изображение в эту область для загрузки.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default DropZoneField;
