import React, { FC } from "react";

import { connect, useDispatch } from "react-redux";
import { StateType } from "../../../redux/store";
import {
  InitialStateCarsGenerationType,
  setCarsGenerationData,
  setPagePagination,
} from "../../../redux/carsGenerationData-reducer";
import {
  InitialStateCarsForRequestType,
  setSelectedSort,
} from "../../../redux/carsDataForRquest-reducer";
import { makeStyles } from "@material-ui/core/styles";

import FilterSelect from "../../../components/Selects/FilterSelect";
import Fade from "@material-ui/core/Fade";
import MainPageGenerationCarsPagination from "./mainPageGenerationCarsPagination";
import { ICard } from "../../../api/carsGeneration/interfaceCarsGeneration";
import { EnumMainFilter } from "../MainFilter/enumMainFilter";
import useCarsDataForRequest from "../../../hooks/useCarsDataForRequest";
import CarCard from "../../../components/Cards/CarCard/CarCard";
const sortedArray = [
  {
    id: "cross",
    name: "Проходимость",
  },
  {
    id: "appearance",
    name: "Внешний вид",
  },
  {
    id: "dynamism",
    name: "Динамика",
  },
  {
    id: "comfort",
    name: "Комфорт",
  },
  {
    id: "economy",
    name: "Экономичность",
  },
  {
    id: "safety",
    name: "Безопасность",
  },
  {
    id: "price",
    name: "По средней цене",
  },
  {
    id: "aspects_sum",
    name: "По положительным отзывам",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: "border-box",
    padding: "0px 48px 55px",
    background:
      "linear-gradient(0deg, rgba(39, 30, 12, 0.85) 0%, rgba(39, 30, 12, 0.90) 48.56%, #271E0C 82.21%, #271E0C 100%)",
    boxShadow: "-2px -15px 15px -9px rgba(39,30,12,1)",
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px 35px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0 10px 15px",
    },
  },
  cardList: {
    margin: "0 auto",
    padding: "48px 0 60px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(336px, 1fr));",
    gridRowGap: "18px",
    gridColumnGap: "18px",

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr));",
    },
  },
  topLine: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #fff",
    paddingTop: 44,
  },
  selector: {
    width: "100%",
    maxWidth: 209,
  },
  arrowContainer: {
    display: "flex",
  },
  arrowLeft: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #FFF",
    boxSizing: "border-box",
    borderRadius: "4px 0px 0px 4px",
    marginRight: 18,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFBE3F",
    },
  },
  arrowRight: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #FFF",
    boxSizing: "border-box",
    borderRadius: "0px 4px 4px 0px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FFBE3F",
    },
  },
  extraText: {
    paddingBottom: 25,

    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "16px",

    color: "#FFFFFF",
  },
}));

interface IMainPageGenerationCarsList {
  carsGenerationData: InitialStateCarsGenerationType;
  carsDataForRequest: InitialStateCarsForRequestType;
  setSelectedSort(sort_by: string): void;
  setCarsGenerationData(
    cross: number,
    appearance: number,
    dynamism: number,
    comfort: number,
    economy: number,
    safety: number,
    price_gte: number,
    price_lte: number,
    car_brand_ids: number[],
    car_model_id: string | number,
    car_class_id: string | number,
    gearbox_id: string | number,
    car_body_id: string | number,
    car_drive_id: string | number,
    trunkVolumeMin: number,
    trunkVolumeMax: number,
    engine_power_min: number,
    engine_power_max: number,
    engine_displacement_min: number,
    engine_displacement_max: number,
    engine_type_id: string | number,
    car_acceleration_min: number,
    car_acceleration_max: number,
    manufacture_year_gte: number,
    manufacture_year_lte: number,
    fuel_consumption_gte: number,
    fuel_consumption_lte: number,
    reason_tag_ids: number[],
    sort_by: string,
    limit: number
  ): void;
  setPagePagination(data: number): void;
  scrollToRes: () => void;
}

const MainPageGenerationCarsList: FC<IMainPageGenerationCarsList> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataForRequest = useCarsDataForRequest();

  const getCarsGeneration = async (value: string) => {
    props.setSelectedSort(value);
    await dispatch(
      setCarsGenerationData(
        dataForRequest.carCross,
        dataForRequest.carAppearance,
        dataForRequest.carDynamism,
        dataForRequest.carComfort,
        dataForRequest.carEconomy,
        dataForRequest.carSafety,
        dataForRequest.carPrice.minValue,
        dataForRequest.carPrice.maxValue,
        dataForRequest.carsBrands,
        dataForRequest.carModel,
        dataForRequest.carClass,
        dataForRequest.carGearbox,
        dataForRequest.carBody,
        dataForRequest.carDriver,
        dataForRequest.carTrunk.minValue,
        dataForRequest.carTrunk.maxValue,
        dataForRequest.carEnginePower.minValue,
        dataForRequest.carEnginePower.maxValue,
        dataForRequest.carDisplacement.minValue,
        dataForRequest.carDisplacement.maxValue,
        dataForRequest.carEngineType,
        dataForRequest.carAcceleration.minValue,
        dataForRequest.carAcceleration.maxValue,
        dataForRequest.carYear.minValue,
        dataForRequest.carYear.maxValue,
        dataForRequest.carConsumption.minValue,
        dataForRequest.carConsumption.maxValue,
        dataForRequest.carReason,
        value,
        EnumMainFilter.carLimit
      )
    );
  };

  const cars = props.carsGenerationData.carsGeneration;
  // console.log(
  //   "props.carsGenerationData.carsGeneration",
  //   props.carsGenerationData.carsGeneration
  // );
  const carsForRendering =
    cars.cars && cars.recommended_cars
      ? [...cars.cars, ...cars.recommended_cars]
      : cars.cars;

  const page = props.carsGenerationData.pagePagination;
  const showCards = (): ICard[] => {
    let start = (page - 1) * 6;
    let end = start + 6;
    return carsForRendering ? carsForRendering.slice(start, end) : [];
  };
  const maxPage = carsForRendering ? Math.ceil(carsForRendering.length / 6) : 0;

  const updatePage = (page: number) => {
    if (page < 1) {
      page = 1;
    } else if (page > maxPage) {
      page = maxPage;
    }
    props.setPagePagination(page);
    props.scrollToRes();
  };
  return (
    <div className={classes.root}>
      <div className={classes.topLine}>
        <div className={classes.selector}>
          <FilterSelect
            label="Сортировать по"
            value={sortedArray}
            disabled={false}
            set={getCarsGeneration}
            selected={
              props.carsDataForRequest.carSort
                ? props.carsDataForRequest.carSort
                : ""
            }
          />
        </div>
        <div className={classes.arrowContainer}>
          <div
            onClick={() => updatePage(page - 1)}
            className={classes.arrowLeft}
          >
            <svg
              width="15"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.82 21.18L5.66 12l9.16-9.18L12 0 0 12l12 12 2.82-2.82z"
                fill="#fff"
              />
            </svg>
          </div>
          <div
            onClick={() => updatePage(page + 1)}
            className={classes.arrowRight}
          >
            <svg
              width="15"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M.18 2.82L9.34 12 .18 21.18 3 24l12-12L3 0 .18 2.82z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <Fade
          in={props.carsGenerationData.carsGeneration.cars && true}
          timeout={1900}
        >
          <div className={classes.cardList}>
            {showCards().map((data) => (
              <CarCard data={data} key={data.generationId} />
            ))}
          </div>
        </Fade>
        {/* <p className={classes.extraText}>
          * Этот автомобиль не в полной мере соответстует вашим запросам, но
          максимально близок к ним.
        </p> */}
        {maxPage > 1 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <MainPageGenerationCarsPagination
              set={updatePage}
              page={page}
              count={maxPage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default connect(
  (state: StateType) => ({
    carsGenerationData: state.carsGenerationData,
    carsDataForRequest: state.carsDataForRequest,
  }),
  {
    setSelectedSort,
    setCarsGenerationData,
    setPagePagination,
  }
)(MainPageGenerationCarsList);
