import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    notData: {
      maxWidth: 518,
      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 24,
      color: "#fff",
      margin: 0,
      padding: 30,
      textAlign: "center",
    },
  })
);

interface Props {
  text: string;
}

const NoData: FC<Props> = (props) => {
  const classes = useStyles();

  return <p className={classes.notData}>{props.text}</p>;
};

export default NoData;
