import React, { FC, ReactNode } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: "relative",
      "&:hover $tooltip": {
        visibility: "visible",
        opacity: 1,
      },
    },
    tooltip: {
      padding: "4px 8px",
      maxWidth: 200,
      width: "max-content",

      fontFamily: "'Roboto'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "120%",

      color: "#FFFFFF",
      borderRadius: "4px",
      backgroundColor: "rgba(97, 97, 97, 0.9)",

      visibility: "hidden",
      opacity: 0,

      position: "absolute",
      top: "20px",
      left: "0",
      zIndex: 1000,
      transform: "none",

      transition: "all 0.5s ease-in-out",
      [theme.breakpoints.up(600)]: {
        maxWidth: 200,
        top: "20px",
        left: "-40px",
        transform: "translate(50%, 0)",
      },
    },
    poz: {
      position: "absolute",
      top: -5,
      left: 20,
      cursor: "pointer",
    },
  })
);

interface I {
  title?: string;
  children: ReactNode;
}

const CustomTooltipCard: FC<I> = ({ title, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.tooltip}>{title}</p>
      {children}
    </div>
  );
};

export default CustomTooltipCard;
