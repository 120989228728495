import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      margin: "0 auto",
      width: 302,
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
    },
    title: {
      margin: "0 auto 24px",
      maxWidth: "340px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
    text: {
      margin: "0 auto",
      padding: "5px 10px",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#fff",
      textAlign: "center"
    },
  })
);

const FooterFeedbackSuccess: FC = () => {
  const classes = useStyles();

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <div className={classes.content}>
        <h3 className={classes.title}>Спасибо</h3>
        <p className={classes.text}>Ваш вопрос успешно отправлен.</p>
      </div>
    </Grow>
  );
};

export default FooterFeedbackSuccess;
