import React, { FC } from "react";
import { useSelector } from "react-redux";

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Grow from "@material-ui/core/Grow";

import background from "../../../assets/images/backgroundModal.png";
import FooterFeedbackForm from "./FooterFeedbackForm";
import FooterFeedbackSuccess from "./FooterFeedbackSuccess";
import { StateType } from "../../../redux/store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: "60px 0",
      boxSizing: "border-box",
      width: "600px",
      maxHeight: "75vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      outline: "none",
      boxShadow:
        "0px 0px 4px rgba(0, 0, 0, 0.25), 0px 5px 4px rgba(0, 0, 0, 0.28)",
      borderRadius: 5,
      backgroundImage: `url('${background}')`,
      backgroundColor: "#6b6a6a",
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        padding: "30px 0",
        width: "100%",
        maxHeight: "90vh",
      },
    },
    cross: {
      position: "absolute",
      top: 0,
      right: 0,
      fontSize: 40,
      color: "#FFBE3F",
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);

interface I {
  isOpen: boolean;
  onClose: () => void;
}

const FooterFeedbackModal: FC<I> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { isSuccess } = useSelector((state: StateType) => state.feedback);

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      onClose={onClose}
      closeAfterTransition
    >
      <Grow
        in={isOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...{ timeout: 700 }}
      >
        <div className={classes.paper}>
          <CloseIcon onClick={onClose} className={classes.cross} />
          {isSuccess ? <FooterFeedbackSuccess /> : <FooterFeedbackForm />}
        </div>
      </Grow>
    </Modal>
  );
};

export default FooterFeedbackModal;
