import { carsGeneration } from "../api/carsGeneration/carsGeneration";
import { ICarsGeneration } from "../api/carsGeneration/interfaceCarsGeneration";

import { StateType } from "./store";
import { ThunkAction } from "redux-thunk";

const GET_CARS_GENERATION = "GET_CARS_GENERATION";
const CAR_LOADING = "CAR_LOADING";
const GET_PAGE_PAGINATION = "GET_PAGE_PAGINATION";
const ASPECT_ID_FOR_BUG_REPORT = "ASPECT_ID_FOR_BUG_REPORT";

let initialState = {
  carsGeneration: {} as ICarsGeneration,
  loadingVisible: false,
  pagePagination: 1 as number,
  aspectIdForBugReport: 0 as number,
};

export type InitialStateCarsGenerationType = typeof initialState;
type allTypes =
  | setCarsGeneration
  | setLoadingVisible
  | setPagePagination
  | aspectIdForBugReportType;

const carsGenerationDataReducer = (
  state = initialState,
  action: allTypes
): InitialStateCarsGenerationType => {
  switch (action.type) {
    case GET_CARS_GENERATION:
      return { ...state, carsGeneration: action.data };
    case CAR_LOADING:
      return { ...state, loadingVisible: action.data };
    case GET_PAGE_PAGINATION:
      return { ...state, pagePagination: action.data };
    case ASPECT_ID_FOR_BUG_REPORT:
      return { ...state, aspectIdForBugReport: action.data };
    default:
      return state;
  }
};

type setCarsGeneration = {
  type: typeof GET_CARS_GENERATION;
  data: ICarsGeneration;
};
type setLoadingVisible = {
  type: typeof CAR_LOADING;
  data: boolean;
};
type setPagePagination = {
  type: typeof GET_PAGE_PAGINATION;
  data: number;
};

type aspectIdForBugReportType = {
  type: typeof ASPECT_ID_FOR_BUG_REPORT;
  data: number;
};

const actionsCars = {
  setCarsGeneration: (data: ICarsGeneration): setCarsGeneration => ({
    type: GET_CARS_GENERATION,
    data: data,
  }),
  setLoadingVisible: (data: boolean): setLoadingVisible => ({
    type: CAR_LOADING,
    data: data,
  }),
  setPagePagination: (data: number): setPagePagination => ({
    type: GET_PAGE_PAGINATION,
    data: data,
  }),
  setAspectIdForBugReport: (data: number): aspectIdForBugReportType => ({
    type: ASPECT_ID_FOR_BUG_REPORT,
    data: data,
  }),
};

type ThunkType = ThunkAction<void, StateType, unknown, allTypes>;
type ThunkTypeAsync = ThunkAction<Promise<void>, StateType, unknown, allTypes>;

export const setCarsGenerationData = (
  cross: number,
  appearance: number,
  dynamism: number,
  comfort: number,
  economy: number,
  safety: number,
  price_gte: number,
  price_lte: number,
  car_brand_ids: number[],
  car_model_id: string | number,
  car_class_id: string | number,
  gearbox_id: string | number,
  car_body_id: string | number,
  car_drive_id: string | number,
  trunk_volume_min: number,
  trunk_volume_max: number,
  engine_power_min: number,
  engine_power_max: number,
  engine_displacement_min: number,
  engine_displacement_max: number,
  engine_type_id: string | number,
  car_acceleration_min: number,
  car_acceleration_max: number,
  manufacture_year_gte: number,
  manufacture_year_lte: number,
  fuel_consumption_gte: number,
  fuel_consumption_lte: number,
  reason_tag_ids: number[],
  sort_by: string,
  limit: number
): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsCars.setLoadingVisible(true));
    try {
      let generation = await carsGeneration.getCarsGeneration(
        cross,
        appearance,
        dynamism,
        comfort,
        economy,
        safety,
        price_gte,
        price_lte,
        car_brand_ids,
        car_model_id,
        car_class_id,
        gearbox_id,
        car_body_id,
        car_drive_id,
        trunk_volume_min,
        trunk_volume_max,
        engine_power_min,
        engine_power_max,
        engine_displacement_min,
        engine_displacement_max,
        engine_type_id,
        car_acceleration_min,
        car_acceleration_max,
        manufacture_year_gte,
        manufacture_year_lte,
        fuel_consumption_gte,
        fuel_consumption_lte,
        reason_tag_ids,
        sort_by,
        limit
      );
      // console.log(`generation`, generation);
      dispatch(actionsCars.setLoadingVisible(false));
      dispatch(actionsCars.setPagePagination(1));
      dispatch(actionsCars.setCarsGeneration(generation));
    } catch (err) {
      dispatch(actionsCars.setLoadingVisible(false));
      return await Promise.reject(err);
    }
  };
};

export const setStateLoadingVisible = (data: boolean): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCars.setLoadingVisible(data));
  };
};

export const setPagePagination = (data: number): ThunkType => {
  return async (dispatch) => {
    dispatch(actionsCars.setPagePagination(data));
  };
};

export const setAspectIdForBugReport = (data: number): ThunkType => {
  return (dispatch) => {
    dispatch(actionsCars.setAspectIdForBugReport(data));
  };
};
export default carsGenerationDataReducer;
