import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 14,
      maxWidth: 302,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    text: {
      margin: 0,
      fontSize: "12px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: "rgba(255, 255, 255, 0.7)",
    },
    reg: {
      margin: 0,
      fontSize: "12px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      textTransform: "uppercase",
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover": {
        cursor: "pointer",
        color: "#FAC867",
      },
    },
  })
);
interface IHeaderSignInUiBottom {
  questionText: string;
  linkText: string;
  changeForm: () => void;
}

const HeaderSignInUiBottom: FC<IHeaderSignInUiBottom> = ({
  linkText,
  changeForm,
  questionText,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p className={classes.text}>{questionText}</p>
      <p onClick={changeForm} className={classes.reg}>
        {linkText}
      </p>
    </div>
  );
};

export default HeaderSignInUiBottom;
