import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      maxHeight: 18,
      margin: "0 auto",
    },
    progress: {
      padding: 7,
      backgroundColor: "#fff",
      boxShadow: "inset 0 -1px 0 #C4C4C4, inset 0 1px 0 #C4C4C4",
      flex: 1,
    },
    barColorFour: { backgroundColor: "#A28C8E" },
    barColorSix: { backgroundColor: "#666666" },
    barColorEight: { backgroundColor: "#9E8D6F" },
    barColorTen: { backgroundColor: "#FAC764" },
    value: {
      backgroundColor: "#675229",
      color: "#fff",
      width: 37,
      textAlign: "center",
      fontSize: 14,
      lineHeight: "21px",
      margin: 0,
    },
  })
);

interface IDataProgress {
  value: number;
  width?: number;
}

const DataProgress: FC<IDataProgress> = ({ value, width = 218 }) => {
  const classes = useStyles();

  const setColor = (value: number) => {
    if (value <= 40) {
      return classes.barColorFour;
    } else if (value <= 60) {
      return classes.barColorSix;
    } else if (value <= 80) {
      return classes.barColorEight;
    } else {
      return classes.barColorTen;
    }
  };
  return (
    <div className={classes.root} style={{ maxWidth: width }}>
      <LinearProgress
        className={classes.progress}
        classes={{ barColorPrimary: setColor(value * 10) }}
        variant="determinate"
        value={value * 10}
      />
      <p className={classes.value}>{value}</p>
    </div>
  );
};

export default DataProgress;
