export enum enumToasts {
  maxFiles = "Максимальное количество изображений ",
  differentTypeFile = "Допустимые типы изображений: .jpeg, .jpg, .gif, .png",
  sameFiles = "Вы уже загружали это изображение",
  noAutorization = "Авторизуйтесь, чтобы подобрать автомобиль",
  successSendReport = "Сообщение отправлено! Спасибо!",
  somethingWrong = "Что-то пошло не так",
  successChangePassword = "Ваш пароль изменен!",
  successSendEmail = "Мы отправили Вам Email для создания нового пароля!",
  successAddUsersCars = "Автомобиль добавлен",
}
