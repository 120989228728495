import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import {
  setFormOpen,
  setStatusForm,
} from "../../../../redux/auth/auth-actions";
import { StateType } from "../../../../redux/store";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: 302,
    },
    title: {
      margin: "0 0 20px",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "36px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
      textAlign: "center",
    },
    subTitle: {
      margin: 0,
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
  })
);

interface IHeaderSignInSuccessForm {
  title?: string;
  subtitle?: string;
  isActivation?: boolean;
}

const HeaderSignInSuccessForm: FC<IHeaderSignInSuccessForm> = ({
  title,
  subtitle,
  isActivation,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state: StateType) => state.auth);

  const handleContinueClick = () => {
    if (isActivation && isAuth) {
      dispatch(setFormOpen(false));
    }
    dispatch(setStatusForm("login"));
  };

  return (
    <div className={classes.root}>
      <h3 className={classes.title}>{title}</h3>
      <p className={classes.subTitle}>{subtitle}</p>
      <Button onClick={handleContinueClick} className={classes.button}>
        ПРОДОЛЖИТЬ
      </Button>
    </div>
  );
};
export default HeaderSignInSuccessForm;
