import React, { useState } from "react";
import { useDrag } from "@use-gesture/react";

import { Collapse, IconButton, Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./AppBannerStyles";

import { LinksEnum } from "../../routes";
import { useDispatch } from "react-redux";
import { setAppBannerOpen } from "../../redux/auth/auth-actions";

import Image1 from "../../assets/images/mobile-app/image1.png";
import Image2 from "../../assets/images/mobile-app/image2.png";
import Image3 from "../../assets/images/mobile-app/image3.png";

const MOVE_DIST = 30;

const Images = [Image1, Image2, Image3];

const AppBanner = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const bindBanner = useDrag(({ movement: [, my] }) => {
    if (my < -MOVE_DIST) {
      setIsOpen(true);
    }
    if (my > MOVE_DIST) {
      setIsOpen(false);
    }
  });

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const handleHide = () => dispatch(setAppBannerOpen(false));

  return (
    <div>
      {isOpen && <div className={classes.blackout} onClick={handleClose} />}
      <Paper className={classes.paper} onClick={handleOpen} {...bindBanner()}>
        <div className={classes.head}>
          <div>
            <div className={classes.logo} />
            <p className={classes.name}>car4u.app</p>
          </div>

          <div className={classes.info}>
            <a
              className={classes.button}
              href={LinksEnum.GOOGLE_PLAY}
              target="_blank"
              rel="noopener noreferrer"
            >
              УСТАНОВИТЬ
            </a>
            <IconButton onClick={handleHide}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Collapse in={isOpen}>
          <ul className={classes.images}>
            {Images.map((image) => (
              <li key={image}>
                <img src={image} alt="mobile app" className={classes.item} />
              </li>
            ))}
          </ul>
        </Collapse>
      </Paper>
    </div>
  );
};

export default AppBanner;
