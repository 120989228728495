import React, { FC, lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { YMInitializer } from "react-yandex-metrika";
// @ts-ignore
import { AndroidView, MobileOnlyView } from "react-device-detect";
import { toast } from "react-toastify";
import "@fontsource/montserrat";

import "react-toastify/dist/ReactToastify.min.css";
import "./app.css";

import { instance } from "./api/baseURL";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import CarLoading from "./components/Loading/carLoading";
import { initGA } from "./index";
import MainPage from "./pages/Main/Main";
// import SurveyPageMain from "./pages/survey/main/SurveyPageMain";
import { StateType } from "./redux/store";
import { AppRoutesEnum, suspenseRoutes } from "./routes";
import VerifyPage from "./pages/verify/Verify";
import { setCarsGenerationData } from "./redux/carsGenerationData-reducer";
// import { setSberbankBanner } from "./redux/auth/auth-actions";
import { EnumMainFilter } from "./pages/Main/MainFilter/enumMainFilter";
import AppBanner from "./components/AppBanner/AppBanner";

const CarPage = lazy(() => import("./pages/Car/Car"));
const CarsComparisonPage = lazy(
  () => import("./pages/Comparison/CarsComparisonPage")
);
const Profile = lazy(() => import("./pages/Profile/Profile"));

// const SurveyPageFirst = lazy(
//   () => import("./pages/survey/first/SurveyPageFirst")
// );
// const SurveyPageSecond = lazy(
//   () => import("./pages/survey/second/SurveyPageSecond")
// );
// const SurveyPageThird = lazy(
//   () => import("./pages/survey/third/SurveyPageThird")
// );
// const SurveyPageFourth = lazy(
//   () => import("./pages/survey/fourth/SurveyPageFourth")
// );

toast.configure();
const App: FC = () => {
  const { pathname } = useLocation();
  const [isWhiteBlackgroung, setIsWhiteBlackgroung] = useState(false);
  const dispatch = useDispatch();
  const { isAuth, access_token /* , banner */, isAppBannerOpen } = useSelector(
    (state: StateType) => state.auth
  );
  const { loadingVisible } = useSelector(
    (state: StateType) => state.carsGenerationData
  );
  const dataForRequest = useSelector(
    (state: StateType) => state.carsDataForRequest
  );
  const { cars, recommended_cars } = useSelector(
    (state: StateType) => state.carsGenerationData.carsGeneration
  );

  useEffect(() => {
    initGA();
  }, []);

  if (isAuth) {
    instance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${access_token}`; //AUTH_TOKEN;
  }

  // рандомное определение баннера
  /* useEffect(() => {
    if (!banner) {
      dispatch(
        // banner1 || banner2
        setSberbankBanner(`banner${Math.floor(Math.random() * 2) + 1}`)
      );
    }
  }, [banner, dispatch]); */

  useEffect(() => {
    // Удалять Authorization из заголовков запросов при выходе
    if (!isAuth) {
      delete instance.defaults.headers.common["Authorization"];
    }
  }, [isAuth]);

  // для отображения чего-то при переходе на main
  useEffect(() => {
    if (!cars && !recommended_cars) {
      dispatch(
        setCarsGenerationData(
          dataForRequest.carCross,
          dataForRequest.carAppearance,
          dataForRequest.carDynamism,
          dataForRequest.carComfort,
          dataForRequest.carEconomy,
          dataForRequest.carSafety,
          dataForRequest.carPrice.minValue,
          dataForRequest.carPrice.maxValue,
          dataForRequest.carsBrands,
          dataForRequest.carModel,
          dataForRequest.carClass,
          dataForRequest.carGearbox,
          dataForRequest.carBody,
          dataForRequest.carDriver,
          dataForRequest.carTrunk.minValue,
          dataForRequest.carTrunk.maxValue,
          dataForRequest.carEnginePower.minValue,
          dataForRequest.carEnginePower.maxValue,
          dataForRequest.carDisplacement.minValue,
          dataForRequest.carDisplacement.maxValue,
          dataForRequest.carEngineType,
          dataForRequest.carAcceleration.minValue,
          dataForRequest.carAcceleration.maxValue,
          dataForRequest.carYear.minValue,
          dataForRequest.carYear.maxValue,
          dataForRequest.carConsumption.minValue,
          dataForRequest.carConsumption.maxValue,
          dataForRequest.carReason,
          dataForRequest.carSort,
          EnumMainFilter.carLimitFirstTime // лимит для первого входа на сайт
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const path = pathname.toLowerCase();
    setIsWhiteBlackgroung(
      // path.startsWith("/survey") ||
      path.startsWith("/verify_")
    );
  }, [pathname]);

  return (
    <div className={isWhiteBlackgroung ? "survey_app" : "app"}>
      <YMInitializer accounts={[86465690]} options={{ webvisor: true }} />
      <Header />
      <div className={isWhiteBlackgroung ? "survey" : "app__mainInfo"}>
        <Switch>
          <Route exact path={AppRoutesEnum.MAIN} component={MainPage} />
          {/* {!isAuth && (
            <Route
              exact
              path={AppRoutesEnum.SURVEY_MAIN}
              component={SurveyPageMain}
            />
          )} */}
          <Route exact path={suspenseRoutes}>
            <Suspense fallback={<CarLoading />}>
              <Switch>
                {/* <Route
                  exact
                  path={AppRoutesEnum.SURVEY_FIRST}
                  component={SurveyPageFirst}
                />
                <Route
                  exact
                  path={AppRoutesEnum.SURVEY_SECOND}
                  component={SurveyPageSecond}
                />
                <Route
                  exact
                  path={AppRoutesEnum.SURVEY_THIRD}
                  component={SurveyPageThird}
                />
                <Route
                  exact
                  path={AppRoutesEnum.SURVEY_FOURTH}
                  component={SurveyPageFourth}
                /> */}
                <Route exact path={AppRoutesEnum.CAR} component={CarPage} />
                <Route
                  exact
                  path={AppRoutesEnum.COMPARISON}
                  component={CarsComparisonPage}
                />
                <Route exact path={AppRoutesEnum.PROFILE} component={Profile} />
                <Redirect to={AppRoutesEnum.MAIN} />
              </Switch>
            </Suspense>
          </Route>
          <Route
            exact
            path={AppRoutesEnum.USER_ACTIVATE}
            component={MainPage}
          />
          <Route
            exact
            path={AppRoutesEnum.USER_PASSWORD}
            component={MainPage}
          />
          <Route
            exact
            path={[AppRoutesEnum.VERIFY_GOOGLE, AppRoutesEnum.VERIFY_VK]}
            component={VerifyPage}
          />
          {/* {isAuth ? ( */}
          <Redirect to={AppRoutesEnum.MAIN} />
          {/* ) : ( */}
          {/* <Redirect to={AppRoutesEnum.SURVEY_MAIN} /> */}
          {/* )} */}
        </Switch>
        {loadingVisible && <CarLoading />}
      </div>
      <Footer />
      {isAppBannerOpen && (
        <MobileOnlyView>
          <AndroidView>
            <AppBanner />
          </AndroidView>
        </MobileOnlyView>
      )}
    </div>
  );
};

export default App;
