import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { setIsPrivacyPolicyOpen } from "../../redux/privacyPolicy-reducer";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      textTransform: "inherit",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

export interface PrivacyPolicyButtonProps {
  text: string;
  className: string
}

const PrivacyPolicyButton: FC<PrivacyPolicyButtonProps> = ({ className, text }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenForm = () => {
    dispatch(setIsPrivacyPolicyOpen(true));
  };

  return (
    <Button onClick={handleOpenForm} className={classes.button + " " + className}>
      {text}
    </Button>
  );
};

export default PrivacyPolicyButton;
