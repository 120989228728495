import { ICarAspect } from "../../api/carAspect/interfaceCarAspect";
import {
  AllTypes,
  CarAspectActionsEnum,
  InitialStateType,
} from "./carAspect-types";

export const initialState = {
  aspects: [] as ICarAspect[],
  isLoading: false,
  addReviewOpened: false,
  addReviewAspectId: undefined as undefined | number,
};

const carAspectReducer = (
  state = initialState,
  action: AllTypes
): InitialStateType => {
  switch (action.type) {
    case CarAspectActionsEnum.CAR_ASPECTS:
      return { ...state, aspects: action.data };

    case CarAspectActionsEnum.CAR_ASPECTS_LOADING:
      return { ...state, isLoading: action.data };

    case CarAspectActionsEnum.ADD_REWIER_OPEN:
      return { ...state, addReviewOpened: action.data };

    case CarAspectActionsEnum.ADD_REWIER_ASPECT_ID:
      return { ...state, addReviewAspectId: action.data };
      
    default:
      return state;
  }
};

export default carAspectReducer;
