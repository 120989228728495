import { ThunkAction } from "redux-thunk";
import { StateType } from "../store";
import { ISurveyOpendPages } from "./interfaceSurvey";

const SURVEY_OPEND_PAGE = "SURVEY_PAGE";
const SURVEY_DONE = "SURVEY_DONE";

let initialState = {
  survey_page: "surveyMain" as ISurveyOpendPages,
  survey_done: false,
};

export type InitialStateType = typeof initialState;
type AllTyps = SurveyPage | SurveyDone;

const surveyReducer = (
  state = initialState,
  action: AllTyps
): InitialStateType => {
  switch (action.type) {
    case SURVEY_OPEND_PAGE:
      return { ...state, survey_page: action.data };
    case SURVEY_DONE:
      return { ...state, survey_done: action.data };

    default:
      return state;
  }
};

type SurveyPage = {
  type: typeof SURVEY_OPEND_PAGE;
  data: ISurveyOpendPages;
};
type SurveyDone = {
  type: typeof SURVEY_DONE;
  data: boolean;
};

export const actionsSurvey = {
  opendPage: (data: ISurveyOpendPages): SurveyPage => ({
    type: SURVEY_OPEND_PAGE,
    data,
  }),
  done: (data: boolean): SurveyDone => ({
    type: SURVEY_DONE,
    data,
  }),
};

// type ThunkTypeAsync = ThunkAction<Promise<void>, StateType, unknown, AllTyps>;
type ThunkType = ThunkAction<void, StateType, unknown, AllTyps>;

export const setsurveyPsges = (pages: ISurveyOpendPages): ThunkType => {
  return (dispatch) => {
    dispatch(actionsSurvey.opendPage(pages));
  };
};
export const setSurveyDone = (data: boolean): ThunkType => {
  return (dispatch) => {
    dispatch(actionsSurvey.done(data));
  };
};

export default surveyReducer;
