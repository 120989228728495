import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: "0 auto",
    },
    pagination: {
      color: "#fff",
      "& .MuiPaginationItem-root": {
        color: "#fff",
        [theme.breakpoints.down("sm")]: {
          fontSize: 10,
          padding: 0,
          margin: 0,
        },
      },
      "& .Mui-selected": {
        backgroundColor: "#FFBE3F",
        color: "#000",
        "&:hover": {
          backgroundColor: "#FFBE3F",
        },
      },
    },
  })
);

interface IMainPageGenerationCarsPagination {
  count: number;
  set(page: number): void;
  page: number;
}

const MainPageGenerationCarsPagination: FC<IMainPageGenerationCarsPagination> = (
  props
) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.set(value as number);
  };
  return (
    <div className={classes.root}>
      <Pagination
        className={classes.pagination}
        page={props.page}
        onChange={handleChange}
        count={props.count}
        showFirstButton={window.innerWidth > 760}
        showLastButton={window.innerWidth > 760}
      />
    </div>
  );
};

export default MainPageGenerationCarsPagination;
