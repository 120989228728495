import { createTheme } from "@mui/material/styles";
import { amber } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: amber,
    secondary: {
      main: "#9D8E6F",
      contrastText: "#000",
    },
    text: {
      secondary: "#fff",
    },
  },
});

export default theme;
