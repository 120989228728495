import { ICarsGeneration } from "../../api/carsGeneration/interfaceCarsGeneration";
import {
  ICarsColor,
  ICarsSpec,
  IOwnershipStatus,
  IUsersCar,
  IUsersCarsPhoto,
} from "../../api/usersCars/interfaceUsersCars";
import {
  AllTypes,
  InitialStateType,
  UsersCarFormType,
  UsersCarFormValuesType,
  UsersCarsActionsEnum,
} from "./usersCars-types";

export const initialState = {
  carsFilters: {
    colors: [] as ICarsColor[],
    generations: {} as ICarsGeneration,
    ownershipStatuses: [] as IOwnershipStatus[],
    specs: [] as ICarsSpec[],
  },
  error: null as string | null,
  isLoading: false,
  isUsersCarFormOpen: false,
  selectedCar: {} as IUsersCar,
  usersCarFormInitial: {
    brand: "",
    color: "",
    description: "",
    generation: "",
    model: "",
    modification: "",
    ownershipStatus: "",
    shortDescription: "",
    year: "",
  } as UsersCarFormValuesType,
  usersCarFormType: UsersCarFormType.ADD,
  usersCars: [] as IUsersCar[],
  usersCarsPhotos: {
    add: [] as File[],
    remove: [] as IUsersCarsPhoto[],
    saved: [] as IUsersCarsPhoto[],
  },
};

export const usersCarsReducer = (
  state = initialState,
  action: AllTypes
): InitialStateType => {
  switch (action.type) {
    case UsersCarsActionsEnum.ADD_USERS_CAR:
      return { ...state, usersCars: state.usersCars.concat(action.data) };
    case UsersCarsActionsEnum.DELETE_USERS_CAR:
      return {
        ...state,
        usersCars: state.usersCars.filter((car) => car.id !== action.data),
      };
    case UsersCarsActionsEnum.EDIT_USERS_CAR:
      return {
        ...state,
        usersCars: state.usersCars.map((c) =>
          c.id === action.data.id ? action.data : c
        ),
      };
    case UsersCarsActionsEnum.REMOVE_USERS_CARS_PHOTO:
      return {
        ...state,
        usersCarsPhotos: {
          ...state.usersCarsPhotos,
          saved: state.usersCarsPhotos.saved.filter(
            (p) => p.id !== action.data.id
          ),
          remove: state.usersCarsPhotos.remove.concat(action.data),
        },
      };
    case UsersCarsActionsEnum.SELECT_USERS_CAR:
      return { ...state, selectedCar: action.data };
    case UsersCarsActionsEnum.SET_CARS_COLORS:
      return {
        ...state,
        carsFilters: { ...state.carsFilters, colors: action.data },
      };
    case UsersCarsActionsEnum.SET_CARS_GENERATION:
      return {
        ...state,
        carsFilters: { ...state.carsFilters, generations: action.data },
      };
    case UsersCarsActionsEnum.SET_CARS_OWNERSHIP_STATUSES:
      return {
        ...state,
        carsFilters: { ...state.carsFilters, ownershipStatuses: action.data },
      };
    case UsersCarsActionsEnum.SET_CARS_SPECIFICATION:
      return {
        ...state,
        carsFilters: { ...state.carsFilters, specs: action.data },
      };
    case UsersCarsActionsEnum.SET_ERROR:
      return { ...state, error: action.data };
    case UsersCarsActionsEnum.SET_LOADING:
      return { ...state, isLoading: action.data };
    case UsersCarsActionsEnum.SET_USERS_CARS_PHOTOS:
      return { ...state, usersCarsPhotos: action.data };
    case UsersCarsActionsEnum.SET_USERS_CARS_PHOTOS_ADD:
      return {
        ...state,
        usersCarsPhotos: { ...state.usersCarsPhotos, add: action.data },
      };
    case UsersCarsActionsEnum.USERS_CARS:
      return { ...state, usersCars: action.data };
    case UsersCarsActionsEnum.USERS_CAR_FORM_INITIAL:
      return { ...state, usersCarFormInitial: action.data };
    case UsersCarsActionsEnum.USERS_CAR_FORM_OPEN:
      return { ...state, isUsersCarFormOpen: action.data };
    case UsersCarsActionsEnum.USERS_CAR_FORM_TYPE:
      return { ...state, usersCarFormType: action.data };
    default:
      return state;
  }
};

export default usersCarsReducer;
