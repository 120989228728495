import React, { FC, memo, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Slider, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import BaseTextField from "../Inputs/BaseTextField";
import NumberFormatPrice from "../Inputs/NumberFormatPrice";
import NumberFormatYear from "../Inputs/NumberFormatYear";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: 120,
    maxWidth: 420,
  },
});
interface TooltipProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

export type currentValueType = {
  minValue: number;
  maxValue: number;
};

interface DoubleSliderWithInputsProps {
  min: number;
  max: number;
  set(data: currentValueType): void;
  value: currentValueType;
  step: number;
  type?: string;
  title: string;
}

const ValueLabelComponent = (props: TooltipProps) => {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
};

const DoubleSliderWithInputs: FC<DoubleSliderWithInputsProps> = ({
  set,
  value,
  min,
  max,
  step,
  type,
  title,
}) => {
  const classes = useStyles();

  const [values, setValues] = useState(value);

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const arrValue = newValue as number[];
    const [minValue, maxValue] = arrValue;
    setValues({ minValue: minValue, maxValue: maxValue });
  };
  const handleSliderBlur = (event: any, newValue: number | number[]) => {
    const arrValue = newValue as number[];
    const [minValue, maxValue] = arrValue;
    set({ minValue: minValue, maxValue: maxValue });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: +event.target.value,
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value: number = +event.target.value.replace(/[\s₽г]/g, "");

    if (name === "minValue") {
      if (values.maxValue < value) {
        value = values.maxValue - step;
      } else if (min > value) {
        value = min;
      }
    }

    if (name === "maxValue") {
      if (values.minValue > value) {
        value = values.minValue + step;
      } else if (max < value) {
        value = max;
      }
    }
    set({
      ...values,
      [name]: value,
    });
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    setValues(value);
  }, [value]);

  const isFormatPrice = type === "price";

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" color="primary">
        {title}
      </Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={4}>
          <BaseTextField
            value={values.minValue}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            name="minValue"
            inputProps={{
              step: step,
              min: min,
              max: max,
              "aria-labelledby": "input-slider-min",
            }}
            InputProps={{
              inputComponent: isFormatPrice
                ? (NumberFormatPrice as any)
                : (NumberFormatYear as any),
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={4}>
          <Slider
            value={[values.minValue, values.maxValue]}
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderBlur}
            aria-labelledby="input-slider"
            min={min}
            max={max}
            step={step}
            ValueLabelComponent={ValueLabelComponent}
          />
        </Grid>
        <Grid item xs={4}>
          <BaseTextField
            value={values.maxValue}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            name="maxValue"
            inputProps={{
              step: step,
              min: min,
              max: max,
              "aria-labelledby": "input-slider-max",
            }}
            InputProps={{
              inputComponent: isFormatPrice
                ? (NumberFormatPrice as any)
                : (NumberFormatYear as any),
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(DoubleSliderWithInputs);
