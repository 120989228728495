import React, { FC, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";

import { Button, Grow } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { users } from "../../../api/users/users";
import { GAModalView } from "../../../index";
import {
  errorVisibleMessage,
  setFormOpen,
} from "../../../redux/auth/auth-actions";
import { StateType } from "../../../redux/store";
import { enumToasts } from "../../../utils/enumToasts";
import { validate } from "../../../utils/validate";
import InputBase from "../../Inputs/InpuBase";
import ButtonLoading from "../../Loading/buttonLoading";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      maxHeight: 582,
      width: 302,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    inp: {
      margin: "14px 0 0 5px",
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    title: {
      margin: "0 0 20px",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
      textAlign: "center",
    },
    subtitle: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
  })
);

interface IEmailForRestorePasswordForm {
  authForm: StateType;
}

const EmailForRestorePasswordForm: FC<
  InjectedFormProps<
    EmailForRestorePasswordFormType,
    IEmailForRestorePasswordForm
  > &
    IEmailForRestorePasswordForm
> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { handleSubmit, pristine, submitting, invalid } = props;

  useEffect(() => {
    GAModalView("emailForRestorePasswordForm");
  }, []);

  const handleSubmitEmailRestorePassword = async (
    values: EmailForRestorePasswordFormType
  ) => {
    try {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
        await users.sendRestorePasswordEmail(values.login);
      } else {
        await users.sendRestorePasswordLogin(values.login);
      }
      dispatch(setFormOpen(false));
      toast.success(enumToasts.successSendEmail);
    } catch (error) {
      if (error.response) {
        if (error.response.data.msg.includes("User is not found")) {
          throw new SubmissionError({
            login: "Такого пользователя не существует",
          });
        } else {
          toast.error(error.response.data.msg);
        }
      } else {
        toast.error(enumToasts.somethingWrong);
      }
    }
  };

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleSubmitEmailRestorePassword)}
      >
        <h3 className={classes.title}>Восстановление пароля</h3>
        <p className={classes.subtitle}>
          Введите Ваш электронный адрес или логин, используемый для входа в
          CAR4U, и Вам будет отправлено электронное письмо с ссылкой для
          создания нового пароля.
        </p>
        <div className={classes.inp}>
          <Field name="login" component={InputBase} label="Логин / Email" />
        </div>
        <Button
          type="submit"
          name="button"
          disabled={
            props.authForm.auth.progressVisible ||
            invalid ||
            pristine ||
            submitting
          }
          className={classes.button}
        >
          {props.authForm.auth.progressVisible ? (
            <ButtonLoading />
          ) : (
            "ОТПРАВИТЬ"
          )}
        </Button>
      </form>
    </Grow>
  );
};
export type EmailForRestorePasswordFormType = {
  login: string;
};

const EmailForRestorePasswordFormRedux = reduxForm<
  EmailForRestorePasswordFormType,
  IEmailForRestorePasswordForm
>({
  form: "emailForRestorePasswordForm",
  validate,
})(EmailForRestorePasswordForm);

export default connect((state: StateType) => ({ authForm: state }), {
  errorVisibleMessage,
})(EmailForRestorePasswordFormRedux);
