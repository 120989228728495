import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";

const BaseTextField = withStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        color: "#fff",
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.light,
      },
    },
  },
}))(TextField);

export default BaseTextField;
