// в этом reducer получаем данные с сервера для того чтобы заполнить списки в УМНОМ ФИЛЬТРЕ
import { carsInfo } from "../api/carsInfo/getCarsInfo";
import {
  ICarBrand,
  ICarModels,
  ICarClasses,
  ICarGearboxes,
  ICarDrivers,
  ICarBodies,
  ICarMinMaxPriceAndYear,
  ICarReasons,
  ICarEngineDisplacements,
  ICarEngineTypes,
} from "../api/carsInfo/interfaceCars";

import { StateType } from "./store";
import { ThunkAction } from "redux-thunk";
import { EnumMainFilter } from "../pages/Main/MainFilter/enumMainFilter";
import { getFullYear } from "../utils/getFullYear";

const GET_CARS_BRANDS = "GET_CARS_BRANDS";
const GET_CARS_MODELS = "GET_CARS_MODELS";
const GET_CARS_MODELS_DISABLED = "GET_CARS_MODELS_DISABLED";
const GET_CARS_CLASSES = "GET_CARS_CLASSES";
const GET_CARS_GEARBOXES = "GET_CARS_GEARBOXES";
const GET_CARS_DRIVERS = "GET_CARS_DRIVERS";
const GET_CARS_BODIES = "GET_CARS_BODIES";
const GET_CARS_MIN_MAX_PRICE_YEAR = "GET_CARS_MIN_MAX_PRICE_YEAR";
const GET_CARS_REASONS = "GET_CARS_REASON";
const GET_CARS_ENGINE_DISPLACEMENT = "GET_CARS_ENGINE_DISPLACEMENT";
const GET_CARS_ENGINE_TYPES = "GET_CARS_ENGINE_TYPES";

let initialState = {
  carsBrands: {} as ICarBrand,
  carModels: {} as ICarModels,
  isModelsDisabled: true,
  carClasses: {} as ICarClasses,
  carGearboxes: {} as ICarGearboxes,
  carDrivers: {} as ICarDrivers,
  carBodies: {} as ICarBodies,
  carMinMaxPriceAndYear: {
    minMax: {
      manufactureYearMax: getFullYear(),
      manufactureYearMin: EnumMainFilter.carYearMin,
      priceMax: EnumMainFilter.carPriceMax,
      priceMin: EnumMainFilter.carPriceMin,
    },
  } as ICarMinMaxPriceAndYear,
  carReasons: [] as ICarReasons[],
  carEngineDisplacement: [] as ICarEngineDisplacements[],
  carEngineTypes: {} as ICarEngineTypes,
};

export type InitialStateCarsForFilterType = typeof initialState;
type allTypes =
  | setCarsBrands
  | setCarModels
  | setCarModelsDisabled
  | setCarClasses
  | setCarGearboxes
  | setCarDrivers
  | setCarBodies
  | setCarMinMaxPriceAndYear
  | setCarReasons
  | setCarEngineDisplacement
  | setCarEngineTypes;

const carsDataForFilterReducer = (
  state = initialState,
  action: allTypes
): InitialStateCarsForFilterType => {
  switch (action.type) {
    case GET_CARS_BRANDS:
      return { ...state, carsBrands: action.data };
    case GET_CARS_MODELS:
      return { ...state, carModels: action.data };
    case GET_CARS_MODELS_DISABLED:
      return { ...state, isModelsDisabled: action.data };
    case GET_CARS_CLASSES:
      return { ...state, carClasses: action.data };
    case GET_CARS_GEARBOXES:
      return { ...state, carGearboxes: action.data };
    case GET_CARS_DRIVERS:
      return { ...state, carDrivers: action.data };
    case GET_CARS_BODIES:
      return { ...state, carBodies: action.data };
    case GET_CARS_MIN_MAX_PRICE_YEAR:
      return { ...state, carMinMaxPriceAndYear: action.data };
    case GET_CARS_REASONS:
      return { ...state, carReasons: action.data };
    case GET_CARS_ENGINE_DISPLACEMENT:
      return { ...state, carEngineDisplacement: action.data };
    case GET_CARS_ENGINE_TYPES:
      return { ...state, carEngineTypes: action.data };
    default:
      return state;
  }
};

type setCarsBrands = {
  type: typeof GET_CARS_BRANDS;
  data: ICarBrand;
};
type setCarModels = {
  type: typeof GET_CARS_MODELS;
  data: ICarModels;
};
type setCarModelsDisabled = {
  type: typeof GET_CARS_MODELS_DISABLED;
  data: boolean;
};
type setCarClasses = {
  type: typeof GET_CARS_CLASSES;
  data: ICarClasses;
};
type setCarGearboxes = {
  type: typeof GET_CARS_GEARBOXES;
  data: ICarGearboxes;
};
type setCarDrivers = {
  type: typeof GET_CARS_DRIVERS;
  data: ICarDrivers;
};
type setCarBodies = {
  type: typeof GET_CARS_BODIES;
  data: ICarBodies;
};
type setCarMinMaxPriceAndYear = {
  type: typeof GET_CARS_MIN_MAX_PRICE_YEAR;
  data: ICarMinMaxPriceAndYear;
};
type setCarReasons = {
  type: typeof GET_CARS_REASONS;
  data: ICarReasons[];
};
type setCarEngineDisplacement = {
  type: typeof GET_CARS_ENGINE_DISPLACEMENT;
  data: ICarEngineDisplacements[];
};
type setCarEngineTypes = {
  type: typeof GET_CARS_ENGINE_TYPES;
  data: ICarEngineTypes;
};

const actionsCars = {
  setCarsBrands: (data: ICarBrand): setCarsBrands => ({
    type: GET_CARS_BRANDS,
    data: data,
  }),
  setCarModels: (data: ICarModels): setCarModels => ({
    type: GET_CARS_MODELS,
    data: data,
  }),
  setCarsModelsDisabled: (data: boolean): setCarModelsDisabled => ({
    type: GET_CARS_MODELS_DISABLED,
    data: data,
  }),
  setCarClasses: (data: ICarClasses): setCarClasses => ({
    type: GET_CARS_CLASSES,
    data: data,
  }),
  setCarGearboxes: (data: ICarGearboxes): setCarGearboxes => ({
    type: GET_CARS_GEARBOXES,
    data: data,
  }),
  setCarDrivers: (data: ICarDrivers): setCarDrivers => ({
    type: GET_CARS_DRIVERS,
    data: data,
  }),
  setCarBodies: (data: ICarBodies): setCarBodies => ({
    type: GET_CARS_BODIES,
    data: data,
  }),
  setCarMinMaxPriceAndYear: (
    data: ICarMinMaxPriceAndYear
  ): setCarMinMaxPriceAndYear => ({
    type: GET_CARS_MIN_MAX_PRICE_YEAR,
    data: data,
  }),
  setCarReasons: (data: ICarReasons[]): setCarReasons => ({
    type: GET_CARS_REASONS,
    data: data,
  }),
  setCarEngineDisplacement: (
    data: ICarEngineDisplacements[]
  ): setCarEngineDisplacement => ({
    type: GET_CARS_ENGINE_DISPLACEMENT,
    data: data,
  }),
  setCarEngineTypes: (data: ICarEngineTypes): setCarEngineTypes => ({
    type: GET_CARS_ENGINE_TYPES,
    data: data,
  }),
};

// для async
type ThunkTypeAsync = ThunkAction<Promise<void>, StateType, unknown, allTypes>;
// для sync
type ThunkType = ThunkAction<void, StateType, unknown, allTypes>;

export const setCarsBrands = (): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let brands = await carsInfo.getCarsBrands();
      dispatch(actionsCars.setCarsBrands(brands));
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const setCarsModels = (id: number): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let models = await carsInfo.getCarModels(id);
      dispatch(actionsCars.setCarModels(models));
    } catch (err) {
      return Promise.reject(err);
    }
  };
};

export const setCarsBrandsAndModelsForExtraFilter = (
  selectedBrands: number[]
): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsCars.setCarModels(initialState.carModels));
    dispatch(actionsCars.setCarsModelsDisabled(true));
    try {
      let brands = await carsInfo.getCarsBrands();
      dispatch(actionsCars.setCarsBrands(brands));
      if (selectedBrands.length === 1) {
        try {
          let models = await carsInfo.getCarModels(selectedBrands[0]);
          dispatch(actionsCars.setCarModels(models));
          dispatch(actionsCars.setCarsModelsDisabled(false));
        } catch (err) {
          console.log(err);
        }
        try {
          let engineTypes = await carsInfo.getCarEngineTypes(selectedBrands);
          dispatch(actionsCars.setCarEngineTypes(engineTypes));
        } catch (err) {
          console.log(err);
        }

        try {
          let engineDisplacement = await carsInfo.getCarEngineDisplacements(
            selectedBrands
          );
          dispatch(actionsCars.setCarEngineDisplacement(engineDisplacement));
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          let engineTypes = await carsInfo.getCarEngineTypes();
          dispatch(actionsCars.setCarEngineTypes(engineTypes));
        } catch (err) {
          console.log(err);
        }

        try {
          let engineDisplacement = await carsInfo.getCarEngineDisplacements();
          dispatch(actionsCars.setCarEngineDisplacement(engineDisplacement));
        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCarsInfoForExtraFilter = (): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let classes = await carsInfo.getCarClasses();
      dispatch(actionsCars.setCarClasses(classes));
    } catch (err) {
      console.log(err);
    }

    try {
      let gearboxes = await carsInfo.getCarGearboxes();
      dispatch(actionsCars.setCarGearboxes(gearboxes));
    } catch (err) {
      console.log(err);
    }

    try {
      let drivers = await carsInfo.getCarDrivers();
      dispatch(actionsCars.setCarDrivers(drivers));
    } catch (err) {
      console.log(err);
    }

    try {
      let bodies = await carsInfo.getCarBodies();
      dispatch(actionsCars.setCarBodies(bodies));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCarMinMaxPriceAndYearForFilter = (
  selectedBrands: number[],
  model: number
): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let priceAndYear = await carsInfo.getCarMinMaxPriceAndYear(
        selectedBrands,
        model
      );
      dispatch(actionsCars.setCarMinMaxPriceAndYear(priceAndYear));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCarReasons = (): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let reasons = await carsInfo.getCarReasons();
      dispatch(actionsCars.setCarReasons(reasons));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCarEngineDisplacement = (
  brandId?: number[],
  carModelId?: number
): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let engineDisplacements = await carsInfo.getCarEngineDisplacements(
        brandId,
        carModelId
      );
      dispatch(actionsCars.setCarEngineDisplacement(engineDisplacements));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCarEngineTypes = (
  brandId?: number[],
  carModelId?: number
): ThunkTypeAsync => {
  return async (dispatch) => {
    try {
      let engineTypes = await carsInfo.getCarEngineTypes(brandId, carModelId);
      dispatch(actionsCars.setCarEngineTypes(engineTypes));
    } catch (err) {
      console.log(err);
    }
  };
};

export default carsDataForFilterReducer;
