import {
  IAddFilterParams,
  ICarById,
  ICarDTP,
  ICarPricePredictions,
  ICarPricesById,
  ICarsFromAvito,
  ICarsOptions,
  IFeatureOption,
  IMaintenanceCost,
  IRadarData,
} from "../../api/carsGeneration/interfaceCarsGeneration";
import {
  AllTypes,
  CarActionsEnum,
  CarSurveyViewEnum,
  InitialStateType,
} from "./car-types";

export const initialState = {
  carData: {} as ICarById,
  carSpecSelected: {} as ICarsOptions, //отображаемые спецификации
  carSpecs: {} as ICarsOptions[], // доступные спецификации
  costData: {
    maintenanceCost: {} as IMaintenanceCost,
    pieValueTotal: 0,
  },
  costFilter: {
    annualMileage: 10000,
    driverUnder22: true,
    drivingExperienceUnder3: true,
  },
  dtp: [] as ICarDTP[],
  error: null as string | null,
  isLoading: false,
  isLoadingSpec: false,
  isSpecFilterOpen: false,
  pricePredictions: [] as ICarPricePredictions[],
  prices: [] as ICarPricesById[],
  radarData: [] as IRadarData[],
  recommendations: [] as string[],
  specFilter: {} as IAddFilterParams, // доступные значения фильтров
  specFilterSelected: {
    enginePower: [] as string[], // двигатель
    fuelConsumption: [] as string[], // расход топлива
    trunkVolumeMax: [] as string[], // багажник
    wheelsSize: [] as string[], // шины
  } as IAddFilterParams, // выбранные значения фильтров
  specOptions: {} as IFeatureOption,
  surveyView: CarSurveyViewEnum.ASPECTS,
  carsFromAvito: null as null | ICarsFromAvito[],
};

const carReducer = (
  state = initialState,
  action: AllTypes
): InitialStateType => {
  switch (action.type) {
    case CarActionsEnum.COST_FILTER_ANNUAL_MILEAGE:
      return {
        ...state,
        costFilter: {
          ...state.costFilter,
          annualMileage: action.data,
        },
      };
    case CarActionsEnum.COST_FILTER_DRIVER_AGE:
      return {
        ...state,
        costFilter: {
          ...state.costFilter,
          driverUnder22: action.data,
        },
      };
    case CarActionsEnum.COST_FILTER_DRIVING_EXPERIENCE:
      return {
        ...state,
        costFilter: {
          ...state.costFilter,
          drivingExperienceUnder3: action.data,
        },
      };
    case CarActionsEnum.RESET_CAR_DATA:
      return {
        ...state,
        carData: initialState.carData,
        prices: initialState.prices,
        pricePredictions: initialState.pricePredictions,
        dtp: initialState.dtp,
        recommendations: initialState.recommendations,
      };
    case CarActionsEnum.SELECT_CAR_SPEC:
      return { ...state, carSpecSelected: action.data };
    case CarActionsEnum.SET_CAR_DATA:
      return { ...state, carData: action.data };
    case CarActionsEnum.SET_CAR_SPECS:
      return { ...state, carSpecs: action.data };
    case CarActionsEnum.SET_COST_DATA:
      return { ...state, costData: action.data };
    case CarActionsEnum.SET_DTP:
      return { ...state, dtp: action.data };
    case CarActionsEnum.SET_ERROR:
      return { ...state, error: action.data };
    case CarActionsEnum.SET_LOADING:
      return { ...state, isLoading: action.data };
    case CarActionsEnum.SET_LOADING_SPEC:
      return { ...state, isLoadingSpec: action.data };
    case CarActionsEnum.SET_PRICES:
      return { ...state, prices: action.data };
    case CarActionsEnum.SET_PRICE_PREDICTIONS:
      return { ...state, pricePredictions: action.data };
    case CarActionsEnum.SET_RADAR_DATA:
      return { ...state, radarData: action.data };
    case CarActionsEnum.SET_RECOMMENDATIONS:
      return { ...state, recommendations: action.data };
    case CarActionsEnum.SET_SPEC_FILTER:
      return { ...state, specFilter: action.data };
    case CarActionsEnum.SET_SPEC_FILTER_OPEN:
      return { ...state, isSpecFilterOpen: action.data };
    case CarActionsEnum.SET_SPEC_OPTIONS:
      return { ...state, specOptions: action.data };
    case CarActionsEnum.SET_SURVEY_VIEW:
      return { ...state, surveyView: action.data };
    case CarActionsEnum.SPEC_FILTER_ENGINER_POWER:
      return {
        ...state,
        specFilterSelected: {
          ...state.specFilterSelected,
          enginePower: action.data,
        },
      };
    case CarActionsEnum.SPEC_FILTER_FUEL_CONSUNMPTION:
      return {
        ...state,
        specFilterSelected: {
          ...state.specFilterSelected,
          fuelConsumption: action.data,
        },
      };
    case CarActionsEnum.SPEC_FILTER_TRUNK_VOLUME_MAX:
      return {
        ...state,
        specFilterSelected: {
          ...state.specFilterSelected,
          trunkVolumeMax: action.data,
        },
      };
    case CarActionsEnum.SPEC_FILTER_WHEEL_SIZE:
      return {
        ...state,
        specFilterSelected: {
          ...state.specFilterSelected,
          wheelsSize: action.data,
        },
      };

    case CarActionsEnum.CARS_FROM_AVITO:
      return {
        ...state,
        carsFromAvito: action.data,
      };

    default:
      return state;
  }
};

export default carReducer;
