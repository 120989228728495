import React, { FC } from "react";
import { useLocation } from "react-router";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    link: {
      margin: "0 10px",
      padding: "0 10px",
      width: "100%",
      maxWidth: 302,
      minHeight: 56,

      fontFamily: "'Open sans'",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "120%",
      textAlign: "center",
      textDecoration: "none",

      color: theme.palette.primary.contrastText,
      borderRadius: 4,
      background: theme.palette.primary.main,

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

      transition: ".3s, easy-in",

      "&:hover": {
        background: "#A28C8E",
      },
      "&:disabled": {
        background: theme.palette.primary.main,
      },
    },
    textLink: {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    textUpper: {
      textTransform: "uppercase",
    },
  })
);

const VerifyPage: FC = () => {
  const classes = useStyles();
  const location = useLocation();

  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASE_URL
      : "http://localhost:3000/";

  const URL = `${BASE_URL?.slice(0, BASE_URL.length -1)}${location.pathname}${
    location.search
  }`;

  return (
    <div className={classes.root}>
      <Tooltip title={URL} placement="top" arrow>
        <a href={URL} className={classes.link}>
          <span className={classes.textLink}>a href = {URL}</span>
          <span className={classes.textUpper}>Продолжить вход</span>
        </a>
      </Tooltip>
    </div>
  );
};

export default VerifyPage;
