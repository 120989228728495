import React, { FC } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import IconExtraFilter from "../../assets/images/IconExtraFilter.png";
import IconExtraFilterUp from "../../assets/images/IconExtraFilterUp.png";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 30,
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "fit-content",
    },
    text: {
      margin: 0,
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      textDecorationLine: "underline",
      color: "#FFFFFF",
      cursor: "pointer",
    },
    icon: {
      marginLeft: 10,
      cursor: "pointer",
    },
  })
);

interface ExtraFilterButtonProps {
  onClick(): void;
  extraFilterOpen: boolean;
}

const ExtraFilterButton: FC<ExtraFilterButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div onClick={props.onClick} className={classes.container}>
        <p className={classes.text}>
          {props.extraFilterOpen
            ? "Скрыть"
            : "Показать дополнительные параметры"}
        </p>
        <img
          className={classes.icon}
          src={props.extraFilterOpen ? IconExtraFilterUp : IconExtraFilter}
          alt="icon"
        />
      </div>
    </div>
  );
};

export default React.memo(ExtraFilterButton);
