import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, ClickAwayListener, Collapse, Popper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import { StateType } from "../../../redux/store";
import { AppRoutesEnum } from "../../../routes";

const useStyles = makeStyles((theme) =>
  createStyles({
    button: {
      margin: "0 49px 0 19px",
      padding: 18,
      paddingTop: 25,
      paddingBottom: 23,

      borderRadius: 0,

      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: "#000000",
      background: "#FFBE3F",
      transition: "all .2s ease-in-out",
      "&:hover": {
        backgroundColor: "#A28C8E",
      },
      [theme.breakpoints.down("md")]: {
        margin: 0,
      },
    },
    mobileMenu: {
      backgroundColor: "#FFBE3F",
      margin: 0,
    },
    link: {
      textDecoration: "none",
    },
    menuItem: {
      margin: 0,
      width: 192,
      fontFamily: "Open-sans, sans-serif",
      fontWeight: "normal",
      fontStyle: "normal",
      fontSize: "14px",
      lineHeight: "24px",
      color: "#000",
      padding: "7px 16px",
      cursor: "pointer",
      "&:hover": {
        color: "#fff",
        backgroundColor: "#A28C8E",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

interface IHeaderSignInButton {
  handleOpen(): void;
  handleLogOut(): void;
}

const HeaderSignInButton: FC<IHeaderSignInButton> = ({
  handleOpen,
  handleLogOut,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuth } = useSelector((state: StateType) => state.auth);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleAnchorElClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = async () => {
    history.push(AppRoutesEnum.PROFILE);
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  useEffect(() => {
    setAnchorEl(null);
  }, [isAuth]);

  return (
    <>
      {isAuth ? (
        <div>
          <ClickAwayListener onClickAway={handleAnchorElClose}>
            <Button onClick={handleToggle} className={classes.button}>
              <AccountCircleIcon />
            </Button>
          </ClickAwayListener>
          <Popper
            id={id}
            open={Boolean(open)}
            anchorEl={anchorEl}
            placement={"bottom-end"}
            transition
            keepMounted
          >
            {({ TransitionProps }) => (
              <Collapse {...TransitionProps} timeout={350}>
                <div className={classes.mobileMenu}>
                  <p onClick={handleProfile} className={classes.menuItem}>
                    Личный кабинет
                  </p>
                  <p className={classes.menuItem} onClick={handleLogOut}>
                    Выйти
                  </p>
                </div>
              </Collapse>
            )}
          </Popper>
        </div>
      ) : (
        <Button onClick={handleOpen} className={classes.button}>
          Войти
        </Button>
      )}
    </>
  );
};

export default HeaderSignInButton;
