import { ThunkAction } from "redux-thunk";

import { StateType } from "./store";
import { feedbackAPI } from "../api/feedback/feedback";
import { IFeedback } from "../api/feedback/interfaceFeedback";

const SET_FEEDBACK_LOADING = "SET_FEEDBACK_LOADING";
const SET_FEEDBACK_ERROR = "SET_FEEDBACK_ERROR";
const SET_FEEDBACK_SUCCESS = "SET_FEEDBACK_SUCCESS";

let initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
};

type InitialStateFeedbackType = typeof initialState;
type AllTypes = SetIsLoading | SetIsError | SetIsSuccess;

const feedbackReducer = (
  state = initialState,
  action: AllTypes
): InitialStateFeedbackType => {
  switch (action.type) {
    case SET_FEEDBACK_LOADING:
      return { ...state, isLoading: action.data };
    case SET_FEEDBACK_ERROR:
      return { ...state, isError: action.data };
    case SET_FEEDBACK_SUCCESS:
      return { ...state, isSuccess: action.data };
    default:
      return state;
  }
};

type SetIsLoading = {
  type: typeof SET_FEEDBACK_LOADING;
  data: boolean;
};

type SetIsError = {
  type: typeof SET_FEEDBACK_ERROR;
  data: boolean;
};

type SetIsSuccess = {
  type: typeof SET_FEEDBACK_SUCCESS;
  data: boolean;
};

const actionsFeedback = {
  setIsLoading: (data: boolean): SetIsLoading => ({
    type: SET_FEEDBACK_LOADING,
    data,
  }),
  setIsError: (data: boolean): SetIsError => ({
    type: SET_FEEDBACK_ERROR,
    data,
  }),
  setIsSuccess: (data: boolean): SetIsSuccess => ({
    type: SET_FEEDBACK_SUCCESS,
    data,
  }),
};

type ThunkType = ThunkAction<void, StateType, unknown, AllTypes>;
type ThunkTypeAsync = ThunkAction<Promise<void>, StateType, unknown, AllTypes>;

export const postFeedback = (feedbackData: IFeedback): ThunkTypeAsync => {
  return async (dispatch) => {
    dispatch(actionsFeedback.setIsLoading(true));
    try {
      await feedbackAPI.postFeedback(feedbackData);
      dispatch(actionsFeedback.setIsError(false));
      dispatch(actionsFeedback.setIsSuccess(true));
      dispatch(actionsFeedback.setIsLoading(false));
    } catch (error) {
      dispatch(actionsFeedback.setIsError(true));
      dispatch(actionsFeedback.setIsLoading(false));
      return await Promise.reject(error);
    }
  };
};

export const resetFeedback = (): ThunkType => {
  return (dispatch) => {
    dispatch(actionsFeedback.setIsError(false));
    dispatch(actionsFeedback.setIsLoading(false));
    dispatch(actionsFeedback.setIsSuccess(false));
  };
};

export default feedbackReducer;
