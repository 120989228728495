import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import storeRedux from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import theme from "./assets/styles/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";

const { store, persistList } = storeRedux();

export const initGA = () => {
  const trackingID = "UA-179953059-1";
  ReactGA.initialize(trackingID);
};
export const GAPageView = (page: any) => {
  ReactGA.pageview(page);
};
export const GAModalView = (modal: any) => {
  ReactGA.modalview(modal);
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistList}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
