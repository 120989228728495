import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, Grow } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { GAModalView } from "../../../index";
import { errorVisibleMessage } from "../../../redux/auth/auth-actions";
import { StateType } from "../../../redux/store";
import { validate } from "../../../utils/validate";
import InputBase from "../../Inputs/InpuBase";
import ButtonLoading from "../../Loading/buttonLoading";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      maxHeight: 582,
      width: 302,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    inp: {
      margin: "14px 0 0 5px",
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    title: {
      margin: "0 0 20px",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
      textAlign: "center",
    },
    subtitle: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
  })
);

interface IRestorePasswordForm {
  authForm: StateType;
}

const RestorePasswordForm: FC<
  InjectedFormProps<RestorePasswordFormType, IRestorePasswordForm> &
    IRestorePasswordForm
> = (props) => {
  const classes = useStyles();
  const { handleSubmit, pristine, submitting, invalid } = props;
  useEffect(() => {
    GAModalView("restorePasswordForm");
  }, []);

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <h3 className={classes.title}>Изменение пароля</h3>
        <p className={classes.subtitle}>Введите свой новый пароль для CAR4U.</p>
        <div className={classes.inp}>
          <Field
            name="passwordNew"
            component={InputBase}
            label="Пароль"
            type="password"
          />
        </div>
        <div className={classes.inp}>
          <Field
            name="repeatPassword"
            component={InputBase}
            label="Повторите пароль"
            type="password"
          />
        </div>
        <Button
          type="submit"
          name="button"
          disabled={
            props.authForm.auth.progressVisible ||
            invalid ||
            pristine ||
            submitting
          }
          className={classes.button}
        >
          {props.authForm.auth.progressVisible ? (
            <ButtonLoading />
          ) : (
            "ОТПРАВИТЬ"
          )}
        </Button>
      </form>
    </Grow>
  );
};
export type RestorePasswordFormType = {
  passwordNew: string;
  repeatPassword: string;
};

const RestorePasswordFormRedux = reduxForm<
  RestorePasswordFormType,
  IRestorePasswordForm
>({
  form: "RestorePasswordForm",
  validate,
})(RestorePasswordForm);

export default connect((state: StateType) => ({ authForm: state }), {
  errorVisibleMessage,
})(RestorePasswordFormRedux);
