import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// redux-persist---
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session"; // save reducers in session storage
//------

import { reducer as formReducer } from "redux-form";
// import authReducer from "./auth-reducer";
import carsDataForFilterReducer from "./carsDataForFilter-reducer";
import carsForRequestReducer from "./carsDataForRquest-reducer";
import carsGenerationDataReducer from "./carsGenerationData-reducer";
import carsComparisonDataReducer from "./carsComparisonData-reducer";

import surveyReducer from "./survey/serveyReducer";
import privacyPolicyReducer from "./privacyPolicy-reducer";
import feedbackReducer from "./feedback-reducer";
import carReducer from "./car/car-reducer";
import usersCarsReducer from "./usersCars/usersCars-reducer";
import authReducer from "./auth/auth-reducer";
import carAspectReducer from "./carAspect/carAspect-reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "carsGenerationData",
    "carsDataForRequest",
    "carsComparisonData",
  ], //массив для редьюсеров, которые не должны сбрасываться при обновлении
};

let reducers = combineReducers({
  auth: authReducer,
  car: carReducer,
  carAspect: carAspectReducer,
  carsDataForFilter: carsDataForFilterReducer,
  carsDataForRequest: carsForRequestReducer,
  carsGenerationData: carsGenerationDataReducer,
  carsComparisonData: carsComparisonDataReducer,
  servey: surveyReducer,
  privatePolicy: privacyPolicyReducer,
  feedback: feedbackReducer,
  usersCars: usersCarsReducer,
  form: formReducer,
});

type RootReducerType = typeof reducers;
export type StateType = ReturnType<RootReducerType>;

const persistedReducer = persistReducer(persistConfig, reducers); // redux-persist--- сохраняет состояние при обновлении

export default () => {
  let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );

  let persistList = persistStore(store);
  return { store, persistList };
};
