import React, { FC } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import style from "./carLoading.module.css";
// import useLockBodyScroll from "../../../utils/useLockBodyScroll";

const useStyles = makeStyles(() =>
  createStyles({
    overlay: {
      width: "100%",

      background: "rgba(0, 0, 0, 0.8)",

      top: 0,
      left: 0,
      zIndex: 2000,
    },
    absolute: {
      height: "100%",

      position: "absolute",
    },
    fixed: {
      height: "100vh",

      position: "fixed",
    },
  })
);

const CarLoading: FC<{ relative?: boolean }> = ({ relative = false }) => {
  const classes = useStyles();
  // useLockBodyScroll();
  return (
    <div
      className={`${classes.overlay} ${
        relative ? classes.absolute : classes.fixed
      }`}
    >
      <div className={style.loadingWindow}>
        <div className={style.car}>
          <div className={style.strike} />
          <div className={style.strike} />
          <div className={style.strike} />
          <div className={style.strike} />
          <div className={style.strike} />
          <div className={`${style.detail} ${style.spoiler}`} />
          <div className={`${style.detail} ${style.back}`} />
          <div className={`${style.detail} ${style.center}`} />
          <div className={`${style.detail} ${style.center1}`} />
          <div className={`${style.detail} ${style.front}`} />
          <div className={`${style.detail} ${style.wheel}`} />
          <div className={`${style.detail} ${style.wheel} ${style.wheel_2}`} />
        </div>
        <div className={style.text}>
          <span>Загрузка</span>
          <span className={style.dots}>...</span>
        </div>
      </div>
    </div>
  );
};

export default CarLoading;
