export enum AppRoutesEnum {
  CAR = "/car",
  COMPARISON = "/car-comparison",
  MAIN = "/main",
  PROFILE = "/profile",
  SURVEY_MAIN = "/survey",
  SURVEY_FIRST = "/survey/first",
  SURVEY_SECOND = "/survey/second",
  SURVEY_THIRD = "/survey/third",
  SURVEY_FOURTH = "/survey/fourth",
  USER_ACTIVATE = "/activate/:uuid",
  USER_PASSWORD = "/password/:uuid",
  VERIFY_GOOGLE = "/verify_google",
  VERIFY_VK = "/verify_vk",
}

export const suspenseRoutes = [
  AppRoutesEnum.CAR,
  AppRoutesEnum.COMPARISON,
  AppRoutesEnum.PROFILE,
  AppRoutesEnum.SURVEY_FIRST,
  AppRoutesEnum.SURVEY_SECOND,
  AppRoutesEnum.SURVEY_THIRD,
  AppRoutesEnum.SURVEY_FOURTH,
];

export enum LinksEnum {
  GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=app.car4u",
  SBERBANK = "https://www.sberbank.ru/sms/carloanrequest/?isNewCar=true&source=marketingjjjcar4u",
  INSTAGRAM = "https://www.instagram.com/car4uapp/",
}

export enum CAR_MARKET_LINKS {
  AVITO = "https://www.avito.ru",
  DROM = "https://www.drom.ru/",
  AUTO = "https://auto.ru/",
}
