import { instance } from "../baseURL";
import { ICarsComparison } from "./interfaceCarsComparison";
import { EnumEndPoints } from "../enumEndPoints";

export const carsComparison = {
  getCarsComparison(carId: number[]) {
    return instance
      .get<ICarsComparison>(EnumEndPoints.cars, {
        params: {
          car_ids: carId,
        },
      })
      .then((res) => res.data)
      .catch((error: Error) => Promise.reject(error));
  },
};
