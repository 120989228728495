import { IAuth } from "./interfaceAuth";
import { instance } from "../baseURL";
import { EnumEndPoints } from "../enumEndPoints";

export const authAPI = {
  loginUserName(username: string, password: string) {
    return instance
      .post<IAuth>(EnumEndPoints.auth, { username, password })
      .then((res) => res.data)
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  },
  loginEmail(email: string, password: string) {
    return instance
      .post<IAuth>(EnumEndPoints.auth, { email, password })
      .then((res) => res.data)
      .catch((error: Error) => {
        return Promise.reject(error);
      });
  },

  //TODO: захардкожен driving_experience
  async registration(email: string, password: string, username?: string) {
    try {
      const res = await instance.post<string>(EnumEndPoints.userRegistration, {
        email,
        password,
        username,
        // drivingExperienceId: 1,
      });
      return res.data;
    } catch (error) {
      return await Promise.reject(error);
    }
  },
};
