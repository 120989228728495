import React, { FC, memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { AppRoutesEnum } from "../../routes";
import { StateType } from "../../redux/store";
import { setsurveyPsges } from "../../redux/survey/serveyReducer";
import { setCarsGenerationData } from "../../redux/carsGenerationData-reducer";
import { EnumMainFilter } from "../../pages/Main/MainFilter/enumMainFilter";
import { enumToasts } from "../../utils/enumToasts";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 0,
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    button: {
      padding: "25px 18px 23px",

      fontFamily: "'Open Sans', sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "100%",
      textDecoration: "none",
      textTransform: "capitalize",

      color: "#FFFFFF",
      transition: "all .2s ease-in-out",

      "&:hover": {
        color: "#FFBE3F",
      },
    },
    link: {
      textDecoration: "none",
    },
  })
);
interface IHeaderPagesButtons {
  className: string;
}

const HeaderPagesButtons: FC<IHeaderPagesButtons> = ({ className }) => {
  const classes = useStyles();
  let location = useLocation();
  const dispatch = useDispatch();
  let history = useHistory();
  const carsGenerationData = useSelector(
    (state: StateType) => state.carsGenerationData
  );
  const dataForRequest = useSelector(
    (state: StateType) => state.carsDataForRequest
  );

  const haveCars =
    carsGenerationData.carsGeneration.cars &&
    carsGenerationData.carsGeneration.cars.length > 0;

  const getCarsGeneration = useCallback(async () => {
    dispatch(setsurveyPsges("main"));
    history.push(AppRoutesEnum.MAIN);
    try {
      await dispatch(
        setCarsGenerationData(
          dataForRequest.carCross,
          dataForRequest.carAppearance,
          dataForRequest.carDynamism,
          dataForRequest.carComfort,
          dataForRequest.carEconomy,
          dataForRequest.carSafety,
          dataForRequest.carPrice.minValue,
          dataForRequest.carPrice.maxValue,
          dataForRequest.carsBrands,
          dataForRequest.carModel,
          dataForRequest.carClass,
          dataForRequest.carGearbox,
          dataForRequest.carBody,
          dataForRequest.carDriver,
          dataForRequest.carTrunk.minValue,
          dataForRequest.carTrunk.maxValue,
          dataForRequest.carEnginePower.minValue,
          dataForRequest.carEnginePower.maxValue,
          dataForRequest.carDisplacement.minValue,
          dataForRequest.carDisplacement.maxValue,
          dataForRequest.carEngineType,
          dataForRequest.carAcceleration.minValue,
          dataForRequest.carAcceleration.maxValue,
          dataForRequest.carYear.minValue,
          dataForRequest.carYear.maxValue,
          dataForRequest.carConsumption.minValue,
          dataForRequest.carConsumption.maxValue,
          dataForRequest.carReason,
          dataForRequest.carSort,
          EnumMainFilter.carLimit
        )
      );
      // dispatch(setSurveyDone(true));
    } catch (error) {
      if (
        (error as AxiosError).response &&
        (error as AxiosError).response?.data.msg.includes(
          "Bad Authorization header"
        )
      ) {
        toast.error(enumToasts.noAutorization);
      }
    }
  }, [dispatch, history, dataForRequest]);

  return (
    <div className={classes.root + " " + className}>
      {(location.pathname === AppRoutesEnum.SURVEY_MAIN ||
        location.pathname === AppRoutesEnum.SURVEY_FIRST ||
        location.pathname === AppRoutesEnum.SURVEY_SECOND ||
        location.pathname === AppRoutesEnum.SURVEY_THIRD ||
        location.pathname === AppRoutesEnum.SURVEY_FOURTH) &&
      !haveCars ? (
        <NavLink className={classes.link} to={AppRoutesEnum.MAIN}>
          <Button className={classes.button} onClick={getCarsGeneration}>
            Пропустить опрос
          </Button>
        </NavLink>
      ) : (
        <NavLink className={classes.link} to={AppRoutesEnum.MAIN}>
          <Button className={classes.button}>Главная</Button>
        </NavLink>
      )}
    </div>
  );
};

export default memo(HeaderPagesButtons);
