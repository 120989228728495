import React, { FC, memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmissionError } from "redux-form";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { users } from "../../../api/users/users";
import {
  authEmail,
  authUserName,
  registrationUser,
  setFormOpen,
  setStatusForm,
} from "../../../redux/auth/auth-actions";
import { StateType } from "../../../redux/store";
import BugReportFormRedux from "../../Forms/BugReportForm/BugReportForm";
import EmailForRestorePasswordFormRedux from "../../Forms/emailForRestorePasswordForm/EmailForRestorePasswordForm";
import RestorePasswordFormRedux, {
  RestorePasswordFormType,
} from "../../Forms/restorePasswordForm/restorePasswordForm";
import ChangePasswordForm from "../../Forms/сhangePasswordForm/ChangePasswordForm";
import HeaderSignInLogInForm, {
  LogInFormValuesType,
} from "./headerSignInLogInForm/HeaderSignInLogInForm";
import HeaderSigInLogUpForm, {
  LogUpFormValuesType,
} from "./headerSignInLogUpForm/HeaderSigInLogUpForm";
import HeaderSignInSuccessForm from "./headerSignInSuccessForm/HeaderSignInSuccesForm";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { enumToasts } from "../../../utils/enumToasts";
import { setCarsGenerationData } from "../../../redux/carsGenerationData-reducer";
import { EnumMainFilter } from "../../../pages/Main/MainFilter/enumMainFilter";

const useStyles = makeStyles(() =>
  createStyles({
    sections: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
    },
  })
);

const HeaderSignInModal: FC = () => {
  const classes = useStyles();
  const [loginMessage, setLoginMessage] = useState<string>("");
  const dispatch = useDispatch();
  const { statusForm, uuid } = useSelector((state: StateType) => state.auth);
  const dataForRequest = useSelector(
    (state: StateType) => state.carsDataForRequest
  );

  const handleSubmitLogIn = (values: LogInFormValuesType) => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
      dispatch(authEmail(values.login, values.password));
    } else {
      dispatch(authUserName(values.login, values.password));
    }
    // запрос для того тчобы после авторизации отображался список авто
    dispatch(
      setCarsGenerationData(
        dataForRequest.carCross,
        dataForRequest.carAppearance,
        dataForRequest.carDynamism,
        dataForRequest.carComfort,
        dataForRequest.carEconomy,
        dataForRequest.carSafety,
        dataForRequest.carPrice.minValue,
        dataForRequest.carPrice.maxValue,
        dataForRequest.carsBrands,
        dataForRequest.carModel,
        dataForRequest.carClass,
        dataForRequest.carGearbox,
        dataForRequest.carBody,
        dataForRequest.carDriver,
        dataForRequest.carTrunk.minValue,
        dataForRequest.carTrunk.maxValue,
        dataForRequest.carEnginePower.minValue,
        dataForRequest.carEnginePower.maxValue,
        dataForRequest.carDisplacement.minValue,
        dataForRequest.carDisplacement.maxValue,
        dataForRequest.carEngineType,
        dataForRequest.carAcceleration.minValue,
        dataForRequest.carAcceleration.maxValue,
        dataForRequest.carYear.minValue,
        dataForRequest.carYear.maxValue,
        dataForRequest.carConsumption.minValue,
        dataForRequest.carConsumption.maxValue,
        dataForRequest.carReason,
        dataForRequest.carSort,
        EnumMainFilter.carLimit
      )
    );
  };

  const handleSubmitLogUp = async (values: LogUpFormValuesType) => {
    try {
      await dispatch(
        registrationUser(values.email, values.passwordNew, values.username)
      );
      if (values.username) {
        setLoginMessage(values.username);
      } else {
        setLoginMessage(values.email);
      }
      dispatch(setStatusForm("registration-success"));
    } catch (error) {
      if (
        (error as AxiosError).response &&
        (error as AxiosError).response?.data.msg.includes("Username is taken")
      ) {
        throw new SubmissionError({
          username: "Такой пользователь уже существует",
        });
      } else if (
        (error as AxiosError).response &&
        (error as AxiosError).response?.data.msg.includes("Email is taken")
      ) {
        throw new SubmissionError({
          email: "Такой пользователь уже существует",
        });
      } else if (
        (error as AxiosError).response &&
        (error as AxiosError).response?.data.msg.includes("Weak password")
      ) {
        throw new SubmissionError({
          passwordNew: "Слабый пароль",
        });
      } else {
        throw new SubmissionError({
          username: "Что-то пошло не так.",
        });
      }
    }
  };

  const handleSubmitRestorePassword = async (
    values: RestorePasswordFormType
  ) => {
    if (uuid) {
      try {
        await users.restorePassword(uuid, values.passwordNew);
        toast.success(enumToasts.successChangePassword);
        dispatch(setFormOpen(false));
      } catch (error) {
        toast.error(enumToasts.somethingWrong);
      }
    }
  };

  return (
    <section className={classes.sections}>
      {statusForm === "login" && (
        <HeaderSignInLogInForm onSubmit={handleSubmitLogIn} />
      )}
      {statusForm === "registration" && (
        <HeaderSigInLogUpForm onSubmit={handleSubmitLogUp} />
      )}
      {statusForm === "registration-success" && (
        <HeaderSignInSuccessForm
          title="Регистрация прошла успешно!"
          subtitle={`Пользователь ${loginMessage} создан`}
        />
      )}
      {statusForm === "activation-success" && (
        <HeaderSignInSuccessForm
          title="Активация прошла успешно!"
          subtitle={`Пользователь ${loginMessage} активирован`}
          isActivation={true}
        />
      )}
      {statusForm === "change-password" && <ChangePasswordForm />}

      {statusForm === "email-restore-password" && (
        <EmailForRestorePasswordFormRedux />
      )}

      {statusForm === "restore-password" && (
        <RestorePasswordFormRedux onSubmit={handleSubmitRestorePassword} />
      )}

      {statusForm === "bug-report" && <BugReportFormRedux />}
    </section>
  );
};

export default memo(HeaderSignInModal);
