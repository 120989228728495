import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

import {
  Backdrop,
  Badge,
  ClickAwayListener,
  Collapse,
  Grow,
  Modal,
  Popper,
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import {
  clearToken,
  setFormOpen,
  setStatusForm,
} from "../../../redux/auth/auth-actions";
import { clearComparison } from "../../../redux/carsComparisonData-reducer";
import { setAspectIdForBugReport } from "../../../redux/carsGenerationData-reducer";
import { StateType } from "../../../redux/store";
import { AppRoutesEnum } from "../../../routes";
import IconMobileMenu from "../../Icons/IconMobileMenu";
import HeaderSignInButton from "./HeaderSignInButton";
import HeaderSignInModal from "./HeaderSignInModal";
import { useStyles } from "./HeaderSignInStyle";

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      right: 20,
      top: 19,
      backgroundColor: "#FFBE3F",
      color: "#000",
      padding: "0 3px",
    },
  })
)(Badge);

const HeaderSignIn: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isAuth, formOpened } = useSelector((state: StateType) => state.auth);
  const { carsIdForComparison } = useSelector(
    (state: StateType) => state.carsComparisonData
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenForm = () => {
    dispatch(setFormOpen(true));
    dispatch(setStatusForm("login"));
  };

  const handleLogOut = () => {
    history.push(AppRoutesEnum.MAIN);
    handleOpenForm();
    dispatch(clearToken());
  };

  const handleClose = () => {
    dispatch(setFormOpen(false));
    //TODO: для багрепортов приходится сохранять айди, чтобы передать в форму, при закрытии его сбрасываю здесь. надо оптимизировать.
    dispatch(setAspectIdForBugReport(0));
  };

  const handleProfile = async () => {
    history.push(AppRoutesEnum.PROFILE);
  };

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClearComparison = () => {
    dispatch(clearComparison());
  };

  const handleBugReport = () => {
    dispatch(setFormOpen(true));
    dispatch(setStatusForm("bug-report"));
  };

  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  return (
    <div>
      <ClickAwayListener onClickAway={handlePopoverClose}>
        <div className={classes.mobileContainer}>
          <div className={classes.icon} onClick={handleToggle}>
            <IconMobileMenu />
          </div>
        </div>
      </ClickAwayListener>
      <div className={classes.singInButtonContainer}>
        <HeaderSignInButton
          handleLogOut={handleLogOut}
          handleOpen={handleOpenForm}
        />
      </div>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        transition
        keepMounted
      >
        {({ TransitionProps }) => (
          <Collapse {...TransitionProps} timeout={350}>
            <div className={classes.mobileMenu}>
              {!isAuth && (
                <p className={classes.menuItem} onClick={handleOpenForm}>
                  Войти
                </p>
              )}
              <NavLink className={classes.link} to={AppRoutesEnum.MAIN}>
                <p className={classes.menuItem}>Главная</p>
              </NavLink>
              {isAuth && (
                <p onClick={handleProfile} className={classes.menuItem}>
                  Личный кабинет
                </p>
              )}
              <NavLink className={classes.link} to={AppRoutesEnum.COMPARISON}>
                <StyledBadge
                  badgeContent={carsIdForComparison.length}
                  color="primary"
                >
                  <p className={classes.menuItem}>Перейти в сравнение</p>
                </StyledBadge>
              </NavLink>
              <p className={classes.menuItem} onClick={handleClearComparison}>
                Очистить сравнение
              </p>
              {isAuth && (
                <p className={classes.menuItem} onClick={handleBugReport}>
                  Помощь
                </p>
              )}
              {isAuth && (
                <p className={classes.menuItem} onClick={handleLogOut}>
                  Выйти
                </p>
              )}
            </div>
          </Collapse>
        )}
      </Popper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={formOpened}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow
          in={formOpened}
          style={{ transformOrigin: "0 0 0" }}
          {...(formOpened ? { timeout: 700 } : {})}
        >
          <div className={classes.paper}>
            <CloseIcon onClick={handleClose} className={classes.cross} />
            <HeaderSignInModal />
          </div>
        </Grow>
      </Modal>
    </div>
  );
};

export default HeaderSignIn;
