import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Grow from "@material-ui/core/Grow";

import background from "../../assets/images/backgroundModal.png";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "underline",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      padding: "60px 45px 60px 80px",
      boxSizing: "border-box",
      maxWidth: "670px",
      maxHeight: "75vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      outline: "none",
      boxShadow:
        "0px 0px 4px rgba(0, 0, 0, 0.25), 0px 5px 4px rgba(0, 0, 0, 0.28)",
      borderRadius: 5,
      backgroundImage: `url('${background}')`,
      backgroundColor: "#6b6a6a",
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        padding: "30px 0",
        width: "100%",
        maxHeight: "90vh",
      },
    },
    cross: {
      position: "absolute",
      top: 0,
      right: 0,
      fontSize: 40,
      color: "#FFBE3F",
      "&:hover": {
        cursor: "pointer",
      },
    },
    title: {
      margin: 0,
      maxWidth: "400px",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "36px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
      [theme.breakpoints.down("xs")]: {
        fontSize: "26px",
        lineHeight: "26px",
      },
    },
    content: {
      margin: "40px auto 0",
      padding: "0 33px 0 0",
      overflowY: "auto",
      "&::-webkit-scrollbar-button": {
        width: "2px",
        height: "0px",
        backgroundImage: "url('')",
        backgroundRepeat: "no-repeat",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ecedee",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "6px",
        backgroundColor: "#FFDA8C",
      },
      "&::-webkit-resizer": {
        width: "2px",
        height: "0px",
        backgroundImage: "url('')",
        backgroundRepeat: "no-repeat",
      },
      "&::-webkit-scrollbar": {
        width: "2px",
      },
      [theme.breakpoints.down("xs")]: {
        margin: "20px 10px 0",
        paddingRight: "10px",
      },
    },
    text: {
      margin: "0 0 17px",
      fontWeight: 300,
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.15px",
      color: "rgba(255, 255, 255, 0.7)",
    },
  })
);

export interface FooterAboutProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FooterAboutProjectModal: FC<FooterAboutProjectModalProps> = ({
  isOpen,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={isOpen}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      onClose={onClose}
      closeAfterTransition
    >
      <Grow
        in={isOpen}
        style={{ transformOrigin: "0 0 0" }}
        {...{ timeout: 700 }}
      >
        <div className={classes.paper}>
          <CloseIcon onClick={onClose} className={classes.cross} />
          <h3 className={classes.title}>О проекте</h3>
          <div className={classes.content}>
            <p className={classes.text}>CAR4U, сервис оценки автомобилей</p>
            <p className={classes.text}>
              CAR4U - является одним из сервисов, разработанных с помощью
              платформы поддержки жизненного цикла интеллектуальных объектов на
              основе больших данных, и представляет собой независимое
              программное решение для быстрого подбора автомобиля с помощью
              интуитивно понятного «умного фильтра».
            </p>
            <p className={classes.text}>
              Car4U построен с применением комплекса моделей на основе машинного
              обучения, позволяющих не только получить структурированную
              агрегированную информацию об интересующем автомобиле и сравнить
              его с аналогами, но и выдающих прогноз относительно стоимости
              владения машиной и потенциальных, связанных с этим рисков, а также
              учитывающих предпочтения, приоритеты пользователя и его финансовые
              возможности.
            </p>
            <p className={classes.text}>
              Разработанный НЦКР сервис призван сделать процесс выбора и оценки
              автомобиля в десятки раз проще и быстрее, что поможет покупателям
              принимать действительно взвешенное и обоснованное решение (в том
              числе решение об отказе от покупки). Для этого пользователю
              предоставляются инструменты подбора автомобиля, исходя из желаемых
              характеристик и финансовых возможностей. В частности, инструмент
              "Умный фильтр" предоставляет набор уже готовых и
              интуитивно-понятных характеристик, что позволяет быстро определить
              необходимую модель. Интеллектуальные калькуляторы, построенные с
              применением машинного обучения, позволяют получить прогноз на
              различные статьи расходов владения машиной. На страницах моделей
              автомобилей пользователь может получить агрегированную информацию,
              которая включает в себя автоматически выделяемые особенности
              владения из сотен комментариев владельцев данной модели.
            </p>
          </div>
        </div>
      </Grow>
    </Modal>
  );
};

export default FooterAboutProjectModal;
