import React, { FC } from "react";

import Button from "@material-ui/core/Button";
import { useDispatch } from "react-redux";
import { resetFilter } from "../../../redux/carsDataForRquest-reducer";

interface I {
  className?: string;
}

const MainFilterButtonDrop: FC<I> = ({ className }) => {
  const dispatch = useDispatch();

  const getDropFilters = (): void => {
    dispatch(resetFilter());
  };

  return (
    <Button
      size="large"
      variant="contained"
      color="secondary"
      className={className}
      onClick={getDropFilters}
    >
      Сбросить
    </Button>
  );
};

export default MainFilterButtonDrop;
