import React, { FC, useEffect, useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedBrands,
  setSelectedPrice,
  setSelectedReason,
  setSelectedYear,
} from "../../../redux/carsDataForRquest-reducer";
import CustomSlider from "../../../components/Sliders/CustomSlider";
import {
  DOUBLE_SLIDERS,
  FILTER_SELECT,
  SLIDERS,
} from "./constantsSlidersMainFilter";
import useMinMaxPriceAndYear from "../../../hooks/useMinMaxPriceAndYear";
import useCarsDataForRequest from "../../../hooks/useCarsDataForRequest";
import FilterSelect from "../../../components/Selects/FilterSelect";
import useCarsDataForFilter from "../../../hooks/useCarsDataForFilter";
import { Collapse, Grid, Typography } from "@material-ui/core";
import ExtraFilterButton from "../../../components/ExtraFilterButton/ExtraFilterButton";
import DoubleSlider from "../../../components/Sliders/DoubleSlider";
import FilterMultiSelect from "../../../components/Selects/FilterMultiSelect";
import DoubleSliderWithInputs from "../../../components/Sliders/DoubleSliderWithInputs";
import {
  setCarReasons,
  setCarsBrandsAndModelsForExtraFilter,
  setCarsInfoForExtraFilter,
} from "../../../redux/carsDataForFilter-reducer";
import { StateType } from "../../../redux/store";
import MainFilterButtons from "./MainFilterButtons";

const useStyles = makeStyles((theme) =>
  createStyles({
    mainFilter: {
      padding: "26px 48px 20px",
      background:
        "linear-gradient(180deg, rgba(39, 30, 12, 0.65) 0%, rgba(39, 30, 12, 0.65) 28.56%, #271E0C 42.21%, #271E0C 100%)",
      [theme.breakpoints.down("sm")]: {
        padding: "26px 24px 20px",
      },
    },
    customEnd: {
      justifySelf: "flex-end",
      justifyContent: "flex-end",
    },
  })
);

interface MainFilterProps {
  onClick(): void;
}

const MainFilter: FC<MainFilterProps> = (props) => {
  const classes = useStyles();
  const [extraFilterOpen, setExtraFilterOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const dataForRequest = useCarsDataForRequest();
  const dataForFilter = useCarsDataForFilter();
  const { carPrice } = useSelector(
    (state: StateType) => state.carsDataForRequest
  );
  const { minPrice, maxPrice, minYear, maxYear } = useMinMaxPriceAndYear();
  useEffect(() => {
    dispatch(setCarReasons());
    dispatch(setCarsInfoForExtraFilter());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCarsBrandsAndModelsForExtraFilter(dataForRequest.carsBrands));
  }, [dataForRequest.carsBrands, dispatch]);

  return (
    <div className={classes.mainFilter}>
      <Typography variant="h6" color="textSecondary" gutterBottom>
        УМНЫЙ ФИЛЬТР
      </Typography>
      <Grid container spacing={3}>
        {SLIDERS.map(({ title, tooltip, value, set }) => (
          <Grid item key={title} xs={6} sm={4} md={3} lg={2}>
            <CustomSlider
              value={dataForRequest[value as keyof object]}
              set={(value) => dispatch(set(value))}
              title={title}
              tooltip={tooltip}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={7} md={6} lg={4}>
          <DoubleSliderWithInputs
            min={minPrice}
            max={maxPrice}
            set={(value) => dispatch(setSelectedPrice(value))}
            value={{
              minValue: carPrice.minValue,
              maxValue: carPrice.maxValue,
            }}
            step={10000}
            type={"price"}
            title="Стоимость, ₽"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={6}
          lg={4}
          style={{ alignSelf: "flex-end" }}
        >
          <FilterSelect
            label="Причина покупки"
            value={dataForFilter.carReasons}
            disabled={false}
            set={(value) => dispatch(setSelectedReason(value))}
            selected={
              dataForRequest.carReason[0]
                ? String(dataForRequest.carReason[0])
                : ""
            }
          />
        </Grid>
        {!extraFilterOpen && (
          <MainFilterButtons md={6} lg={4} onClick={props.onClick} />
        )}
      </Grid>
      <ExtraFilterButton
        onClick={() => setExtraFilterOpen(!extraFilterOpen)}
        extraFilterOpen={extraFilterOpen}
      />
      <Collapse in={extraFilterOpen}>
        <Grid
          container
          spacing={3}
          alignItems="flex-end"
          style={{ marginTop: 30 }}
        >
          {DOUBLE_SLIDERS.map(({ step, title, min, max, value, set }) => (
            <Grid key={title} item xs={6} sm={4} md={3} lg={2}>
              <DoubleSlider
                min={min}
                max={max}
                value={dataForRequest[value as keyof object]}
                set={(value) => dispatch(set(value))}
                title={title}
                step={step}
              />
            </Grid>
          ))}
          <Grid item xs={6} sm={4} md={3} lg={2} />
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <FilterMultiSelect
              label="Марка"
              values={dataForFilter.carsBrands.carBrands}
              set={(value) => dispatch(setSelectedBrands(value))}
              selected={dataForRequest.carsBrands}
            />
          </Grid>
          {FILTER_SELECT.map(
            ({ label, values, key, set, selected, disabled }) => (
              <Grid key={label} item xs={6} sm={4} md={3} lg={2}>
                <FilterSelect
                  label={label}
                  value={
                    dataForFilter[values as keyof object][key as keyof object]
                  }
                  disabled={dataForFilter[disabled as keyof object]}
                  set={(value) => dispatch(set(value))}
                  selected={dataForRequest[selected as keyof object]}
                />
              </Grid>
            )
          )}
          <Grid item xs={12} sm={7} md={6} lg={4}>
            <DoubleSliderWithInputs
              min={minYear}
              max={maxYear}
              set={(value) => dispatch(setSelectedYear(value))}
              value={{
                minValue: dataForRequest.carYear.minValue,
                maxValue: dataForRequest.carYear.maxValue,
              }}
              step={1}
              title="Год выпуска"
            />
          </Grid>
          <MainFilterButtons md={12} lg={6} onClick={props.onClick} />
        </Grid>
      </Collapse>
    </div>
  );
};

export default React.memo(MainFilter);
