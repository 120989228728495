import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import { users } from "../../api/users/users";
import NoData from "../../components/NoData/NoData";
import { GAPageView, initGA } from "../../index";
import {
  setFormOpen,
  setStatusForm,
  setUuid,
} from "../../redux/auth/auth-actions";
import { StateType } from "../../redux/store";
import { setsurveyPsges } from "../../redux/survey/serveyReducer";
import MainFilter from "./MainFilter/MainFilter";
import MainPageGenerationCarsList from "./mainPageGenerationCars/mainPageGenerationCarsList";
import MainPageTitle from "./MainTitle";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      // backdropFilter: "blur(2px)",
      background: "rgba(129, 129, 129, .1)",
    },
    title: {
      minHeight: "800px",
      backgroundSize: "contain",
      height: "100%",
    },
    filter: {
      margin: "-358px 0 0 0",
    },
    noDataRoot: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px 0",
      background:
        "linear-gradient(0deg, rgba(39, 30, 12, 0.85) 0%, rgba(39, 30, 12, 0.90) 48.56%, #271E0C 82.21%, #271E0C 100%)",
    },
  })
);

const MainPage: FC = () => {
  const carsGenerationData = useSelector(
    (state: StateType) => state.carsGenerationData
  );
  const classes = useStyles();
  const [showNoData, setShowNoData] = useState(false);
  const sectionCarsList = useRef<HTMLElement>(null);
  const noData = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const root = useRef<HTMLDivElement>(null);

  let location = useLocation();
  useEffect(() => {
    initGA();
    GAPageView("/main-page");
  }, []);

  useEffect(() => {
    if (location.pathname.includes("activate")) {
      let id = location.pathname.slice(10);
      if (id.length > 0) {
        users
          .activateUser(id)
          .then((res) => {
            dispatch(setFormOpen(true));
            dispatch(setStatusForm("activation-success"));
          })
          .catch((err) => console.log(err));
      }
    }

    if (location.pathname.includes("password")) {
      let id = location.pathname.slice(10);
      if (id.length > 0) {
        dispatch(setFormOpen(true));
        dispatch(setStatusForm("restore-password"));
        dispatch(setUuid(id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const scrollTo = useCallback(
    (): void => {
      if (sectionCarsList.current) {
        window.scrollTo({
          behavior: "smooth",
          top: sectionCarsList.current.offsetTop,
        });
      }
      setShowNoData(true);
      if (noData.current) {
        window.scrollTo({
          behavior: "smooth",
          top: noData.current.offsetTop,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [carsGenerationData.carsGeneration.cars]
  );

  useEffect(() => {
    dispatch(setsurveyPsges("main"));
  }, [dispatch]);

  const haveCars =
    (carsGenerationData.carsGeneration.cars &&
      carsGenerationData.carsGeneration.cars.length > 0) ||
    (carsGenerationData.carsGeneration.recommended_cars &&
      carsGenerationData.carsGeneration.recommended_cars.length > 0);

  // console.log(`carsGenerationData.carsGeneration`, carsGenerationData);
  return (
    <div className={classes.root} ref={root}>
      <section className={classes.title}>
        <MainPageTitle />
      </section>
      <section className={classes.filter}>
        <MainFilter onClick={scrollTo} />
      </section>
      {haveCars && (
        <section ref={sectionCarsList}>
          <MainPageGenerationCarsList scrollToRes={scrollTo}/>
        </section>
      )}
      {showNoData && !haveCars && (
        <div className={classes.noDataRoot} ref={noData}>
          <NoData text={"По заданным параметрам нет данных"} />
        </div>
      )}
    </div>
  );
};

export default memo(MainPage);
