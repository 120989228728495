export enum EnumMainFilter {
  carPriceMin = 0,
  carPriceMax = 10000000,
  carYearMin = 1950,
  carAccelerationMin = 2,
  carAccelerationMax = 36,
  carTrunkMin = 49,
  carTrunkMax = 4880,
  carEnginePowerMin = 1,
  carEnginePowerMax = 1500,
  carDisplacementMin = 0.1,
  carDisplacementMax = 8.4,
  carConsumptionMin = 2.9,
  carConsumptionMax = 25,
  carLimit = 120,
  carLimitFirstTime = 18,
}
