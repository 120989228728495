import cn from "classnames";
import React, { FC, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Field, InjectedFormProps, reduxForm } from "redux-form";

import { Button, Grow } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { otherRequests } from "../../../api/otherRequests/otherRequests";
import { GAModalView } from "../../../index";
import {
  errorVisibleMessage,
  setFormOpen,
} from "../../../redux/auth/auth-actions";
import { setAspectIdForBugReport } from "../../../redux/carsGenerationData-reducer";
import { StateType } from "../../../redux/store";
import { enumToasts } from "../../../utils/enumToasts";
import { validate } from "../../../utils/validate";
import DropZoneField from "../../DropzoneField/DropzoneField";
import InputBase from "../../Inputs/InpuBase";
import ButtonLoading from "../../Loading/buttonLoading";

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      maxHeight: 582,
      width: 302,
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    input: {
      marginBottom: 24,
    },
    multiline: {
      height: 144,
    },
    button: {
      margin: "1rem 0 0 0",
      width: "302px",
      height: "48px",
      fontSize: "16px",
      lineHeight: "19px",
      background: "#FAC867",
      transition: ".3s, easy-in",
      "&:hover": {
        background: "#A28C8E",
      },
    },
    title: {
      margin: "0 0 20px",
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "28px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
      textAlign: "center",
    },
    subtitle: {
      fontWeight: 400,
      fontStyle: "normal",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "0.1px",
      color: " #FFFFFF",
    },
  })
);

interface BugReportFormProps {
  authForm: StateType;
}

const BugReportForm: FC<
  InjectedFormProps<BugReportFormType, BugReportFormProps> & BugReportFormProps
> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { aspectIdForBugReport } = useSelector(
    (state: StateType) => state.carsGenerationData
  );
  const { handleSubmit, pristine, submitting, invalid } = props;
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    GAModalView("bugReportForm");
  }, []);

  const handleOnDrop = (newFiles: File[]) => {
    setFiles(newFiles);
  };

  const handleSubmitBugReport = async (values: BugReportFormType) => {
    let aspectId = aspectIdForBugReport > 0 ? aspectIdForBugReport : undefined;
    try {
      await otherRequests.bugReport(
        values.message,
        history.location.pathname,
        values.theme,
        aspectId,
        files
      );
      dispatch(setFormOpen(false));
      toast.success(enumToasts.successSendReport);
      dispatch(setAspectIdForBugReport(0));
    } catch (error) {
      if (error.response) {
        // toast.error(error.response.data.msg);
        console.log(`Error report`, error.response.data.msg);
      } else {
        // toast.error(enumToasts.somethingWrong);
        console.log(`Error report`, enumToasts.somethingWrong);
      }
    } finally {
      /// bag report всегда успешен для пользователя, но если ошибка она падает в консоль
      dispatch(setFormOpen(false));
      toast.success(enumToasts.successSendReport);
      dispatch(setAspectIdForBugReport(0));
    }
  };

  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} {...{ timeout: 700 }}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(handleSubmitBugReport)}
      >
        <h3 className={classes.title}>Сообщить об ошибке</h3>
        <Field
          className={classes.input}
          name="theme"
          component={InputBase}
          label="Заголовок"
        />
        <Field
          className={cn(classes.multiline, classes.input)}
          name="message"
          component={InputBase}
          label="Описание ошибки"
          multiline
          rows={6}
        />
        <Field
          className={classes.input}
          name="imageToUpload"
          component={DropZoneField}
          type="file"
          limitFiles={6}
          handleOnDrop={handleOnDrop}
        />

        <Button
          type="submit"
          name="button"
          disabled={
            props.authForm.auth.progressVisible ||
            invalid ||
            pristine ||
            submitting
          }
          className={classes.button}
        >
          {props.authForm.auth.progressVisible ? (
            <ButtonLoading />
          ) : (
            "ОТПРАВИТЬ"
          )}
        </Button>
      </form>
    </Grow>
  );
};
export type BugReportFormType = {
  message: string;
  theme: string;
};

const BugReportFormRedux = reduxForm<BugReportFormType, BugReportFormProps>({
  form: "bugReportForm",
  validate,
})(BugReportForm);

export default connect((state: StateType) => ({ authForm: state }), {
  errorVisibleMessage,
})(BugReportFormRedux);
